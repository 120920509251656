import { LocalizedString } from "design-system";
import { array, eq, option } from "fp-ts";

import { constant, constFalse, pipe } from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import {
  RuntimeLocaleKey,
  useFormatCitizenshipOrOther,
  useFormatIncomeCurrency,
  useFormatMaritalStatus,
  useFormatMessage,
  useFormatMonthYearValue,
  useFormatPercentageLike,
  useFormatSex,
  useFormatYearValue,
} from "../../intl";
import {
  foldPersonalDataField,
  PersonalDataFromOutput,
} from "../Confirmation/domain";
import { ExtractedDataResult } from "../../UploadDocuments/types";
import { ExternalLoanItem, ExternalLoanItemRework } from "../api";
import { getTypeOfProductLabel } from "../domain";
import { useFormatTypeOfRent } from "../IncomeForm/commons/Utils";
import {
  ContractInfo,
  IncomeOutput,
  useFormatAllowanceType,
  useFormatEmploymentType,
  useFormatIncomeSourceType,
  useFormatPaymentMethod,
} from "../IncomeForm/domain";
import { useFormatPensionType } from "../IncomeForm/Forms/PensionerForm/utils";
import {
  ClientDataReworkOCR,
  ExtractedDataReworkResult,
  ReworkChangesItem,
  ReworkIncomeOutput,
} from "./api";
import moment from "moment";

export const mergeExtractDataDocumentsReworkOutput = (
  primary: Option<ClientDataReworkOCR>,
  secondary: Option<ClientDataReworkOCR>
): Option<ExtractedDataReworkResult> => {
  const isThereAnAdditionalDocument =
    option.isSome(primary) && option.isSome(secondary);
  return pipe(
    primary,
    option.alt(constant(secondary)),
    option.map(document => ({
      documentType: document.documentType,
      additionalDocumentType: pipe(
        secondary,
        option.chain(document => document.documentType),
        option.filter(constant(isThereAnAdditionalDocument))
      ),
      personalData: document.personalData,
      permanentAddress: document.permanentAddress,
      documentDetails: document.documentDetails,
      addressSuggestions: document.addressSuggestions,
      additionalDocumentDetails: pipe(
        secondary,
        option.chain(document => document.documentDetails),
        option.filter(constant(isThereAnAdditionalDocument))
      ),
      validationErrors: document.validationErrors,
    }))
  );
};

export function useGetPersonalDataStepChanges(
  clientData: ExtractedDataResult,
  oldDocument: Option<ExtractedDataReworkResult>
): Option<ReworkChangesItem[]> {
  const formatCitizenshipOrOther = useFormatCitizenshipOrOther();
  const formatMaritalStatus = useFormatMaritalStatus();
  const formatSex = useFormatSex();

  return pipe(
    oldDocument,
    option.map(doc => [
      ...pipe(
        doc.personalData,
        option.fold(constant([]), p =>
          pipe<Option<ReworkChangesItem>[], ReworkChangesItem[]>(
            [
              pipe(
                p.title,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.title",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: clientData.personalData.title,
                }))
              ),
              pipe(
                p.titleAfter,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.titleAfter",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: clientData.personalData.titleAfter,
                }))
              ),
              pipe(
                p.name,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.name",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: clientData.personalData.name,
                }))
              ),
              pipe(
                p.surname,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.surname",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: clientData.personalData.surname,
                }))
              ),
              pipe(
                p.sex,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.sex",
                  type: "string",
                  oldValue: option.some(pipe(v, formatSex)),
                  newValue: pipe(
                    clientData.personalData.sex,
                    option.map(formatSex)
                  ),
                }))
              ),
              pipe(
                p.maritalStatus,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.maritalStatus",
                  type: "string",
                  oldValue: option.some(pipe(v, formatMaritalStatus)),
                  newValue: pipe(
                    clientData.personalData.maritalStatus,
                    option.map(formatMaritalStatus)
                  ),
                }))
              ),
              pipe(
                p.dateOfBirth,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.birthDate",
                  type: "date",
                  oldValue: option.some(v),
                  newValue: clientData.personalData.dateOfBirth,
                }))
              ),
              pipe(
                p.birthNumber,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.birthNumber",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: clientData.personalData.birthNumber,
                }))
              ),
              pipe(
                p.placeOfBirth,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.birthPlace",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: clientData.personalData.placeOfBirth,
                }))
              ),
              pipe(
                p.countryOfBirth,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.countryOfBirth.label",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: clientData.personalData.countryOfBirth,
                }))
              ),
              pipe(
                p.citizenship,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.citizenship",
                  type: "string",
                  oldValue: option.some(pipe(v, formatCitizenshipOrOther)),
                  newValue: pipe(
                    clientData.personalData.citizenship,
                    option.map(formatCitizenshipOrOther)
                  ),
                }))
              ),
            ],
            array.compact
          )
        )
      ),
      ...pipe(
        doc.documentDetails,
        option.fold(constant([]), v =>
          pipe<Option<ReworkChangesItem>[], ReworkChangesItem[]>(
            [
              pipe(
                v.idNumber,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.idNumber",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: clientData.documentDetails.idNumber,
                }))
              ),
              pipe(
                v.dateOfIssuing,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.dateOfIssuing",
                  type: "date",
                  oldValue: option.some(v),
                  newValue: clientData.documentDetails.dateOfIssuing,
                }))
              ),
              pipe(
                v.validUntil,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.validUntil",
                  type: "date",
                  oldValue: option.some(v),
                  newValue: clientData.documentDetails.validUntil,
                }))
              ),
              pipe(
                v.authority,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.authority",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: clientData.documentDetails.authority,
                }))
              ),
              pipe(
                v.issuer,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.personalData.placeOfIssue",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: clientData.documentDetails.issuer,
                }))
              ),
            ],
            array.compact
          )
        )
      ),
      ...pipe(
        doc.permanentAddress,
        option.fold(constant([]), v =>
          pipe<Option<ReworkChangesItem>[], ReworkChangesItem[]>(
            [
              pipe(
                v.country,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.address.country",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: pipe(
                    clientData.permanentAddress,
                    option.chain(p => p.country)
                  ),
                }))
              ),
              pipe(
                v.city,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.address.city",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: pipe(
                    clientData.permanentAddress,
                    option.chain(p => p.city)
                  ),
                }))
              ),
              pipe(
                v.streetName,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.address.streetName",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: pipe(
                    clientData.permanentAddress,
                    option.chain(p => p.streetName)
                  ),
                }))
              ),
              pipe(
                v.streetNumber,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.address.streetNumber",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: pipe(
                    clientData.permanentAddress,
                    option.chain(p => p.streetNumber)
                  ),
                }))
              ),
              pipe(
                v.zipCode,
                option.map(v => ({
                  variant: "item",
                  label: "Identification.address.zipCode",
                  type: "string",
                  oldValue: option.some(v),
                  newValue: pipe(
                    clientData.permanentAddress,
                    option.chain(p => p.zipCode)
                  ),
                }))
              ),
            ],
            array.compact
          )
        )
      ),
    ])
  );
}

export function useGetCreditChecksStepChanges(): (
  income: IncomeOutput,
  oldIncome: Option<ReworkIncomeOutput>
) => Option<ReworkChangesItem[]> {
  const formatIncomeSourceType = useFormatIncomeSourceType();
  const formatYearValue = useFormatYearValue();
  const isLowerThanTwoYears = (contractInfo: Option<ContractInfo>) => {
    return pipe(
      contractInfo,
      option.fold(constFalse, contractInfo_ =>
        pipe(
          contractInfo_.startingDate,
          option.fold(constFalse, monthYear => {
            const then = moment(
              monthYear.year + "-" + (monthYear.month - 1) + "-" + "1",
              "YYYY-MM-DD"
            );
            const now = moment();
            return now.diff(then, "year") > 2;
          })
        )
      )
    );
  };
  const formatMonthYearValue = useFormatMonthYearValue();
  const formatEmploymentType = useFormatEmploymentType();
  const formatPaymentMethod = useFormatPaymentMethod();
  const formatPercentageLike = useFormatPercentageLike();
  const formatTypeOfRent = useFormatTypeOfRent();
  const formatAllowanceType = useFormatAllowanceType();
  const formatPensionType = useFormatPensionType();
  const formatCurrency = useFormatIncomeCurrency();

  return (income: IncomeOutput, oldIncome: Option<ReworkIncomeOutput>) =>
    pipe(
      oldIncome,
      option.map(oldIncome => [
        {
          variant: "item",
          label: "StandardLoan.IncomeForm.sourceOfIncome",
          type: "string",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.map(i =>
              pipe(
                i.incomeSource,
                option.fold(() => "", formatIncomeSourceType)
              )
            )
          ),
          newValue: pipe(
            income.incomeInfo,
            option.map(i => pipe(i.incomeSource, formatIncomeSourceType))
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.IncomeForm.specialSourceOfIncome",
          type: "string",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.specialTypeOfIncome)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i =>
              i.incomeSource === "SpecialType"
                ? option.some(
                    pipe(i.specialTypeOfIncome, formatIncomeSourceType)
                  )
                : option.none
            )
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.salaryCurrency",
          type: "string",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.map(i =>
              pipe(
                i.salaryCurrency,
                option.fold(() => "", formatCurrency)
              )
            )
          ),
          newValue: pipe(
            income.incomeInfo,
            option.map(i =>
              pipe(
                i.salaryCurrency,
                option.fold(() => "", formatCurrency)
              )
            )
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.monthlyIncome",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.monthlyIncome)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.monthlyIncome)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.AdditionalIncome.IncomeSection.lastYearEquityAmountLabel",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.equityFromLastYear)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.equityFromLastYear)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.AdditionalIncome.IncomeSection.sumOfPaidShareLabel",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.sumOfPaidShareOfProfit)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.sumOfPaidShareOfProfit)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.CompanyOwner.ContractSection.isEmployOfTheCompanyLabel",
          type: "boolean",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.employedInCompany)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.employedInCompany)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.FreelancerCard.taxBase",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.taxBase)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.taxBase)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.FreelancerCard.tax",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.tax)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.tax)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.FreelancerCard.businessGrossIncome",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.businessGrossIncome)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.businessGrossIncome)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.FreelancerCard.keepsAccountancy",
          type: "boolean",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.keepsAccountancy)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.keepsAccountancy)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.FreelancerCard.grossIncomes",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.grossIncomes)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.grossIncomes)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.FreelancerCard.r101BruttoIncomes",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.r101BruttoIncomes)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.r101BruttoIncomes)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.FreelancerCard.r37PartialTaxBase",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.r37PartialTaxBase)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.r37PartialTaxBase)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.FreelancerCard.r74TaxAfterClaimedRelief",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.r74TaxAfterClaimedRelief)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.r74TaxAfterClaimedRelief)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.AdditionalIncome.IncomeSection.incomeFromRentContractLabel",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.incomeFromRentContract)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.incomeFromRentContract)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.AdditionalIncome.IncomeSection.incomeOnlyFromLeaseLabel",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.r201IncomeFromLease)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.r201IncomeFromLease)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.AdditionalIncome.IncomeSection.partialTaxFromCapitalLabel",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.r38PartialTaxBase)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.r38PartialTaxBase)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.AdditionalIncome.IncomeSection.partialTaxFromActivityLeaseLabel",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.r39PartialTaxBase)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.r39PartialTaxBase)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.AdditionalIncome.IncomeSection.typeOfRent",
          type: "string",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.typeOfRent),
            option.map(formatTypeOfRent)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => pipe(i.typeOfRent, option.map(formatTypeOfRent)))
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.AdditionalIncome.IncomeSection.IsReCoOwnedByAnotherPerson",
          type: "boolean",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.reCoOwned)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.reCoOwned)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.AdditionalIncome.IncomeSection.IsReInPersonalOwnershipLabel",
          type: "boolean",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.reInPersonalOwnership)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.reInPersonalOwnership)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.AdditionalIncome.IncomeSection.IsReUsedCollateral",
          type: "boolean",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.reUsedAsCollateral)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.reUsedAsCollateral)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.AdditionalIncome.IncomeSection.amortizationOfRentedLabel",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.amortizationOfRentedRe)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.amortizationOfRentedRe)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.AdditionalIncome.IncomeSection.IsCooperativeOwnership",
          type: "boolean",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.isCooperativeOwnership)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.isCooperativeOwnership)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.AdditionalIncome.IncomeSection.IsApplicantDeclareAsUserInDeclaration",
          type: "boolean",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.isApplicantDeclareAsUserInDeclaration)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.isApplicantDeclareAsUserInDeclaration)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.allowanceType",
          type: "string",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.maternityOrParentalIncomeDetails),
            option.chain(m => m.allowanceType),
            option.map(formatAllowanceType)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.maternityOrParentalIncomeDetails),
            option.chain(i =>
              pipe(i.allowanceType, option.map(formatAllowanceType))
            )
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.MaternityLeave.monthlySocialBenefit",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.maternityOrParentalIncomeDetails),
            option.chain(m => m.monthlySocialBenefit)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.maternityOrParentalIncomeDetails),
            option.chain(i => i.monthlySocialBenefit)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.PensionerForm.IncomeSection.pensionType",
          type: "string",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.pensionerIncomeDetails),
            option.chain(m => m.pensionType),
            option.map(formatPensionType)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.pensionerIncomeDetails),
            option.chain(i =>
              pipe(i.pensionType, option.map(formatPensionType))
            )
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.PensionerForm.IncomeSection.monthlyIncome",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.pensionerIncomeDetails),
            option.chain(m => m.monthlyPension)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.pensionerIncomeDetails),
            option.chain(i => i.monthlyPension)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.PensionerForm.IncomeSection.monthlyRent",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.pensionerIncomeDetails),
            option.chain(m => m.monthlyRent)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.pensionerIncomeDetails),
            option.chain(i => i.monthlyRent)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.monthlyIncome",
          type: "money",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.alimonyIncomeDetails),
            option.chain(m => m.monthlyAlimonyIncome)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.alimonyIncomeDetails),
            option.chain(i => i.monthlyAlimonyIncome)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.AlimonyCard.childrenInvolved",
          type: "string",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.alimonyIncomeDetails),
            option.chain(m => m.alimonyChildrenInvolved),
            option.map(toString)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.alimonyIncomeDetails),
            option.chain(i =>
              pipe(i.alimonyChildrenInvolved, option.map(toString))
            )
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.AlimonyCard.alimonyIdentifiable",
          type: "boolean",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.alimonyIncomeDetails),
            option.chain(m => m.alimonyIdentifiable)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.alimonyIncomeDetails),
            option.chain(i => i.alimonyIdentifiable)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.AlimonyCard.alimonyOutsideCountry",
          type: "boolean",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.alimonyIncomeDetails),
            option.chain(m => m.alimonyOutsideCountry)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.alimonyIncomeDetails),
            option.chain(i => i.alimonyOutsideCountry)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.salaryCurrency",
          type: "string",
          oldValue: pipe(
            oldIncome.incomeInfo,
            option.chain(i => i.alimonyIncomeDetails),
            option.chain(m => m.alimonyCurrency),
            option.map(formatCurrency)
          ),
          newValue: pipe(
            income.incomeInfo,
            option.chain(i => i.alimonyIncomeDetails),
            option.chain(i =>
              pipe(i.alimonyCurrency, option.map(formatCurrency))
            )
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.CompanySection.companyNameLabel",
          type: "string",
          oldValue: pipe(
            oldIncome.companyInfo,
            option.chain(i => i.companyName)
          ),
          newValue: pipe(
            income.companyInfo,
            option.chain(i => i.companyName)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.CompanySection.companyIcoLabel",
          type: "string",
          oldValue: pipe(
            oldIncome.companyInfo,
            option.chain(i => i.companyIco)
          ),
          newValue: pipe(
            income.companyInfo,
            option.chain(i => i.companyIco)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.applicantShare",
          type: "boolean",
          oldValue: pipe(
            oldIncome.companyInfo,
            option.chain(i => i.applicantsShareHigherThan33)
          ),
          newValue: pipe(
            income.companyInfo,
            option.chain(i => i.applicantsShareHigherThan33)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.CompanyOwner.StakeInCompany",
          type: "string",
          oldValue: pipe(
            oldIncome.companyInfo,
            option.chain(i => i.stakeInCompany),
            option.map(formatPercentageLike)
          ),
          newValue: pipe(
            income.companyInfo,
            option.chain(i =>
              pipe(i.stakeInCompany, option.map(formatPercentageLike))
            )
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.AdditionalIncome.IncomeSection.isEquityPositiveLabel",
          type: "boolean",
          oldValue: pipe(
            oldIncome.companyInfo,
            option.chain(i => i.equityPositive)
          ),
          newValue: pipe(
            income.companyInfo,
            option.chain(i => i.equityPositive)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.FreelancerCard.freelancerType",
          type: "string",
          oldValue: pipe(
            oldIncome.companyInfo,
            option.chain(i => i.freelancerType)
          ),
          newValue: pipe(
            income.companyInfo,
            option.chain(i => i.freelancerType)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.FreelancerCard.startingDate",
          type: "string",
          oldValue: pipe(
            oldIncome.companyInfo,
            option.chain(i => i.businessStartingDate),
            option.map(formatMonthYearValue)
          ),
          newValue: pipe(
            income.companyInfo,
            option.chain(i =>
              pipe(i.businessStartingDate, option.map(formatMonthYearValue))
            )
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.Tradesman.CompanySection.BusinessSector.label",
          type: "string",
          oldValue: pipe(
            oldIncome.companyInfo,
            option.chain(i => i.businessSector)
          ),
          newValue: pipe(
            income.companyInfo,
            option.chain(i => i.businessSector)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.Contract.startingDate",
          type: "string",
          oldValue: isLowerThanTwoYears(oldIncome.contractInfo)
            ? pipe(
                oldIncome.contractInfo,
                option.chain(i => i.startingDate),
                option.map(formatYearValue)
              )
            : pipe(
                oldIncome.contractInfo,
                option.chain(i => i.startingDate),
                option.map(formatMonthYearValue)
              ),
          newValue: isLowerThanTwoYears(income.contractInfo)
            ? pipe(
                income.contractInfo,
                option.chain(i => i.startingDate),
                option.map(formatYearValue)
              )
            : pipe(
                income.contractInfo,
                option.chain(i => i.startingDate),
                option.map(formatMonthYearValue)
              ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.jobPosition",
          type: "string",
          oldValue: pipe(
            oldIncome.contractInfo,
            option.chain(i => i.jobPosition)
          ),
          newValue: pipe(
            income.contractInfo,
            option.chain(i => i.jobPosition)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.employmentType",
          type: "string",
          oldValue: pipe(
            oldIncome.contractInfo,
            option.chain(i => i.employmentType),
            option.map(formatEmploymentType)
          ),
          newValue: pipe(
            income.contractInfo,
            option.chain(i =>
              pipe(i.employmentType, option.map(formatEmploymentType))
            )
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.contractIndefinitePeriod",
          type: "boolean",
          oldValue: pipe(
            oldIncome.contractInfo,
            option.chain(i => i.contractIndefinitePeriod)
          ),
          newValue: pipe(
            income.contractInfo,
            option.chain(i => i.contractIndefinitePeriod)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.contractPeriodExpiration",
          type: "string",
          oldValue: pipe(
            oldIncome.contractInfo,
            option.chain(i => i.contractPeriodExpiration),
            option.map(formatMonthYearValue)
          ),
          newValue: pipe(
            income.contractInfo,
            option.chain(i =>
              pipe(i.contractPeriodExpiration, option.map(formatMonthYearValue))
            )
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.employedInSpecificProfessions",
          type: "boolean",
          oldValue: pipe(
            oldIncome.contractInfo,
            option.chain(i => i.employedInSpecificProfessions)
          ),
          newValue: pipe(
            income.contractInfo,
            option.chain(i => i.employedInSpecificProfessions)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.EmployedCard.lengthOfUninterruptedCurrentEmployment",
          type: "string",
          oldValue: pipe(
            oldIncome.contractInfo,
            option.chain(i => i.lengthOfUninterruptedCurrentEmployment),
            option.map(toString)
          ),
          newValue: pipe(
            income.contractInfo,
            option.chain(i =>
              pipe(
                i.lengthOfUninterruptedCurrentEmployment,
                option.map(toString)
              )
            )
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.inProbation",
          type: "boolean",
          oldValue: pipe(
            oldIncome.contractInfo,
            option.chain(i => i.inProbation)
          ),
          newValue: pipe(
            income.contractInfo,
            option.chain(i => i.inProbation)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.EmployedCard.workBasedOnAgreementOnWorkingActivity",
          type: "boolean",
          oldValue: pipe(
            oldIncome.contractInfo,
            option.chain(i => i.workBasedOnAgreementOnWorkingActivity)
          ),
          newValue: pipe(
            income.contractInfo,
            option.chain(i => i.workBasedOnAgreementOnWorkingActivity)
          ),
        },
        {
          variant: "item",
          label:
            "StandardLoan.EmployedCard.lengthOfUninterruptedOverallEmployment",
          type: "string",
          oldValue: pipe(
            oldIncome.contractInfo,
            option.chain(i => i.lengthOfUninterruptedOverallEmployment),
            option.map(toString)
          ),
          newValue: pipe(
            income.contractInfo,
            option.chain(i =>
              pipe(
                i.lengthOfUninterruptedOverallEmployment,
                option.map(toString)
              )
            )
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.PaymentMethod.label",
          type: "string",
          oldValue: pipe(
            oldIncome.contractInfo,
            option.chain(i => i.paymentMethod),
            option.map(formatPaymentMethod)
          ),
          newValue: pipe(
            income.contractInfo,
            option.chain(i =>
              pipe(i.paymentMethod, option.map(formatPaymentMethod))
            )
          ),
        },

        {
          variant: "item",
          label: "StandardLoan.EmployedCard.receivesAllowance",
          type: "boolean",
          oldValue: pipe(
            oldIncome.allowanceInfo,
            option.chain(i => i.receivesAllowance)
          ),
          newValue: pipe(
            income.allowanceInfo,
            option.chain(i => i.receivesAllowance)
          ),
        },
        {
          variant: "item",
          label: "StandardLoan.EmployedCard.allowanceAmount",
          type: "money",
          oldValue: pipe(
            oldIncome.allowanceInfo,
            option.chain(i => i.allowanceAmount)
          ),
          newValue: pipe(
            income.allowanceInfo,
            option.chain(i => i.allowanceAmount)
          ),
        },
      ])
    );
}

export function useGetAdditionalIncomeStepChanges(
  additionalIncomeList: IncomeOutput[],
  oldAdditionalIncomeList: Option<
    (ReworkIncomeOutput & { uniqueId: Option<NonEmptyString> })[]
  >
): Option<ReworkChangesItem[]> {
  const getCreditChecksStepChanges = useGetCreditChecksStepChanges();

  return pipe(
    oldAdditionalIncomeList,
    option.map(oldAdditionalIncomeList =>
      pipe(
        oldAdditionalIncomeList,
        array.chain(oldAddIncome => [
          ...pipe(
            additionalIncomeList,
            array.findFirst(ad =>
              option
                .getEq(eq.eqString)
                .equals(ad.uniqueId, oldAddIncome.uniqueId)
            ),
            option.fold(
              () =>
                getCreditChecksStepChanges(
                  {
                    allowanceInfo: option.none,
                    companyInfo: option.none,
                    contractInfo: option.none,
                    incomeInfo: option.none,
                    uniqueId: oldAddIncome.uniqueId,
                    hasPreapprovedLimits: false,
                  },
                  option.some(oldAddIncome)
                ),
              newAddIncome =>
                getCreditChecksStepChanges(
                  newAddIncome,
                  option.some(oldAddIncome)
                )
            ),
            option.getOrElse<ReworkChangesItem[]>(constant([]))
          ),
          {
            variant: "divider",
          },
        ])
      )
    )
  );
}

export function useGetAccountsForRefinancingStepChanges(
  externalLoansList: ExternalLoanItem[],
  oldExternalLoansList: Option<ExternalLoanItemRework[]>
): Option<ReworkChangesItem[]> {
  const formatMessage = useFormatMessage();

  return pipe(
    oldExternalLoansList,
    option.map(oldExternalLoansList =>
      pipe(
        oldExternalLoansList,
        array.chainWithIndex((index, oldExtenalLoan) =>
          pipe(
            externalLoansList,
            array.lookup(index),
            option.map<ExternalLoanItem, ReworkChangesItem[]>(newExtenalLoan =>
              pipe<Option<ReworkChangesItem>[], ReworkChangesItem[]>(
                [
                  pipe(
                    oldExtenalLoan.type,
                    option.map(type => ({
                      variant: "item",
                      label: "StandardLoan.LiabilityItem.typeOfProductLabel",
                      type: "string",
                      oldValue: option.some(
                        formatMessage(getTypeOfProductLabel(type))
                      ),
                      newValue: option.some(
                        formatMessage(
                          getTypeOfProductLabel(newExtenalLoan.type)
                        )
                      ),
                    }))
                  ),
                  pipe(
                    oldExtenalLoan.creditor,
                    option.map(creditor => ({
                      variant: "item",
                      label:
                        "StandardLoan.AccountsForRefinancing.creditorLabel",
                      type: "string",
                      oldValue: option.some(creditor),
                      newValue: newExtenalLoan.creditor,
                    }))
                  ),
                  pipe(
                    oldExtenalLoan.originalAmount,
                    option.map(originalAmount => ({
                      variant: "item",
                      label: "StandardLoan.LiabilityItem.originalAmountLabel",
                      type: "money",
                      oldValue: option.some(originalAmount.amount),
                      newValue: option.some(
                        newExtenalLoan.originalAmount.amount
                      ),
                    }))
                  ),
                  pipe(
                    oldExtenalLoan.remainingAmount,
                    option.map(remainingAmount => ({
                      variant: "item",
                      label: "StandardLoan.LiabilityItem.remainingAmountLabel",
                      type: "money",
                      oldValue: option.some(remainingAmount.amount),
                      newValue: option.some(
                        newExtenalLoan.remainingAmount.amount
                      ),
                    }))
                  ),
                  pipe(
                    oldExtenalLoan.accountForDisbursment,
                    option.map(accountForDisbursment => ({
                      variant: "item",
                      label:
                        "StandardLoan.AccountsForRefinancing.accountForDisbursmentLabel",
                      type: "string",
                      oldValue: option.some(accountForDisbursment),
                      newValue: newExtenalLoan.accountForDisbursment,
                    }))
                  ),
                  pipe(
                    oldExtenalLoan.variableSymbol,
                    option.map(variableSymbol => ({
                      variant: "item",
                      label:
                        "StandardLoan.AccountsForRefinancing.variableSymbolLabel",
                      type: "string",
                      oldValue: option.some(variableSymbol.toString()),
                      newValue: pipe(
                        newExtenalLoan.variableSymbol,
                        option.map(newVal => newVal.toString())
                      ),
                    }))
                  ),
                ],
                array.compact
              )
            ),
            option.getOrElse<ReworkChangesItem[]>(constant([]))
          )
        )
      )
    )
  );
}

export function useGetPersonalDataChanges(
  personalData: Option<PersonalDataFromOutput>,
  oldPersonalData: Option<PersonalDataFromOutput>
) {
  const formatMessage = useFormatMessage();

  return pipe(
    personalData,
    option.chain(personalData =>
      pipe(
        oldPersonalData,
        option.map(oldPersonalData =>
          pipe(
            oldPersonalData,
            array.map(oldPersonalDataEntry =>
              pipe(
                personalData,
                array.findFirst(ad =>
                  eq.eqString.equals(ad.key, oldPersonalDataEntry.key)
                ),
                option.map(personalDataEntry =>
                  pipe(
                    oldPersonalDataEntry,
                    foldPersonalDataField<ReworkChangesItem>(
                      boolField => ({
                        variant: "item",
                        label: oldPersonalDataEntry.key,
                        type: "boolean",
                        oldValue: option.some(boolField.value),
                        newValue: option.some(
                          personalDataEntry.value as boolean
                        ),
                      }),
                      dateField => ({
                        variant: "item",
                        label: oldPersonalDataEntry.key,
                        type: "date",
                        oldValue: option.some(dateField.value),
                        newValue: option.some(personalDataEntry.value as Date),
                      }),
                      numberField => ({
                        variant: "item",
                        label: oldPersonalDataEntry.key,
                        type: "money",
                        oldValue: numberField.value,
                        newValue: personalDataEntry.value as Option<number>,
                      }),
                      stringField => ({
                        variant: "item",
                        label: oldPersonalDataEntry.key,
                        type: "string",
                        oldValue: pipe(
                          personalData,
                          array.findFirst(v => v.key === stringField.key),
                          option.chain(v =>
                            pipe(
                              v.options as Option<NonEmptyArray<any>>,
                              option.chain(
                                array.findFirstMap(v =>
                                  v.value === stringField.value
                                    ? pipe(
                                        v.name,
                                        option.map(v =>
                                          formatMessage(v as RuntimeLocaleKey)
                                        )
                                      )
                                    : option.none
                                )
                              )
                            )
                          )
                        ),
                        newValue: option.some(
                          pipe(
                            personalData,
                            array.findFirst(
                              v => v.key === personalDataEntry.key
                            ),
                            option.chain(v =>
                              pipe(
                                v.options as Option<NonEmptyArray<any>>,
                                option.chain(
                                  array.findFirstMap(v =>
                                    v.value === personalDataEntry.value
                                      ? pipe(
                                          v.name,
                                          option.map(v =>
                                            formatMessage(v as RuntimeLocaleKey)
                                          )
                                        )
                                      : option.none
                                  )
                                )
                              )
                            ),
                            option.getOrElse(constant(stringField.value))
                          )
                        ),
                      }),
                      numberField => ({
                        variant: "item",
                        label: oldPersonalDataEntry.key,
                        type: "money",
                        oldValue: numberField.value,
                        newValue: personalDataEntry.value as Option<number>,
                      }),
                      companyICOField => ({
                        variant: "item",
                        label: oldPersonalDataEntry.key,
                        type: "string",
                        oldValue: option.some(companyICOField.value),
                        newValue: option.some(
                          personalDataEntry.value as LocalizedString
                        ),
                      }),
                      companyNameField => ({
                        variant: "item",
                        label: oldPersonalDataEntry.key,
                        type: "string",
                        oldValue: option.some(companyNameField.value),
                        newValue: option.some(
                          personalDataEntry.value as LocalizedString
                        ),
                      })
                    )
                  )
                )
              )
            ),
            array.compact
          )
        )
      )
    )
  );
}
