import {
  Button,
  SelectionCard,
  FormRow,
  FormSection,
  ReadOnlyField,
  Space,
  Stack,
  Upselling,
  InsuranceIcon,
  Heading,
  useIsMobileLayout,
  AngleDownIcon,
  AngleUpIcon,
} from "design-system";
import {
  useFormatMessage,
  useFormatMoneyAmountValue,
  useFormatPercentageLike,
} from "../../intl";
import { useCommand } from "../../useAPI";
import * as apis from "./api";
import { constFalse, constNull, pipe } from "fp-ts/function";
import { boolean, option, taskEither } from "fp-ts";
import * as classes from "./OfferChooseCard.treat";
import { useState } from "react";
import { GenericLoanOffer, GenericLoanResponseOutput } from "../api";

type Props = {
  selected?: boolean;
  onComplete: (selectedOffer: GenericLoanResponseOutput) => void;
  offer: GenericLoanOffer;
  expanded: boolean;
};

export function OfferChooseCard(props: Props) {
  const formatMessage = useFormatMessage();
  const formatMoneyAmount = useFormatMoneyAmountValue();
  const formatPercentage = useFormatPercentageLike();

  const saveSelectedOffer = useCommand(apis.saveSelectedOffer);

  const onOfferChoose = pipe(
    saveSelectedOffer(props.offer),
    taskEither.chain(selectedOffer =>
      taskEither.fromIO(() => props.onComplete(selectedOffer))
    )
  );

  const isMobileLayout = useIsMobileLayout();
  const [isCollapsed, setIsCollapsed] = useState(!props.expanded);
  const isContentVisible = !isMobileLayout || (isMobileLayout && !isCollapsed);

  const cardTitle = () =>
    pipe(
      props.offer.offerType,
      option.fold(
        () =>
          formatMessage("StandardLoan.OfferApproved.ChooseCard.yourOfferTitle"),
        offerType => {
          switch (offerType) {
            case "EXTENDED_DURATION":
              return formatMessage(
                "StandardLoan.OfferApproved.ChooseCard.extendedDurationOffer"
              );
            case "HIGHER_AMOUNT":
              return formatMessage(
                "StandardLoan.OfferApproved.ChooseCard.higherAmountOffer"
              );
            case "COUNTER_OFFER":
              return formatMessage(
                "StandardLoan.CounterOffer.counterOfferTitle"
              );
            case "CUSTOMER_OFFER":
              return formatMessage(
                "StandardLoan.OfferApproved.ChooseCard.yourOfferTitle"
              );
          }
        }
      )
    );

  const hasRefinancing = pipe(
    props.offer.offerType,
    option.fold(() => option.isSome(props.offer.newMoneyAmount), constFalse)
  );

  const variant = props.selected ? "primary" : "secondary";

  return (
    <SelectionCard variant={variant}>
      <Stack
        column
        grow
        shrink
        units={8}
        className={
          isMobileLayout ? classes.cardContentMobile : classes.cardContent
        }
      >
        <Stack
          units={2}
          vAlignContent="center"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <Heading size="small" weight="medium">
            {cardTitle()}
          </Heading>
          {isMobileLayout &&
            (isCollapsed ? (
              <AngleDownIcon size="small" className={classes.angleIcon} />
            ) : (
              <AngleUpIcon size="small" className={classes.angleIcon} />
            ))}
        </Stack>
        <Stack column grow shrink units={4}>
          <Stack column units={4}>
            <FormSection>
              {pipe(
                props.offer.newMoneyAmount,
                option.fold(constNull, newMoneyAmount => (
                  <FormRow type="full">
                    <ReadOnlyField
                      size="big"
                      label={formatMessage(
                        "StandardLoan.OfferApproved.ChooseCard.refinancingAmount"
                      )}
                      value={formatMoneyAmount({
                        amount: newMoneyAmount,
                        currency: props.offer.currencyCode,
                      })}
                    />
                  </FormRow>
                ))
              )}
              <FormRow type="full">
                <ReadOnlyField
                  size="medium"
                  label={formatMessage(
                    "StandardLoan.OfferApproved.ChooseCard.loanAmount"
                  )}
                  value={formatMoneyAmount({
                    amount: props.offer.borrowedAmount,
                    currency: props.offer.currencyCode,
                  })}
                />
              </FormRow>
              {isContentVisible && (
                <FormRow type="full">
                  <ReadOnlyField
                    size="medium"
                    label={formatMessage(
                      "StandardLoan.OfferApproved.ChooseCard.monthlyInstallment"
                    )}
                    value={formatMoneyAmount({
                      amount: props.offer.installmentAmount,
                      currency: props.offer.currencyCode,
                    })}
                  />
                </FormRow>
              )}
              {isContentVisible && (
                <FormRow type="full">
                  <ReadOnlyField
                    size="medium"
                    label={formatMessage(
                      "StandardLoan.OfferApproved.ChooseCard.durationOfInstallments"
                    )}
                    value={formatMessage(
                      "StandardLoan.OfferApproved.ChooseCard.durationOfInstallmentsValue",
                      {
                        duration: props.offer.tenor,
                      }
                    )}
                  />
                </FormRow>
              )}
              {isContentVisible && (
                <FormRow type="full">
                  <ReadOnlyField
                    size="medium"
                    label={formatMessage(
                      "StandardLoan.OfferApproved.ChooseCard.interestRate"
                    )}
                    value={formatMessage(
                      "StandardLoan.OfferApproved.ChooseCard.interestRateValue",
                      {
                        rate: formatPercentage(props.offer.interestRateDecimal),
                      }
                    )}
                  />
                </FormRow>
              )}
              {isContentVisible &&
                pipe(
                  props.offer.cpimonthlyAmount,
                  option.fold(constNull, cpimonthlyAmount => (
                    <FormRow type="full">
                      <ReadOnlyField
                        size="medium"
                        label={formatMessage(
                          "StandardLoan.OfferApproved.ChooseCard.cpiMonthlyAmount"
                        )}
                        value={formatMoneyAmount({
                          amount: cpimonthlyAmount,
                          currency: props.offer.currencyCode,
                        })}
                      />
                    </FormRow>
                  ))
                )}
            </FormSection>
            {pipe(
              !hasRefinancing && !isMobileLayout,
              boolean.fold(
                () => <></>,
                () => <Space units={18} />
              )
            )}
          </Stack>
          <Space fluid />
          {pipe(
            props.offer.cpiSelectedName,
            option.fold(constNull, cpiName =>
              pipe(
                props.offer.cpimonthlyAmount,
                option.fold(constNull, () => (
                  <Upselling
                    included
                    content={formatMessage(
                      "StandardLoan.OfferApproved.ChooseCard.insurance",
                      {
                        insurance: formatMessage(cpiName),
                      }
                    )}
                    icon={InsuranceIcon}
                  />
                ))
              )
            )
          )}
          {isContentVisible && (
            <Button
              label={formatMessage(
                "StandardLoan.OfferApproved.ChooseCard.chooseOption"
              )}
              variant="secondary"
              size="default"
              action={onOfferChoose}
            />
          )}
        </Stack>
      </Stack>
    </SelectionCard>
  );
}
