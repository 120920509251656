import {
  Stack,
  LocalizedString,
  Loader,
  ErrorBanner,
  ContentRow,
  Box,
  useIsMobileLayout,
  useForm,
  Button,
  LoadingButton,
  ReadOnlyField,
} from "design-system";
import { boolean, option, taskEither } from "fp-ts";
import { IO } from "fp-ts/IO";
import { pipe, constNull } from "fp-ts/function";
import { useFormatMessage } from "../../intl";
import { PageHeading } from "../../Common/PageHeading/PageHeading";
import { OfferReviewCard } from "./OfferReviewCard";
import { useQuery } from "../../useAPI";
import { RejectionReason } from "../StandardLoanState";
import * as remoteData from "../../RemoteData";
import * as api from "../api";
import { Option } from "fp-ts/Option";
import { ExistingClientAuthenticationMethod } from "../../globalDomain";
// import { useValidators } from "./Common/useValidators";
import { OfferDocuments } from "./OfferDocuments";
import { useState } from "react";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

export type OnApplicationRejected = (result: RejectionReason) => void;

type Props = {
  onBack: IO<unknown>;
  onComplete: () => unknown;
  onApplicationRejected: OnApplicationRejected;
  selectedOffer: api.GenericLoanResponseOutput;
  authenticationMethod: Option<ExistingClientAuthenticationMethod>;
  existingClient: boolean;
  disbursementAccount: Option<NonEmptyString>;
};

export function OfferReviewPush(props: Props) {
  const formatMessage = useFormatMessage();

  const [appStateRequiredDocuments] = useQuery(api.appStateRequiredDocuments);
  const isMobileLayout = useIsMobileLayout();

  // const setDisbursementAccount = useCommand(api.setDisbursementAccount);

  // const { definedNoExtract } = useValidators();

  const disbursementAccount = pipe(
    props.disbursementAccount,
    option.fold(
      () => "",
      account => account
    )
  );

  const label = formatMessage(
    "PreApprovedLoanSaleRecap.disbursementAccounts.label"
  );

  const { handleSubmit } = useForm(
    {
      initialValues: {
        disbursementAccount: option.none as Option<LocalizedString>,
      },
      fieldValidators: () => (props.existingClient ? {} : {}),
    },
    {
      onSubmit: () => taskEither.fromIO(props.onComplete),
    }
  );

  const continueButton = pipe(
    props.authenticationMethod,
    option.fold(
      () => (
        <Button
          label={formatMessage("StandardLoan.OfferApproved.OfferReview.accept")}
          variant="primary"
          size="default"
          action={props.onComplete}
        />
      ),
      () => (
        <LoadingButton
          variant="primary"
          type="submit"
          labels={{
            normal: formatMessage(
              "StandardLoan.OfferApproved.OfferReview.accept"
            ),
            loading: formatMessage(
              "StandardLoan.OfferApproved.OfferReview.accept"
            ),
            error: formatMessage(
              "StandardLoan.OfferApproved.OfferReview.accept"
            ),
            success: formatMessage(
              "StandardLoan.OfferApproved.OfferReview.accept"
            ),
          }}
          size="default"
          action={handleSubmit}
        />
      )
    )
  );
  const [documentsLoading, setDocumentsLoading] = useState(true);
  const handleDocumentsSuccess = () => {
    if (documentsLoading) {
      setDocumentsLoading(false);
    }
    return <OfferDocuments />;
  };
  const handleDocumentsFailure = () => {
    if (documentsLoading) {
      setDocumentsLoading(false);
    }
    return (
      <ErrorBanner>
        {formatMessage("StandardLoan.UploadDocuments.loadingError")}
      </ErrorBanner>
    );
  };

  return (
    <Stack units={10} column>
      <PageHeading
        title={formatMessage("StandardLoan.OfferApproved.OfferReview.title")}
      />
      <ContentRow type="lateral-margins">
        <Box column shrink grow>
          <Stack column units={6}>
            <OfferReviewCard offer={props.selectedOffer} />
            {props.existingClient && (
              <ReadOnlyField
                value={disbursementAccount}
                label={label}
                size="medium"
              />
            )}
            {pipe(
              appStateRequiredDocuments,
              remoteData.fold(
                constNull,
                handleDocumentsFailure,
                handleDocumentsSuccess
              )
            )}
            {documentsLoading && (
              <Box hAlignContent="center">
                <Loader />
              </Box>
            )}
          </Stack>
        </Box>
      </ContentRow>

      <ContentRow type="lateral-margins">
        <Box column shrink grow>
          {pipe(
            isMobileLayout,
            boolean.fold(
              () => continueButton,
              () => continueButton
            )
          )}
        </Box>
      </ContentRow>
    </Stack>
  );
}
