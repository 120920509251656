import { LocaleKey, useFormatMessage } from "../../intl";
import { PaymentDestination, TransactionType } from "./domain";
import {
  AverageIncomingPaymentsId,
  PaymentDestination2,
  SourceOfFundsId,
} from "../../KYC/domain";

export function useFormatPaymentDestination() {
  const formatMessage = useFormatMessage();
  return (paymentDestination: PaymentDestination | PaymentDestination2) =>
    formatMessage(
      `StandardLoan.KYC.Transactions.PaymentDestination.${paymentDestination}` as LocaleKey
    );
}

export function useFormatTransactionType() {
  const formatMessage = useFormatMessage();
  return (transactionType: TransactionType) =>
    formatMessage(
      `StandardLoan.KYC.Transactions.TransactionType.${transactionType}` as LocaleKey
    );
}

export function useFormatAverageIncomingPayments() {
  const formatMessage = useFormatMessage();
  return (paymentAmount: AverageIncomingPaymentsId) =>
    formatMessage(
      `StandardLoan.KYC.Transactions.averageIncomingPayments.${paymentAmount}` as LocaleKey
    );
}

export function useFormatSourceOfFunds() {
  const formatMessage = useFormatMessage();
  return (sourceOfFunds: SourceOfFundsId) =>
    formatMessage(
      `StandardLoan.KYC.Transactions.sourceOfFunds.${sourceOfFunds}` as LocaleKey
    );
}
