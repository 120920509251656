import { useFormatMessage } from "../../intl";
import { array, option, tuple } from "fp-ts";
import { constant, constNull, identity, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import {
  Banner,
  Box,
  Dialog,
  Divider,
  Heading,
  InfoIcon,
  ReadOnlyField,
  FormRow,
  Space,
  Stack,
  useIsMobileLayout,
  Body,
  ContentRow,
  unsafeLocalizedString,
} from "design-system";
import { useState } from "react";
import {
  ReworkChangesItem,
  ReworkChangesItemData,
  StepCommentsOutput,
} from "./api";
import { ReadOnlyDateField } from "../../Common/ReadOnlyDateField/ReadOnlyDateField";
import { ReadOnlyMoneyAmountField } from "../../Common/ReadOnlyMoneyAmountField/ReadOnlyMoneyAmountField";
import { useTenantCurrency } from "../../Common/useTenantCurrency";
import { ReadOnlyYesNoField } from "../../Common/ReadOnlyYesNoField/ReadOnlyYesNoField";
import { useQuery } from "../../useAPI";
import * as api from "../IncomeForm/api";
import * as remoteData from "../../RemoteData";
import { useAppContext } from "../../useAppContext";

type Props = {
  changes: Option<ReworkChangesItem[]>;
  stepComments: Option<StepCommentsOutput>;
  hideChangesSection?: boolean;
  forceDisplayComments?: boolean;
};

export function ReworkBanner(props: Props) {
  const formatMessage = useFormatMessage();
  const currency = useTenantCurrency();
  const isMobileLayout = useIsMobileLayout();

  const [showDetails, setShowDetails] = useState(false);

  const jobPositions = pipe(
    useQuery(api.jobPositions),
    tuple.fst,
    remoteData.fold(constant([]), constant([]), identity)
  );

  const {
    config: { r6Enabled },
  } = useAppContext();

  const renderItemValue = (valueType: "old" | "new") => (
    item: ReworkChangesItemData
  ) => {
    switch (item.type) {
      case "string":
        let stringValue = valueType === "old" ? item.oldValue : item.newValue;
        if (item.label === "StandardLoan.EmployedCard.jobPosition") {
          const jobPosition = pipe(
            jobPositions,
            array.findFirst(
              o =>
                pipe(
                  stringValue,
                  option.getOrElse(constant(""))
                ).toLowerCase() === o.key.toLowerCase()
            )
          );
          const jobPositionName = pipe(
            jobPosition,
            option.fold(
              () => stringValue,
              ({ name }) => option.some(name)
            )
          );
          stringValue = jobPositionName;
        }
        return (
          <ReadOnlyField
            size="small"
            label={formatMessage(item.label)}
            value={pipe(stringValue, option.getOrElse(constant("")))}
          />
        );
      case "money":
        const moneyValue = valueType === "old" ? item.oldValue : item.newValue;
        return pipe(
          moneyValue,
          option.fold(
            constant(
              <ReadOnlyField
                size="x-small"
                label={formatMessage(item.label)}
                value=""
              />
            ),
            value => (
              <ReadOnlyMoneyAmountField
                size="x-small"
                label={formatMessage(item.label)}
                value={{
                  amount: value,
                  currency: currency,
                }}
              />
            )
          )
        );
      case "date":
        const dateValue = valueType === "old" ? item.oldValue : item.newValue;
        return (
          <ReadOnlyDateField
            format="dd-mm-yyyy"
            label={formatMessage(item.label)}
            value={dateValue}
          />
        );
      case "boolean":
        const booleanValue =
          valueType === "old" ? item.oldValue : item.newValue;
        return pipe(
          booleanValue,
          option.fold(
            constant(
              <ReadOnlyField
                size="x-small"
                label={formatMessage(item.label)}
                value=""
              />
            ),
            value => (
              <ReadOnlyYesNoField
                size="small"
                label={formatMessage(item.label)}
                value={value}
              />
            )
          )
        );
    }
  };

  const changesHeader = isMobileLayout ? (
    <Stack grow units={1}>
      <Box grow shrink>
        <Heading size="small" weight="medium">
          {formatMessage("StandardLoan.Rework.Dialog.oldValues")}
        </Heading>
      </Box>
      <Box grow shrink>
        <Heading size="small" weight="medium">
          {formatMessage("StandardLoan.Rework.Dialog.newValues")}
        </Heading>
      </Box>
    </Stack>
  ) : (
    <FormRow type="1-1">
      <Heading size="small" weight="medium">
        {formatMessage("StandardLoan.Rework.Dialog.oldValues")}
      </Heading>
      <Heading size="small" weight="medium">
        {formatMessage("StandardLoan.Rework.Dialog.newValues")}
      </Heading>
    </FormRow>
  );

  const change = (change: ReworkChangesItem) =>
    change.variant === "item" ? (
      isMobileLayout ? (
        <Stack grow units={1}>
          <Box grow shrink basis="0px">
            {pipe(change, renderItemValue("old"))}
          </Box>
          <Box grow shrink basis="0px">
            {pipe(change, renderItemValue("new"))}
          </Box>
        </Stack>
      ) : (
        <FormRow type="1-1">
          {pipe(change, renderItemValue("old"))}
          {pipe(change, renderItemValue("new"))}
        </FormRow>
      )
    ) : (
      <>
        <Space units={4} />
        <Divider />
        <Space units={4} />
      </>
    );

  function shouldDisplay(c: ReworkChangesItem) {
    if (c.variant === "item") {
      let areDifferent = false;
      if (c.type === "string") {
        let old = pipe(
          c.oldValue,
          option.fold(constNull, val => val)
        );
        areDifferent =
          pipe(
            c.newValue,
            option.fold(constNull, val => val)
          ) != old && old != "";
      } else if (c.type === "boolean") {
        areDifferent =
          pipe(
            c.newValue,
            option.fold(constNull, val => val)
          ) !=
          pipe(
            c.oldValue,
            option.fold(constNull, val => val)
          );
      } else if (c.type === "money") {
        areDifferent =
          pipe(
            c.newValue,
            option.fold(constNull, val => val)
          ) !=
          pipe(
            c.oldValue,
            option.fold(constNull, val => val)
          );
      } else if (c.type === "date") {
        areDifferent =
          pipe(
            c.newValue,
            option.fold(constNull, val => val)
          ) !=
          pipe(
            c.oldValue,
            option.fold(constNull, val => val)
          );
      }
      return (
        option.isSome<any>(c.oldValue) &&
        option.isSome<any>(c.newValue) &&
        areDifferent
      );
    }
    return false;
  }

  const renderComments = pipe(
    props.stepComments,
    option.fold(
      () => <></>,
      comments => (
        <Box column hAlignContent="center" vAlignContent="center">
          <Stack units={4} column>
            <ContentRow type="full">
              <Heading size="small" weight="medium" align="left">
                {unsafeLocalizedString("Comments")}
              </Heading>
            </ContentRow>
            {option.isSome(comments.stepComments) &&
              comments.stepComments.value.map(comment => (
                <ContentRow type="full">
                  <Body size="small" weight="regular" align="left">
                    {unsafeLocalizedString(comment)}
                  </Body>
                </ContentRow>
              ))}
          </Stack>
          <Space units={4} />
          <Space units={4} />
        </Box>
      )
    )
  );

  const renderNoChangesBanner = (
    <Banner
      type="informative"
      content={formatMessage("StandardLoan.Rework.noChanges")}
      title={option.none}
      actions={option.none}
      onDismiss={option.none}
    />
  );

  const filterChanges = pipe(
    props.changes,
    option.map(
      array.filter(
        c =>
          c.variant === "divider" || (c.variant === "item" && shouldDisplay(c))
      )
    )
  );

  const areReworkChanges =
    option.isSome(filterChanges) && filterChanges.value.length > 0;

  return (
    <Box grow shrink column>
      <Banner
        type="warning"
        content={formatMessage("StandardLoan.Rework.Banner.title")}
        actions={option.some([
          {
            label: formatMessage("StandardLoan.Rework.Banner.action"),
            action: () => setShowDetails(true),
            variant: "secondary",
          },
        ])}
        onDismiss={option.none}
        title={option.none}
      />
      {showDetails && (
        <Dialog
          onDismiss={option.some(() => setShowDetails(false))}
          actions={[]}
          icon={InfoIcon}
          title={formatMessage("StandardLoan.Rework.Dialog.title")}
          size="medium"
          variant="center"
          subtitle={formatMessage("StandardLoan.Rework.Dialog.subtitle")}
        >
          {r6Enabled &&
            (areReworkChanges || props.forceDisplayComments) &&
            renderComments}
          {!props.hideChangesSection &&
            pipe(
              filterChanges,
              option.fold(constant(renderNoChangesBanner), changes =>
                changes.length > 0 ? (
                  <Stack units={4} column shrink>
                    {changesHeader}
                    {changes.map(change)}
                  </Stack>
                ) : (
                  renderNoChangesBanner
                )
              )
            )}
        </Dialog>
      )}
      <Space units={10} />
    </Box>
  );
}
