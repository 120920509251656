import { Reader } from "fp-ts/Reader";
import { ContentRow, Panel } from "design-system";
import * as api from "../api";
import { useFormatMessage } from "../../intl";
import {
  RefinancingAccountSelection,
  SelectedLoansInformation,
} from "./RefinancingAccountSelection";
import { LiabilityFormModel } from "../domain";
import { boolean, option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { PreapprovedLimitState } from "../CustomerOffer/UpdateCustomerOfferContext";
import { useState } from "react";
import { Currency } from "../../globalDomain";

type Props = {
  onLoanSelection: Reader<SelectedLoansInformation, void>;
  liabilities: api.RefinancingLoanListOutput;
  clientExists: boolean;
  preapprovedLimitState: PreapprovedLimitState;
  refinancingLimitExceeded: boolean;
  maxLoanAmount: number;
  internal: LiabilityFormModel[];
  external: LiabilityFormModel[];
  preapprovedLimit: number;
  preapprovedLimitCurrency: Currency;
  isTopUpActive: boolean;
  eligibleForPreapproved: number;
};

export function RefinancingCreditsCheck(props: Props) {
  const formatMessage = useFormatMessage();
  const [isPanelOpen, setIsPanelOpen] = useState(true);

  return (
    <ContentRow type="lateral-margins">
      <Panel
        title={formatMessage("StandardLoan.CustomerOffer.Refinancing.title")}
        content={() => (
          <RefinancingAccountSelection
            topUp={pipe(
              props.clientExists && props.preapprovedLimit > 0,
              boolean.fold(
                () => option.none,
                () =>
                  option.some({
                    limit: {
                      amount: props.preapprovedLimit,
                      currency: props.preapprovedLimitCurrency,
                    },
                  })
              )
            )}
            isTopUpActive={props.isTopUpActive}
            internal={props.internal}
            external={props.external}
            maximumPreapprovedLimit={props.preapprovedLimit}
            liabilities={props.liabilities}
            onLoanSelection={props.onLoanSelection}
            refinancingLimitExceeded={props.refinancingLimitExceeded}
            maxLoanAmount={props.maxLoanAmount}
            eligibleForPreapproved={props.eligibleForPreapproved}
          />
        )}
        isOpen={isPanelOpen}
        status="active"
        headerIcon={option.none}
        onSelect={option.some(() => setIsPanelOpen(prevState => !prevState))}
        width="100%"
        className="refinancingCreditsCheck"
      />
    </ContentRow>
  );
}
