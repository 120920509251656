import { useFormatMessage } from "../../intl";
import { boolean, option, taskEither } from "fp-ts";
import { Option } from "fp-ts/Option";
import { constNull, constVoid, pipe } from "fp-ts/function";

import {
  Body,
  Box,
  ErrorBanner,
  ErrorIcon,
  Heading,
  NonNegative,
  Space,
  Stack,
  SuccessIcon,
  useForm,
} from "design-system";
import { RefinancingLoanListOutput, selectLoanForRefinancing } from "../api";
import { MoneyAmount } from "../../globalDomain";
import { LiabilityEditableItem } from "./LiabilityEditableItem";
import { LiabilityFormModel } from "../domain";
import { palette } from "design-system/lib/styleConstants";
import { useCommand } from "../../useAPI";
import { useEffect, useRef, useState } from "react";
import { useTenantCurrency } from "../../Common/useTenantCurrency";
import { Reader } from "fp-ts/Reader";

export type TopUpPAL = {
  limit: MoneyAmount;
};

type LoanOrigin = "internal" | "external";

export interface SelectedLoansInformation {
  internal: {
    count: number;
    amount: number;
  };
  external: {
    count: number;
    amount: number;
  };
}

type Props = {
  liabilities: RefinancingLoanListOutput;
  maximumPreapprovedLimit: number;
  topUp: Option<TopUpPAL>;
  onLoanSelection: Reader<SelectedLoansInformation, void>;
  refinancingLimitExceeded: boolean;
  maxLoanAmount: number;
  internal: LiabilityFormModel[];
  external: LiabilityFormModel[];
  isTopUpActive: boolean;
  eligibleForPreapproved: number;
};

function initSelectedLoansForRefinancing(
  liabilities: RefinancingLoanListOutput
): SelectedLoansInformation {
  const selectedInternalLoans = pipe(
    liabilities.internalLoans,
    option.fold(
      () => [],
      ({ liabilitiesList }) =>
        liabilitiesList.filter(loan => loan.selectedForRefinance)
    )
  );

  const selectedExternalLoans = pipe(
    liabilities.externalLoans,
    option.fold(
      () => [],
      ({ liabilitiesList }) =>
        liabilitiesList.filter(loan => loan.selectedForRefinance)
    )
  );

  return {
    internal: {
      count: selectedInternalLoans.length,
      amount: selectedInternalLoans.reduce(
        (res, loan) => res + loan.remainingAmount.amount,
        0
      ),
    },
    external: {
      count: selectedExternalLoans.length,
      amount: selectedExternalLoans.reduce(
        (res, loan) => res + loan.remainingAmount.amount,
        0
      ),
    },
  };
}

export function RefinancingAccountSelection(props: Props) {
  const formatMessage = useFormatMessage();
  const currency = useTenantCurrency();
  const selectLoanCommand = useCommand(selectLoanForRefinancing);

  const selectedLoansForRefinancing = useRef<SelectedLoansInformation>(
    initSelectedLoansForRefinancing(props.liabilities)
  );

  const [showAmountIsTooHighError, setAmountIsTooHighError] = useState<
    Option<NonNegative>
  >(option.none);

  useEffect(() => {
    props.onLoanSelection(selectedLoansForRefinancing.current);
  }, []);

  const { fieldArray } = useForm(
    {
      initialValues: {
        internal: props.internal,
        external: props.external,
      },
      fieldValidators: () => ({}),
      fieldArrayValidators: () => ({}),
    },
    {
      onSubmit: () => taskEither.fromIO(constVoid),
    }
  );

  const topUp = pipe(
    props.topUp,
    option.map(topUp => (
      <Stack column units={4}>
        <Heading size="small" weight="medium">
          {formatMessage("StandardLoan.RefinancingAccountSelection.topUpTitle")}
        </Heading>
        <Body size="medium" weight="regular">
          {formatMessage(
            "StandardLoan.RefinancingAccountSelection.topUpMessage",
            { ...topUp.limit }
          )}
        </Body>
        {props.isTopUpActive && (
          <>
            <Space units={4} />
            <Box>
              <SuccessIcon size="small" color={palette.success800} />
              <Body size="small" weight="regular" color={palette.success800}>
                {formatMessage(
                  "StandardLoan.RefinancingAccountSelection.topUpActiveDetails"
                )}
              </Body>
            </Box>
          </>
        )}
        {!props.isTopUpActive && (
          <>
            <Space units={4} />
            <Box>
              <ErrorIcon size="medium" color={palette.error900} />
              <Body size="small" weight="regular" color={palette.error900}>
                {formatMessage(
                  "StandardLoan.RefinancingAccountSelection.topUpNotActive"
                )}
              </Body>
            </Box>
          </>
        )}
      </Stack>
    )),
    option.getOrElse(() => <></>)
  );

  const internalFieldArray = fieldArray("internal");
  const externalFieldArray = fieldArray("external");

  const selectLoan = (origin: LoanOrigin, loanData: LiabilityFormModel) =>
    pipe(
      selectLoanCommand({
        recordId: loanData.recordId,
        type: loanData.type,
        selectedForRefinance: loanData.selected,
      }),
      taskEither.chain(response =>
        taskEither.fromIO(() =>
          setAmountIsTooHighError(option.some(response.maxLoanAmount))
        )
      ),
      taskEither.chain(() =>
        taskEither.fromIO(() => {
          const countDifference = loanData.selected ? +1 : -1;
          const amountDifference = loanData.selected
            ? loanData.remainingAmount.amount
            : -loanData.remainingAmount.amount;

          switch (origin) {
            case "internal":
              selectedLoansForRefinancing.current = {
                ...selectedLoansForRefinancing.current,
                internal: {
                  count:
                    selectedLoansForRefinancing.current.internal.count +
                    countDifference,
                  amount:
                    selectedLoansForRefinancing.current.internal.amount +
                    amountDifference,
                },
              };
              break;
            case "external":
              selectedLoansForRefinancing.current = {
                ...selectedLoansForRefinancing.current,
                external: {
                  count:
                    selectedLoansForRefinancing.current.external.count +
                    countDifference,
                  amount:
                    selectedLoansForRefinancing.current.external.amount +
                    amountDifference,
                },
              };
              break;
          }

          props.onLoanSelection(selectedLoansForRefinancing.current);
        })
      )
    )();

  return (
    <Box column grow shrink>
      <Body size="medium" weight="regular">
        {formatMessage("StandardLoan.RefinancingAccountSelection.subTitle")}
      </Body>
      <Space units={4} />
      {props.eligibleForPreapproved === 1 &&
        option.isSome(props.topUp) &&
        topUp}

      {internalFieldArray.items.length > 0 && (
        <>
          <Space units={5} />
          <Heading size="x-small" weight="medium">
            {formatMessage(
              "StandardLoan.AccountsForRefinancing.InternalLiabilities.title"
            )}
          </Heading>
          <Space units={4} />
        </>
      )}

      {internalFieldArray.items.map(
        ({ fieldProps, namePrefix, onChangeValues }, index) => (
          <>
            <LiabilityEditableItem
              key={index}
              fieldProps={fieldProps}
              namePrefix={namePrefix}
              onChangeValues={value => {
                onChangeValues(value);
                selectLoan("internal", value);
              }}
              divider={
                internalFieldArray.items.length > 1 &&
                index < internalFieldArray.items.length - 1
              }
            />
            <Space units={4} />
          </>
        )
      )}

      {externalFieldArray.items.length > 0 && (
        <>
          <Space units={5} />
          <Heading size="x-small" weight="medium">
            {formatMessage(
              "StandardLoan.AccountsForRefinancing.ExternalLiabilities.title"
            )}
          </Heading>
          <Space units={4} />
        </>
      )}
      {externalFieldArray.items.map(
        ({ fieldProps, namePrefix, onChangeValues }, index) => (
          <>
            <LiabilityEditableItem
              key={index}
              fieldProps={fieldProps}
              namePrefix={namePrefix}
              onChangeValues={value => {
                onChangeValues(value);
                selectLoan("external", value);
              }}
              divider={
                externalFieldArray.items.length > 1 &&
                index < externalFieldArray.items.length - 1
              }
            />
            <Space units={4} />
          </>
        )
      )}
      <Space units={4} />

      {pipe(
        props.refinancingLimitExceeded,
        boolean.fold(constNull, () => (
          <ErrorBanner>
            {formatMessage(
              "StandardLoan.AccountsForRefinancing.amountTooHigh",
              {
                amount: pipe(
                  showAmountIsTooHighError,
                  option.fold(
                    () => props.maxLoanAmount,
                    maxAmount => maxAmount
                  )
                ),
                currency: currency,
              }
            )}
          </ErrorBanner>
        ))
      )}
    </Box>
  );
}
