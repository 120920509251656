import * as t from "io-ts";
import { IncomeSourceType } from "../IncomeForm/domain";

export const PaymentDestination = t.keyof({
  Domestic: true,
  ForeignEU: true,
  OutsideEU: true,
});
export type PaymentDestination = t.TypeOf<typeof PaymentDestination>;

export const TransactionType = t.keyof({
  CashAndCashless: true,
  Cashless: true,
  Cash: true,
});
export type TransactionType = t.TypeOf<typeof TransactionType>;

export const incomeSourcesWithIndustry: Array<IncomeSourceType> = [
  "Employed",
  "Freelancer",
  "EmployedAbroad",
  "CompanyOwner",
  "TradesmanCoOperatingPerson",
];
