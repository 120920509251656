import { FeedbackBlock, Action, LocalizedString } from "design-system";
import { useFormatMessage } from "../../intl";
import { option } from "fp-ts";
import { Reader } from "fp-ts/Reader";
import { ComponentProps } from "react";
import { IO } from "fp-ts/IO";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { RejectionReason } from "../StandardLoanState";
import { StandardLoanFlowType } from "../domain";

type BasicProps = {
  onGotoClientsPage: Option<IO<unknown>>;
};

type ApprovalProps = BasicProps & {
  decision: "Approved";
  isNonWorkingDay: boolean;
  isSummary: boolean;
  clientExists: boolean;
  flowType: StandardLoanFlowType;
};

type PendingProps = BasicProps & {
  decision: "Pending";
  isRefinancing: boolean;
  isSummary: boolean;
  flowType: StandardLoanFlowType;
};

type RejectionProps = BasicProps & {
  decision: "Rejected";
  rejectionReason: RejectionReason;
  flowType: StandardLoanFlowType;
};

type Props = ApprovalProps | PendingProps | RejectionProps;

function foldProps<T>(matches: {
  whenApproved: Reader<ApprovalProps, T>;
  whenPending: Reader<PendingProps, T>;
  whenRejected: Reader<RejectionProps, T>;
}): Reader<Props, T> {
  return props => {
    switch (props.decision) {
      case "Approved":
        return matches.whenApproved(props);
      case "Pending":
        return matches.whenPending(props);
      case "Rejected":
        return matches.whenRejected(props);
    }
  };
}

export function FinalFeedback(props: Props) {
  const formatMessage = useFormatMessage();

  function getTitle(): LocalizedString {
    switch (props.decision) {
      case "Approved":
        return props.isSummary
          ? props.clientExists
            ? formatMessage("StandardLoan.Approval.summary.title")
            : formatMessage("StandardLoan.Approval.newClient.summary.title")
          : formatMessage("StandardLoan.Approval.title");
      case "Pending":
        return props.isSummary
          ? formatMessage("StandardLoan.Pending.summary.title")
          : props.isRefinancing
          ? formatMessage("StandardLoan.Refinancing.title")
          : formatMessage("StandardLoan.Pending.title");
      case "Rejected":
        switch (props.rejectionReason) {
          case "LFRejected":
            return formatMessage("StandardLoan.Rejection.LoanFactory.title");
          case "CBRejected":
            return formatMessage("StandardLoan.Rejection.CreditBureau.title");
          case "BonitaRejected":
            return formatMessage("StandardLoan.Rejection.Bonita.title");
          case "RejectedWithLead":
            return formatMessage("StandardLoan.Rejection.WithLead.title");
          case "ExposureExceeded":
            return formatMessage("StandardLoan.Rejection.CustomerOffer.title");
          case "RestoredTooEarly":
            return formatMessage("StandardLoan.Rejection.Restore.title");
          case "hasBlockingNotesCF":
          case "invalidAgeCF":
          case "privateClient":
          case "phoneNumberNotVerifiedCF":
          case "phoneNumberMissingCF":
          case "highRiskClientCF":
          case "hasNotPasswordForCommunicationCF":
          case "foreignClientCF":
          case "KYC_ID_FIXED_EZY":
          case "KYC_ID_FIXED_EZY_UPDATE_CONTACTS":
          case "KYC_FIXED_EZY_UPDATE_CONTACTS":
          case "KYC_FIXED_EZY":
          case "UPDATE_CONTACTS":
          case "ID_FIXED_EZY_UPDATE_CONTACTS":
          case "ID_FIXED_EZY":
          case "UPDATE_ID_CONTACTS":
          case "UPDATE_ID":
          case "UPDATE_PASSWORD":
          case "UPDATE_ID_PASSWORD":
          case "UPDATE_ID_CONTACTS_PASSWORD":
          case "UPDATE_ID_OTHER_THAN_AUTH_ID":
          case "UPDATE_ID_MISSING_INFORMATION":
          case "USER_VALIDATION_KO_REDIRECT_BRANCH":
            return formatMessage("UserValidation.loading.title");
        }
    }
  }

  function getText(): LocalizedString {
    switch (props.decision) {
      case "Approved":
        return props.isNonWorkingDay
          ? formatMessage("StandardLoan.Approval.text.nonWorkingDay")
          : props.isSummary
          ? props.clientExists
            ? formatMessage("StandardLoan.Approval.summary.text")
            : formatMessage("StandardLoan.Approval.newClient.summary.text")
          : formatMessage("StandardLoan.Approval.text");
      case "Pending":
        return props.isSummary
          ? formatMessage("StandardLoan.Pending.summary.text")
          : props.isRefinancing
          ? formatMessage("StandardLoan.Refinancing.text")
          : formatMessage("StandardLoan.Pending.text");
      case "Rejected":
        switch (props.rejectionReason) {
          case "LFRejected":
            return formatMessage("StandardLoan.Rejection.LoanFactory.message");
          case "CBRejected":
            return formatMessage("StandardLoan.Rejection.CreditBureau.message");
          case "BonitaRejected":
            return formatMessage("StandardLoan.Rejection.Bonita.message");
          case "RejectedWithLead":
            return formatMessage("StandardLoan.Rejection.WithLead.message");
          case "ExposureExceeded":
            return formatMessage(
              "StandardLoan.Rejection.CustomerOffer.message"
            );
          case "RestoredTooEarly":
            return formatMessage("StandardLoan.Rejection.Restore.message");
          case "hasBlockingNotesCF":
            return formatMessage(
              "ClientProfile.cfExisting.blockingNotes.failure.message"
            );
          case "invalidAgeCF":
            return formatMessage(
              "ClientProfile.cfExisting.minimumAge.failure.message"
            );
          case "privateClient":
            return formatMessage(
              "ClientProfile.savingsAccountOpening.privateClient.failure.message"
            );
          case "phoneNumberNotVerifiedCF":
            return formatMessage(
              "ClientProfile.cfExisting.phoneNumberNotVerified.failure.message"
            );
          case "phoneNumberMissingCF":
            return formatMessage(
              "ClientProfile.phoneNumberMissing.failure.message"
            );
          case "highRiskClientCF":
            return formatMessage(
              "ClientProfile.cfExisting.kycCheck.failure.message"
            );
          case "hasNotPasswordForCommunicationCF":
            return formatMessage(
              "ClientProfile.cfExisting.passwordCommunication.failure.message"
            );
          case "foreignClientCF":
            return formatMessage(
              "ClientProfile.cfExisting.nonLocalCitizenBranch.failure.message"
            );
          case "KYC_ID_FIXED_EZY":
            return formatMessage(
              "ClientProfile.ukontoOpening.kycAndPrimaryIdToFix.failure.message"
            );
          case "KYC_ID_FIXED_EZY_UPDATE_CONTACTS":
            return formatMessage(
              "ClientProfile.ukontoOpening.kycAndPrimaryIdToFixWithContacts.failure.message"
            );
          case "KYC_FIXED_EZY_UPDATE_CONTACTS":
            return formatMessage(
              "ClientProfile.ukontoOpening.kycToFixWithContacts.failure.message"
            );
          case "KYC_FIXED_EZY":
            return formatMessage(
              "ClientProfile.ukontoOpening.kycToFix.failure.message"
            );
          case "UPDATE_CONTACTS":
            return formatMessage(
              "ClientProfile.ukontoOpening.updateClientContacts.failure.message"
            );
          case "ID_FIXED_EZY_UPDATE_CONTACTS":
            return formatMessage(
              "ClientProfile.ukontoOpening.primaryIdToFixWithContacts.failure.message"
            );
          case "ID_FIXED_EZY":
            return formatMessage(
              "ClientProfile.ukontoOpening.primaryIdToFix.failure.message"
            );
          case "UPDATE_ID_CONTACTS":
            return formatMessage(
              "ClientProfile.ukontoOpening.updatePrimaryIdWithContacts.failure.message"
            );
          case "UPDATE_ID":
            return formatMessage(
              "ClientProfile.ukontoOpening.updatePrimaryId.failure.message"
            );
          case "UPDATE_PASSWORD":
            return formatMessage(
              "ClientProfile.cfExisting.passwordCommunicationNeeded.failure.message"
            );
          case "UPDATE_ID_PASSWORD":
            return formatMessage(
              "ClientProfile.cfExisting.updateIdPassword.failure.message"
            );
          case "UPDATE_ID_CONTACTS_PASSWORD":
            return formatMessage(
              "ClientProfile.cfExisting.updateIdContactsPassword.failure.message"
            );
          case "UPDATE_ID_OTHER_THAN_AUTH_ID":
            return formatMessage(
              "ClientProfile.cfExisting.updateIdOtherThanAuthId.failure.message"
            );
          case "UPDATE_ID_MISSING_INFORMATION":
            return formatMessage(
              "ClientProfile.cfExisting.updateIdMissingInformation.failure.message"
            );
          case "USER_VALIDATION_KO_REDIRECT_BRANCH":
            return formatMessage(
              "ClientProfile.userValidation.failure.message.redirect.branch"
            );
        }
    }
  }

  function getFeedbackType(): ComponentProps<typeof FeedbackBlock>["type"] {
    switch (props.decision) {
      case "Approved":
        return "positive"; // props.clientExists ? "positive" : "loading";
      case "Pending":
        return "positive";
      case "Rejected":
        return "negative";
    }
  }

  return (
    <FeedbackBlock
      type={getFeedbackType()}
      heading={getTitle()}
      subheading={option.some(getText())}
      actions={pipe(
        props.onGotoClientsPage,
        option.fold<IO<unknown>, [] | [Action]>(
          () => [],
          action => [
            {
              variant: "primary",
              label: pipe(
                props,
                foldProps({
                  whenApproved: function () {
                    return props.flowType === "PWSRemote"
                      ? formatMessage("StandardLoan.FinalDecision.gotoHomePage")
                      : formatMessage(
                          "StandardLoan.FinalDecision.gotoClientPage"
                        );
                  },
                  whenPending: function () {
                    return props.flowType === "PWSRemote"
                      ? formatMessage("StandardLoan.FinalDecision.gotoHomePage")
                      : formatMessage(
                          "StandardLoan.FinalDecision.gotoClientPage"
                        );
                  },
                  whenRejected: function (props) {
                    return props.flowType === "3P" ||
                      props.flowType === "PWSRemote"
                      ? formatMessage("StandardLoan.FinalDecision.gotoHomePage")
                      : props.rejectionReason === "phoneNumberMissingCF"
                      ? formatMessage(
                          "ClientProfile.clientData.missingPhoneNumber"
                        )
                      : formatMessage(
                          "StandardLoan.FinalDecision.gotoClientPage"
                        );
                  },
                })
              ),
              action,
            },
          ]
        )
      )}
      {...pipe(
        props,
        foldProps({
          whenApproved: function (props) {
            return props.isSummary ? { size: "medium" } : { size: "large" };
          },
          whenPending: function (props) {
            return props.isSummary
              ? {
                  size: "medium",
                  inlineLoaderMessage: formatMessage(
                    "StandardLoan.Pending.summary.progress"
                  ),
                }
              : { size: "large" };
          },
          whenRejected: function () {
            return { size: "large" };
          },
        })
      )}
    />
  );
}
