import {
  Body,
  Dialog,
  Divider,
  FormRow,
  FormSection,
  Heading,
  ReadOnlyField,
  Space,
  Stack,
  unsafeLocalizedString,
} from "design-system";
import { option } from "fp-ts";
import { constNull, pipe } from "fp-ts/function";
import {
  useFormatMessage,
  useFormatMoneyAmountValue,
  useFormatPercentageLike,
} from "../../intl";
import { GenericLoanResponseOutput } from "../api";
import { useAppContext } from "../../useAppContext";
import { unsafeNonNegativeInteger } from "../../globalDomain";

type Props = {
  offer: GenericLoanResponseOutput;
  onDismiss: () => unknown;
};

export function OfferReviewDetailDialog(props: Props) {
  const {
    config: { enableBankFee50FE: enableBankFee50 },
  } = useAppContext();

  const formatMessage = useFormatMessage();
  const formatMoneyAmount = useFormatMoneyAmountValue();
  const formatPercentage = useFormatPercentageLike();
  const formatMoneyAmountValueInteger = useFormatMoneyAmountValue(
    unsafeNonNegativeInteger(0)
  );

  const cpiMonthlyAmount = pipe(
    props.offer.monthlyInsuranceAmount,
    option.getOrElse(() => 0)
  );

  return (
    <Dialog
      variant="left"
      size="small"
      title={unsafeLocalizedString("")}
      onDismiss={option.some(props.onDismiss)}
      actions={[]}
    >
      <Heading size="medium" weight="medium">
        {formatMessage("StandardLoan.OfferApproved.OfferReview.Detail.title")}
      </Heading>
      <Space units={8} />
      <Stack units={32} vAlignContent={"top"}>
        <FormSection>
          <FormRow type="full">
            <ReadOnlyField
              size="small"
              label={formatMessage(
                "StandardLoan.OfferApproved.OfferReview.Detail.totalLoanAmount"
              )}
              value={formatMoneyAmount({
                amount: props.offer.totalAmount,
                currency: props.offer.currency,
              })}
            />
          </FormRow>
          <FormRow type="full">
            <ReadOnlyField
              size="small"
              label={formatMessage(
                "StandardLoan.OfferApproved.OfferReview.Detail.monthlyInstallment"
              )}
              value={formatMoneyAmount({
                amount: props.offer.installment,
                currency: props.offer.currency,
              })}
            />
          </FormRow>
          <FormRow type="full">
            <ReadOnlyField
              size="small"
              label={formatMessage(
                "StandardLoan.OfferApproved.OfferReview.Detail.interestRate"
              )}
              value={formatMessage(
                "StandardLoan.OfferApproved.OfferReview.Detail.interestRateValue",
                {
                  rate: formatPercentage(props.offer.interestRate), // @TODO: check if formatting is correct
                }
              )}
            />
          </FormRow>
          {cpiMonthlyAmount > 0 && (
            <FormRow type="full">
              <ReadOnlyField
                size="small"
                label={formatMessage(
                  "StandardLoan.OfferApproved.OfferReview.Detail.cpiMonthlyAmount"
                )}
                value={formatMoneyAmount({
                  amount: cpiMonthlyAmount,
                  currency: props.offer.currency,
                })}
              />
            </FormRow>
          )}
        </FormSection>
        <FormSection>
          <FormRow type="full">
            <ReadOnlyField
              size="small"
              label={formatMessage(
                "StandardLoan.OfferApproved.OfferReview.Detail.loanDuration"
              )}
              value={formatMessage(
                "StandardLoan.OfferApproved.OfferReview.Detail.loanDurationValue",
                {
                  months: props.offer.tenor,
                }
              )}
            />
          </FormRow>
          {cpiMonthlyAmount > 0 && (
            <FormRow type="full">
              <ReadOnlyField
                size="small"
                label={unsafeLocalizedString("")}
                value={unsafeLocalizedString(" ")}
              />
            </FormRow>
          )}
          <FormRow type="full">
            <ReadOnlyField
              size="small"
              label={formatMessage(
                "StandardLoan.OfferApproved.OfferReview.Detail.apr"
              )}
              value={formatMessage(
                "StandardLoan.OfferApproved.OfferReview.Detail.aprValue",
                {
                  apr: formatPercentage(props.offer.apr),
                }
              )}
            />
          </FormRow>
          {pipe(
            props.offer.totalPayableAmount,
            option.fold(constNull, totalPayableAmount => (
              <FormRow type="full">
                <ReadOnlyField
                  size="small"
                  label={formatMessage(
                    "StandardLoan.OfferApproved.OfferReview.Detail.totalToPay"
                  )}
                  value={formatMoneyAmount({
                    amount: totalPayableAmount,
                    currency: props.offer.currency,
                  })}
                />
              </FormRow>
            ))
          )}
          {enableBankFee50 &&
            pipe(
              props.offer.bankingFee,
              option.fold(constNull, bankingFee =>
                pipe(
                  props.offer.bankingFeeIncluded,
                  option.fold(
                    () => (
                      <ReadOnlyField
                        size="medium"
                        label={formatMessage(
                          "StandardLoan.CustomerOffer.detailsModal.bankingFees.label"
                        )}
                        value={formatMoneyAmountValueInteger({
                          amount: bankingFee,
                          currency: props.offer.currency,
                        })}
                      />
                    ),
                    feeIncluded => (
                      <ReadOnlyField
                        size="medium"
                        label={formatMessage(
                          feeIncluded
                            ? "StandardLoan.CustomerOffer.detailsModal.bankingFees.included.label"
                            : "StandardLoan.CustomerOffer.detailsModal.bankingFees.notIncluded.label"
                        )}
                        value={formatMoneyAmountValueInteger({
                          amount: bankingFee,
                          currency: props.offer.currency,
                        })}
                      />
                    )
                  )
                )
              )
            )}
        </FormSection>
      </Stack>
      <Space units={6} />
      <Divider />
      <Space units={6} />
      <Body size="small" weight="regular">
        {formatMessage(
          "StandardLoan.OfferApproved.OfferReview.Detail.description"
        )}
      </Body>
    </Dialog>
  );
}
