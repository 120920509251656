import { Option } from "fp-ts/Option";
import { ExtractedDataResult } from "../../UploadDocuments/types";
import { option } from "fp-ts";
import { constNull, pipe } from "fp-ts/function";
import {
  Body,
  Box,
  Button,
  ContentRow,
  Divider,
  Heading,
  PageHeading,
  Space,
  Stack,
  useIsMobileLayout,
  useIsTouchScreen,
} from "design-system";
import { ReworkBanner } from "./ReworkBanner";
import * as reworkApi from "./api";
import { StepCommentsOutput } from "./api";
import { useFormatDocumentType, useFormatMessage } from "../../intl";
import { AddressForm } from "../../IdUpload/AddressForm/AddressForm";
import {
  formStateFromPersonalDataOCR,
  PersonalDataForm,
} from "../../IdUpload/ClientData/PersonalDataForm/PersonalDataForm";
import { PersonalDocumentOCR } from "../../IdUpload/domain";
import { DocumentType } from "../../UploadDocuments/domain";
import {
  formStateFromPersonalDocumentOCR,
  PersonalDocumentForm,
} from "../../IdUpload/ClientData/PersonalDocumentForm/PersonalDocumentForm";
import {
  AddressFormState,
  formStateFromAddressOCR,
  useDefaultCountryCode,
} from "../../IdUpload/addressFormUtils";
import { IO } from "fp-ts/IO";
import {
  mergeExtractDataDocumentsReworkOutput,
  useGetPersonalDataStepChanges,
} from "./utils";
import { useCommand } from "../../useAPI";
import { useAppContext } from "../../useAppContext";
import { useRemoteData } from "../../useRemoteData";
import { useMemo } from "react";
import * as remoteData from "../../RemoteData";
import { MainContent } from "../../Common/MainContent";

type Props = {
  clientData: ExtractedDataResult;
  reworkData: Option<reworkApi.ReworkOldValuesOutput>;
  onContinue: IO<void>;
};

export function ConfirmClientData(props: Props) {
  const formatMessage = useFormatMessage();
  const formatDocumentType = useFormatDocumentType();
  const isMobileLayout = useIsMobileLayout();
  const defaultCountry = useDefaultCountryCode();

  const reworkDocument = pipe(
    props.reworkData,
    option.chain(r => r.personalDataConfirmation),
    option.chain(pd => {
      const { primary, secondary } = pd;
      const documentPayload = mergeExtractDataDocumentsReworkOutput(
        primary,
        secondary
      );

      return pipe(
        documentPayload,
        option.map(document => ({
          ...document,
        }))
      );
    })
  );

  const changes = useGetPersonalDataStepChanges(
    props.clientData,
    reworkDocument
  );

  const stepComments = useCommand(reworkApi.stepComments);

  const {
    config: { r6Enabled },
  } = useAppContext();

  const comments = useRemoteData(
    useMemo(() => stepComments({ reworkCategories: ["PERSONAL_ID"] }), [
      r6Enabled,
    ])
  );

  const reworkComments = pipe(
    comments,
    remoteData.fold(
      () => option.none,
      () => option.none,
      comments => option.some(comments)
    )
  );

  const reworkBanner = (comments: Option<StepCommentsOutput>) => {
    return pipe(
      props.reworkData,
      option.fold(constNull, () => (
        <ReworkBanner changes={changes} stepComments={comments} />
      ))
    );
  };

  const areReworkChanges = option.isSome(changes) && changes.value.length > 0;

  const areReworkComments =
    option.isSome(reworkComments) &&
    option.isSome(reworkComments.value.stepComments) &&
    reworkComments.value.stepComments.value.length > 0;

  const personalDataFormState = formStateFromPersonalDataOCR(
    props.clientData.personalData
  );

  const permanentAddress: Option<AddressFormState> = pipe(
    props.clientData.permanentAddress,
    option.map(permanentAddressFromOCR =>
      formStateFromAddressOCR(permanentAddressFromOCR, defaultCountry)
    )
  );

  const renderDocumentDetailsForm = (
    personalDocument: PersonalDocumentOCR,
    documentType: DocumentType
  ) => (
    <>
      <Box vAlignContent="center">
        <Heading size="x-small" weight="medium">
          {formatDocumentType(documentType)}
        </Heading>
      </Box>
      <Space units={10} />
      <PersonalDocumentForm
        documentType={documentType}
        readOnly
        values={formStateFromPersonalDocumentOCR(personalDocument)}
        errors={option.none}
        issue={option.none}
        authorityMandatory={personalDocument.authorityMandatory}
        issuerMandatory={personalDocument.issuerMandatory}
        reworkDocumentDetails={pipe(
          reworkDocument,
          option.chain(r =>
            pipe(
              r.documentDetails,
              option.map(dd => ({
                ...dd,
                authorityCountry: option.none,
              }))
            )
          )
        )}
        reworkClientProfile={pipe(
          reworkDocument,
          option.map(r => ({
            documentType: r.documentType,
            documentDetails: pipe(
              r.documentDetails,
              option.map(dd => ({
                ...dd,
                authorityCountry: option.none,
              }))
            ),
            permanentAddress: r.permanentAddress,
            personalData: pipe(
              r.personalData,
              option.map(pd => ({
                ...pd,
                nationality: option.none,
                titleBefore: option.none,
              }))
            ),
            email: option.none,
            phoneNumber: option.none,
          }))
        )}
      />
    </>
  );

  const isTouchScreen = useIsTouchScreen();

  return (
    <MainContent>
      <ContentRow type="lateral-margins">
        <Stack column shrink grow units={10}>
          <PageHeading
            title={formatMessage(
              "StandardLoan.ExpensesConfirmationAndAdditionalIncome.title"
            )}
            hideOnMobile
          />
          {(areReworkChanges || areReworkComments) &&
            reworkBanner(reworkComments)}
          <Box column grow shrink>
            {!isTouchScreen && (
              <>
                <Body size="small" weight="regular">
                  {formatMessage(
                    "Identification.personalData.confirmDescription",
                    {
                      name: personalDataFormState.name,
                    }
                  )}
                </Body>
                <Space units={isMobileLayout ? 8 : 14} />
              </>
            )}
            <Box vAlignContent="center">
              <Heading size="x-small" weight="medium">
                {formatMessage("Identification.personalData.personalData")}
              </Heading>
            </Box>
            <Space units={6} />
            <PersonalDataForm
              readOnly={true}
              displayCountryOfBirth={true}
              values={personalDataFormState}
              errors={option.none}
              reworkPersonalData={pipe(
                reworkDocument,
                option.chain(document =>
                  pipe(
                    document.personalData,
                    option.map(pd => ({
                      ...pd,
                      nationality: option.none,
                      titleBefore: option.none,
                    }))
                  )
                )
              )}
              documentType={props.clientData.documentType}
              secondCitizenship={
                props.clientData.personalData.secondCitizenship
              }
            />
            <Space units={isMobileLayout ? 8 : 15} />
            <Divider width="100%" />
            <Space units={isMobileLayout ? 8 : 15} />
            <Heading size="x-small" weight="medium">
              {formatMessage("Identification.personalData.documents")}
            </Heading>
            <Space units={2} />
            <Body size="medium" weight="regular">
              {formatMessage(
                "Identification.personalData.documentsDescription"
              )}
            </Body>
            <Space units={10} />
            <Stack units={10} column>
              {renderDocumentDetailsForm(
                props.clientData.documentDetails,
                props.clientData.documentType
              )}
              {pipe(
                props.clientData.additionalDocumentDetails,
                option.fold(constNull, details =>
                  pipe(
                    props.clientData.additionalDocumentType,
                    option.fold(constNull, type =>
                      renderDocumentDetailsForm(details, type)
                    )
                  )
                )
              )}
            </Stack>
            <Space units={14} />
            {pipe(
              permanentAddress,
              option.fold(constNull, permanentAddress => (
                <>
                  <Divider width="100%" />
                  <Space units={14} />
                  <Box vAlignContent="center">
                    <Heading size="x-small" weight="medium">
                      {formatMessage(
                        "Identification.personalData.permanentAddress"
                      )}
                    </Heading>
                  </Box>
                  <Space units={10} />

                  <AddressForm
                    readOnly
                    values={permanentAddress}
                    hint={option.none}
                    errors={option.none}
                    reworkAddress={pipe(
                      reworkDocument,
                      option.chain(r => r.permanentAddress)
                    )}
                    lockCzSk={false}
                  />
                </>
              ))
            )}
          </Box>
          <Box hAlignContent="right">
            <Button
              size="default"
              variant="primary"
              action={props.onContinue}
              label={formatMessage("Identification.personalData.continue")}
            />
          </Box>
        </Stack>
      </ContentRow>
    </MainContent>
  );
}
