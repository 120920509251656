import {
  AlertDialog,
  Body,
  Box,
  Button,
  Card,
  ContentRow,
  FeedbackDialog,
  Loader,
  LoadingButton,
  PageHeading,
  Space,
  Stack,
  UCInfoIcon,
  unsafeLocalizedString,
  useForm,
  validators,
} from "design-system";
import { Option } from "fp-ts/Option";
import { constant, constNull, pipe } from "fp-ts/function";
import { option, taskEither } from "fp-ts";
import { useFormatMessage } from "../../intl";
import { useCommand, useQuery } from "../../useAPI";
import * as api from "../api";
import { AssignedBrokerForBankerInfo } from "../domain";
import { useRef, useState } from "react";
import * as remoteData from "../../RemoteData";
import { BrokerEmailField } from "./BrokerEmailField";
import { useValidators } from "../../Common/useValidators";
import { BackButton } from "../../Common/BackButton/BackButton";
import { NextButton } from "../../Common/NextButton";
import { MainContent } from "../../Common/MainContent";

type Props = {
  loanOffer: api.LoanOffer;
  onContinue: (loanOffer: api.LoanOffer, show3P?: boolean) => unknown;
  onBack: () => unknown;
  onAgeValidationFail: Option<() => unknown>;
};

export default function Select3P(props: Props) {
  const formatMessage = useFormatMessage();

  const [isEditing, setEditing] = useState<boolean>(false);
  const [assignedBrokerData, refreshAssignedData] = useQuery(
    api.getAssignedBrokerForBanker
  );
  const removeAssignedBrokerForBanker = useCommand(
    api.removeAssignedBrokerForBanker
  );
  const [
    isRemovingAssignedBroker,
    setIsRemovingAssignedBroker,
  ] = useState<boolean>(false);
  const { nonEmptyString, validEmail } = useValidators();

  const saveAssignedBrokerForBanker = useCommand(
    api.saveAssignedBrokerForBanker
  );

  const [brokerSelected, setBrokerSelected] = useState(false);
  const [cannotContinueError, setCannotContinueError] = useState(false);
  const [brokerSelectionError, setBrokerSelectionError] = useState(false);

  const unwrap = (value: Option<string>) =>
    pipe(value, option.getOrElse(constant("")));

  const isAssigned = (assignedBroker: AssignedBrokerForBankerInfo) =>
    option.isSome(assignedBroker.firstName) ||
    option.isSome(assignedBroker.lastName) ||
    option.isSome(assignedBroker.phoneNumber) ||
    option.isSome(assignedBroker.email);

  const { fieldProps, handleSubmit, setValues } = useForm(
    {
      initialValues: pipe(
        pipe(
          assignedBrokerData,
          remoteData.fold(
            () => option.none,
            () => option.none,
            assignedBroker =>
              option.isSome(assignedBroker.brokerInfo) &&
              option.isSome(assignedBroker.brokerInfo.value.email)
                ? option.some({
                    email: assignedBroker.brokerInfo.value.email.value,
                  })
                : option.none
          )
        ),
        option.fold(
          constant({
            email: "",
          }),
          initialValues => ({
            email: initialValues.email,
          })
        )
      ),
      fieldValidators: _ => ({
        email: validators.inSequence(nonEmptyString, validEmail),
      }),
    },
    {
      onSubmit: ({ email }) =>
        pipe({ email }, values =>
          pipe(
            saveAssignedBrokerForBanker(values),
            taskEither.map(({ status }) => {
              if (status === "VALID") {
                setEditing(false);
                setBrokerSelected(true);
                refreshAssignedData();
                return taskEither.right(null);
              } else {
                setBrokerSelectionError(true);
                return taskEither.left(null);
              }
            })
          )
        ),
    }
  );

  const activeQueryTerms = useRef({
    email: "",
  });

  const syncFormState = (suggestion: string) =>
    setValues({
      email: suggestion,
    });

  const renderBrokerData = (assignedBroker: AssignedBrokerForBankerInfo) => (
    <Box column shrink hAlignContent="left">
      <Stack units={4} vAlignContent="center">
        <Body size="small" weight="regular">
          {formatMessage("StandardLoan.Select3P.assignedBroker")}
        </Body>
        <Button
          size="small"
          variant="text"
          label={formatMessage("StandardLoan.Select3P.edit")}
          action={() => setEditing(true)}
        />
        <Space units={5} />
        <Button
          size="small"
          variant="text"
          label={formatMessage("StandardLoan.Select3P.remove")}
          action={() => setIsRemovingAssignedBroker(true)}
        />
      </Stack>
      <Body size="small" weight="regular">
        {unsafeLocalizedString(
          unwrap(assignedBroker.firstName) +
            " " +
            unwrap(assignedBroker.lastName)
        )}
      </Body>
      <Body size="small" weight="regular">
        {unsafeLocalizedString(unwrap(assignedBroker.brokerageCompany))}
      </Body>
      <Body size="small" weight="regular">
        {unsafeLocalizedString(unwrap(assignedBroker.phoneNumber))}
      </Body>
      <Body size="small" weight="regular">
        {unsafeLocalizedString(unwrap(assignedBroker.email))}
      </Body>
    </Box>
  );

  const renderCard = (assignedBroker: AssignedBrokerForBankerInfo) => (
    <Box grow shrink>
      <Card column>{renderBrokerData(assignedBroker)}</Card>
    </Box>
  );

  const renderEmailInput = (
    <Box grow vAlignContent={"top"}>
      <BrokerEmailField
        showNativeTooltip
        {...fieldProps("email")}
        label={formatMessage("StandardLoan.Select3P.broker")}
        placeholder={formatMessage("StandardLoan.Select3P.broker")}
        onSelectSuggestion={syncFormState}
        queryString={activeQueryTerms.current.email}
        onChange={value => {
          activeQueryTerms.current = {
            email: value,
          };
          fieldProps("email").onChange(value);
        }}
      />

      <Box style={{ paddingTop: 24 }}>
        <Space units={5} />
        <LoadingButton
          size="default"
          variant="primary"
          labels={{
            normal: formatMessage("StandardLoan.Select3P.selectBroker"),
            loading: formatMessage("StandardLoan.Select3P.selectBroker"),
            success: formatMessage("StandardLoan.Select3P.selectBroker"),
            error: formatMessage("StandardLoan.Select3P.selectBroker"),
          }}
          action={handleSubmit}
        />
      </Box>
    </Box>
  );

  return (
    <MainContent>
      <PageHeading
        title={formatMessage("StandardLoan.Select3P.Heading.Title")}
        description={formatMessage("StandardLoan.Select3P.Heading.Description")}
      />
      {isRemovingAssignedBroker && (
        <AlertDialog
          type="confirmation"
          icon={UCInfoIcon}
          title={formatMessage(
            "StandardLoan.Select3P.removeBrokerDialog.title"
          )}
          message={formatMessage(
            "StandardLoan.Select3P.removeBrokerDialog.body"
          )}
          confirmLabel={formatMessage(
            "StandardLoan.Select3P.removeBrokerDialog.confirm"
          )}
          onConfirm={pipe(
            removeAssignedBrokerForBanker(),
            taskEither.chain(() => {
              activeQueryTerms.current = {
                email: "",
              };
              fieldProps("email").onChange("");
              setBrokerSelected(false);
              setEditing(false);
              setIsRemovingAssignedBroker(false);
              return taskEither.fromIO(refreshAssignedData);
            })
          )}
          cancelLabel={formatMessage(
            "StandardLoan.Select3P.removeBrokerDialog.cancel"
          )}
          onCancel={() => setIsRemovingAssignedBroker(false)}
          onDismiss={() => setIsRemovingAssignedBroker(false)}
        />
      )}
      {brokerSelectionError && (
        <FeedbackDialog
          type="negative"
          title={formatMessage("StandardLoan.Select3P.invalidBroker")}
          cta={{
            label: formatMessage("StandardLoan.Select3P.invalidBroker.OK"),
            action: () => setBrokerSelectionError(false),
          }}
        />
      )}
      {cannotContinueError && (
        <FeedbackDialog
          type="negative"
          title={formatMessage("StandardLoan.Select3P.noBrokerSelected")}
          cta={{
            label: formatMessage("StandardLoan.Select3P.noBrokerSelected.OK"),
            action: () => setCannotContinueError(false),
          }}
        />
      )}
      <ContentRow type={"lateral-margins"}>
        <div style={{ justifyContent: "flex-start", width: "100%" }}>
          {isEditing
            ? renderEmailInput
            : pipe(
                assignedBrokerData,
                remoteData.fold(
                  constant(<Loader />),
                  constNull,
                  assignedBroker =>
                    pipe(
                      assignedBroker.brokerInfo,
                      option.filter(isAssigned),
                      option.map(renderCard),
                      option.getOrElse(() => renderEmailInput)
                    )
                )
              )}
        </div>
      </ContentRow>
      <Space fluid />
      <Box>
        <BackButton
          action={pipe(
            removeAssignedBrokerForBanker(),
            taskEither.chain(() => {
              activeQueryTerms.current = {
                email: "",
              };
              return taskEither.fromIO(props.onBack);
            })
          )}
        />
        <Space fluid />
        {!isEditing &&
          pipe(
            assignedBrokerData,
            remoteData.fold(constNull, constNull, assignedBroker =>
              pipe(
                assignedBroker.brokerInfo,
                option.filter(isAssigned),
                option.map(() => (
                  <NextButton
                    action={
                      brokerSelected
                        ? taskEither.fromIO(() =>
                            props.onContinue(props.loanOffer, false)
                          )
                        : taskEither.fromIO(() => setCannotContinueError(true))
                    }
                  />
                )),
                option.getOrElseW(constNull)
              )
            )
          )}
      </Box>
    </MainContent>
  );
}
