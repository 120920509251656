import { LocaleKey, useFormatMessage } from "../../intl";
import { array, boolean, option, taskEither } from "fp-ts";
import { constNull, constTrue, constVoid, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import {
  Body,
  Box,
  ErrorBanner,
  Loader,
  LoadingButton,
  Space,
  Stack,
  useForm,
  validators,
} from "design-system";
import {
  ExternalLoanItem,
  ExternalLoansList,
  ExternalLoansListRework,
  loanProviders,
  loanProviderSelected,
  LoanType,
} from "../api";
import { ExternalLiabilitiesEdititableItem } from "./ExternalLiabilitiesEditableItem";
import { ExternalLiabilityFormModel } from "../domain";
import { useValidators } from "../../Common/useValidators";
import { Currency, foldTenant, MoneyAmount } from "../../globalDomain";
import { useCommand, useQuery } from "../../useAPI";
import * as remoteData from "../../RemoteData";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { useAppContext } from "../../useAppContext";
import { useState } from "react";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";

export type CreditorConfig = {
  accountPlaceholder: LocaleKey;
  variableSymbolPlaceholder: Option<LocaleKey>;
  accountForDisbursment: Option<NonEmptyString>;
  accountReadonly: boolean;
};

type Props = {
  liabilities: ExternalLoanItem[];
  account: { account: Option<string>; currency: Currency };
  onSubmit: ReaderTaskEither<ExternalLoansList, unknown, unknown>;
  existingClient: boolean;
  oldValues: Option<ExternalLoansListRework>;
};

export function ExternalLiabilitiesEditForm(props: Props) {
  const [loanProviderListQuery] = useQuery(loanProviders);
  const formatMessage = useFormatMessage();

  return pipe(
    loanProviderListQuery,
    remoteData.fold(
      () => <Loader />,
      () => <ErrorBanner>{formatMessage("GenericError")}</ErrorBanner>,
      loanProviders => (
        <ExternalLiabilitiesEditFormInternal
          {...props}
          loanProviderList={loanProviders.loanProviderList}
        />
      )
    )
  );
}

function ExternalLiabilitiesEditFormInternal(
  props: Props & {
    loanProviderList: NonEmptyArray<{
      code: NonEmptyString;
      name: NonEmptyString;
    }>;
  }
) {
  const formatMessage = useFormatMessage();
  const { nonEmptyString, digitsOnly, maxLength } = useValidators();

  const loanProviderSelectedCommand = useCommand(loanProviderSelected);
  const {
    apiParameters: { tenant },
  } = useAppContext();

  const defaultCreditorConfig: CreditorConfig = foldTenant(
    tenant,
    () => ({
      accountPlaceholder:
        "StandardLoan.AccountsForRefinancing.accountForDisbursmentPlaceholder",
      variableSymbolPlaceholder: option.some(
        "StandardLoan.AccountsForRefinancing.vsDefaultPlaceholder"
      ),
      accountForDisbursment: option.none,
      accountReadonly: false,
    }),
    () => ({
      accountPlaceholder:
        "StandardLoan.AccountsForRefinancing.accountForDisbursmentPlaceholder",
      variableSymbolPlaceholder: option.some(
        "StandardLoan.AccountsForRefinancing.vsDefaultPlaceholder"
      ),
      accountForDisbursment: option.none,
      accountReadonly: false,
    })
  );

  const [creditorConfigs, setCreditorConfigs] = useState<CreditorConfig[]>(
    Array(props.liabilities.length).fill({ ...defaultCreditorConfig })
  );

  // This dummy validator is to avoid a known error in useForm fieldArrayValidator
  const workAroundAlwaysRightValidator = <T extends any>() =>
    validators.fromPredicate<T>(constTrue, formatMessage("GenericError"));

  // TODO: replace with the DS validator when split into multiple fields
  const modulo11Weights = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];
  const checkModulo11 = (value: string) => {
    return (
      pipe(
        array.zipWith(
          array.reverse(value.split("")),
          array.reverse(modulo11Weights),
          (el, weight) => parseInt(el) * weight
        ),
        array.reduce(0, (prev, next) => prev + next)
      ) %
        11 ===
      0
    );
  };

  const { fieldArray, handleSubmit } = useForm(
    {
      initialValues: {
        externalLiabilities: props.liabilities.map(
          liability =>
            ({
              ...liability,
              creditorName: pipe(
                liability.creditor,
                option.getOrElse(() => "")
              ),
              creditCardNumber: pipe(
                liability.creditCardNumber,
                option.getOrElse(() => "")
              ),
              accountForDisbursment: pipe(
                liability.accountForDisbursment,
                option.getOrElse(() => "")
              ),
              variableSymbol: pipe(
                liability.variableSymbol,
                option.getOrElse(() => "")
              ),
            } as ExternalLiabilityFormModel)
        ),
      },
      fieldValidators: () => ({}),
      fieldArrayValidators: (_, index) => ({
        externalLiabilities: {
          creditorName: validators.inSequence(
            nonEmptyString,
            validators.fromPredicate(
              text =>
                props.loanProviderList.filter(lp => lp.name === text).length >
                0,
              formatMessage("StandardLoan.AccountsForRefinancing.wrongBankName")
            )
          ),
          accountForDisbursment: foldTenant(
            tenant,
            () => nonEmptyString,
            () =>
              validators.inSequence(
                nonEmptyString,
                validators.fromPredicate(text => {
                  const textWithoutWhiteChars = text.replace(/\s/g, "");
                  if (
                    /^([(]{0,1}\d{1,6}[)]{0,1}-)?\d{2,10}\/\d+$/.test(
                      textWithoutWhiteChars
                    ) === false
                  ) {
                    return false;
                  }
                  const cleanText = textWithoutWhiteChars.replace(/[()]/g, "");
                  const parts = cleanText.split(/[-/]/g);
                  const account = parts.length === 2 ? parts[0] : parts[1];
                  return checkModulo11(account);
                }, formatMessage("Form.fieldError.invalidAccountNumber"))
              )
          ),
          creditCardNumber:
            props.liabilities[index].type === "CREDIT_CARD"
              ? validators.inSequence(
                  nonEmptyString,
                  validators.lengthRange(
                    16,
                    16,
                    formatMessage(
                      "StandardLoan.AccountsForRefinancing.wrongCreditCard"
                    )
                  )
                )
              : workAroundAlwaysRightValidator<string>(),
          variableSymbol: validators.inSequence(
            nonEmptyString,
            digitsOnly,
            maxLength(10)
          ),
          recordId: workAroundAlwaysRightValidator<NonEmptyString>(),
          originalAmount: workAroundAlwaysRightValidator<MoneyAmount>(),
          remainingAmount: workAroundAlwaysRightValidator<MoneyAmount>(),
          type: workAroundAlwaysRightValidator<LoanType>(),
        },
      }),
    },
    {
      onSubmit: values =>
        pipe(
          {
            liabilitiesList: values.externalLiabilities.map(
              (liability, index) => ({
                creditCardNumber: option.some(liability.creditCardNumber),
                creditor: option.some(liability.creditorName),
                creditorCode: pipe(
                  props.loanProviderList,
                  array.findFirstMap(lp =>
                    lp.name === liability.creditorName
                      ? option.some(lp.code)
                      : option.none
                  )
                ),
                variableSymbol: pipe(
                  creditorConfigs[index].variableSymbolPlaceholder,
                  option.chain(() =>
                    pipe(
                      liability.variableSymbol,
                      NonEmptyString.decode,
                      option.fromEither
                    )
                  )
                ),
                accountForDisbursment: option.some(
                  liability.accountForDisbursment
                ),
                recordId: liability.recordId,
                originalAmount: liability.originalAmount,
                remainingAmount: liability.remainingAmount,
                type: liability.type,
              })
            ),
          },
          props.onSubmit
        ),
    }
  );

  const submitButton = (
    <Box hAlignContent="right">
      <LoadingButton
        type="submit"
        variant="primary"
        size="default"
        labels={{
          normal: formatMessage("StandardLoan.AccountsForRefinancing.button"),
          success: formatMessage("StandardLoan.AccountsForRefinancing.button"),
          loading: formatMessage("Loading"),
          error: formatMessage("Error"),
        }}
        action={handleSubmit}
      />
    </Box>
  );

  const externalLiabilitiesFieldArray = fieldArray("externalLiabilities");

  const setCreditorConfigByIndex = (
    creditorConfig: CreditorConfig,
    index: number
  ) => {
    const newConfig = { ...creditorConfigs };
    newConfig[index] = creditorConfig;
    setCreditorConfigs(newConfig);
  };

  const updateCreditorConfig = (
    creditor: NonEmptyString,
    loanType: LoanType,
    index: number
  ) =>
    foldTenant(tenant, constVoid, () =>
      pipe(
        loanProviderSelectedCommand({
          creditor,
          type: loanType,
        }),
        taskEither.fold(
          () =>
            taskEither.fromIO(() =>
              setCreditorConfigByIndex(defaultCreditorConfig, index)
            ),
          provider =>
            taskEither.fromIO(() =>
              pipe(
                provider,
                option.fold(
                  () => setCreditorConfigByIndex(defaultCreditorConfig, index),
                  provider =>
                    setCreditorConfigByIndex(
                      {
                        accountPlaceholder: provider.accountForDisbursmentHint,
                        variableSymbolPlaceholder: provider.variableSymbolHint,
                        accountForDisbursment: provider.accountForDisbursment,
                        accountReadonly: provider.accountReadonly,
                      },
                      index
                    )
                )
              )
            )
        )
      )()
    );

  return (
    <Stack column units={4}>
      {externalLiabilitiesFieldArray.items.map(
        ({ fieldProps, namePrefix }, index) => (
          <ExternalLiabilitiesEdititableItem
            fieldProps={fieldProps}
            namePrefix={namePrefix}
            loanProviders={props.loanProviderList}
            onCreditorNameChanged={creditorCode =>
              updateCreditorConfig(
                creditorCode,
                fieldProps("type").value,
                index
              )
            }
            creditorConfig={creditorConfigs[index]}
            oldValues={pipe(
              props.oldValues,
              option.chain(oldValues => {
                // Sometimes we receive an undefined value that cause
                // problems to the ExternalLiabilitiesEdititableItem component
                const value = oldValues.liabilitiesList[index];
                if (value !== undefined) {
                  return option.some(value);
                } else {
                  return option.none;
                }
              })
            )}
          />
        )
      )}

      {pipe(
        props.existingClient,
        boolean.fold(
          () => (
            <Body size="small" weight="regular">
              {formatMessage(
                "StandardLoan.AccountsForRefinancing.footerNewClient"
              )}
            </Body>
          ),
          () =>
            pipe(
              props.account.account,
              option.fold(constNull, accountNumber => (
                <Body size="small" weight="regular">
                  {formatMessage("StandardLoan.AccountsForRefinancing.footer", {
                    account: accountNumber,
                    currency: props.account.currency,
                  })}
                </Body>
              ))
            )
        )
      )}

      <Space units={10} />
      <Box hAlignContent="right">{submitButton}</Box>
    </Stack>
  );
}
