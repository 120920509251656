import * as t from "io-ts";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { LocalizedString } from "design-system";

export const BankIdentity = t.type({
  bankCode: NonEmptyString,
  bankName: LocalizedString,
});

export type BankIdentity = t.TypeOf<typeof BankIdentity>;

export type BankIdentityFormValue = {
  id: NonEmptyString;
  name: LocalizedString;
};
