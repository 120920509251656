import {
  Box,
  Stack,
  Body,
  buttonLink,
  Card,
  Divider,
  Space,
  ReadOnlyField,
  Upselling,
  CalculatedField,
  useIsMobileLayout,
  unsafeLocalizedString,
  InsuranceIcon,
} from "design-system";
import { option } from "fp-ts";
import { constNull, pipe } from "fp-ts/function";
import { useState } from "react";
import {
  useFormatMessage,
  useFormatMoneyAmountValue,
  useFormatPercentageLike,
} from "../../intl";
import { GenericLoanResponseOutput } from "../api";
import { OfferReviewDetailDialog } from "./OfferReviewDetailDialog";

type Props = {
  offer: GenericLoanResponseOutput;
};

export function OfferReviewCard(props: Props) {
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const formatMessage = useFormatMessage();
  const formatMoneyAmount = useFormatMoneyAmountValue();
  const formatPercentage = useFormatPercentageLike();
  const isMobileLayout = useIsMobileLayout();

  return (
    <Card>
      <Box width="100%" column>
        <Body size="big" weight="medium">
          {formatMessage("StandardLoan.OfferApproved.OfferReview.yourOffer")}
        </Body>
        <Space units={6} />
        <Divider />
        <Space units={6} />
        <Stack units={isMobileLayout ? 8 : 16} column={isMobileLayout}>
          <ReadOnlyField
            size="medium"
            label={formatMessage(
              "StandardLoan.OfferApproved.OfferReview.monthlyInstallment"
            )}
            value={formatMoneyAmount({
              amount: props.offer.installment,
              currency: props.offer.currency,
            })}
          />
          <ReadOnlyField
            size="medium"
            label={formatMessage(
              "StandardLoan.OfferApproved.OfferReview.durationOfInstallments"
            )}
            value={formatMessage(
              "StandardLoan.OfferApproved.OfferReview.durationOfInstallmentsValue",
              {
                duration: props.offer.tenor,
              }
            )}
          />
          <ReadOnlyField
            size="medium"
            label={formatMessage(
              "StandardLoan.OfferApproved.OfferReview.interestRate"
            )}
            value={formatMessage(
              "StandardLoan.OfferApproved.OfferReview.interestRateValue",
              {
                rate: formatPercentage(props.offer.interestRate),
              }
            )}
          />

          {pipe(
            props.offer.monthlyInsuranceAmount,
            option.filter(amount => amount > 0),
            option.fold(constNull, amount => (
              <ReadOnlyField
                size="medium"
                label={formatMessage(
                  "StandardLoan.OfferApproved.OfferReview.cpiMonthlyAmount"
                )}
                value={formatMoneyAmount({
                  amount,
                  currency: props.offer.currency,
                })}
              />
            ))
          )}
        </Stack>
        {pipe(
          props.offer.monthlyInsuranceAmount,
          option.filter(amount => amount > 0),
          option.apSecond(props.offer.insurance),
          option.fold(constNull, insurance => (
            <>
              <Space units={8} />
              <Upselling
                included
                content={formatMessage(
                  "StandardLoan.OfferApproved.ChooseCard.insurance",
                  {
                    insurance: unsafeLocalizedString(insurance),
                  }
                )}
                icon={InsuranceIcon}
              />
            </>
          ))
        )}
        <Space units={4} />
        <CalculatedField
          label={formatMessage(
            "StandardLoan.OfferApproved.OfferReview.totalLoanAmount"
          )}
          value={formatMoneyAmount({
            amount: props.offer.totalAmount,
            currency: props.offer.currency,
          })}
          type="rightContent"
          rightContent={unsafeLocalizedString("")}
          error={option.none}
        />
        <Space units={8} />
        <Body size="small" weight="medium">
          {buttonLink(
            () => setIsDetailDialogOpen(true),
            formatMessage("StandardLoan.OfferApproved.OfferReview.viewDetails")
          )}
        </Body>
      </Box>
      {isDetailDialogOpen && (
        <OfferReviewDetailDialog
          offer={props.offer}
          onDismiss={() => setIsDetailDialogOpen(false)}
        />
      )}
    </Card>
  );
}
