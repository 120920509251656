import {
  Box,
  DropdownField as DropdownFieldDs,
  DropdownOption,
  Form,
  FormRow,
  FormSection,
  RadioGroupField,
  Space,
  TextField,
  useForm,
  validators,
} from "design-system";
import { OptionsType } from "react-select";
import { Option } from "fp-ts/Option";
import { array, eq, option, ord, record, taskEither } from "fp-ts";
import {
  constant,
  constFalse,
  constNull,
  constTrue,
  flow,
  identity,
  pipe,
} from "fp-ts/function";
import { DropdownField } from "../../Common/DropdownField/DropdownField";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { useEffect, useState } from "react";
import { TransactionsInfo2 } from "../api";
import { useFormatMessage } from "../../intl";
import { usePortalStatusContext } from "../../PortalStatusContext";
import {
  AverageIncomingPaymentsId,
  AverageIncomingPaymentsIdCz,
  AverageIncomingPaymentsIdSk,
  Country,
  PaymentDestination2,
  SourceOfFundsId,
} from "../../KYC/domain";
import { useCommand } from "../../useAPI";
import * as kycApi from "../../KYC/api";
import {
  useFormatAverageIncomingPayments,
  useFormatPaymentDestination,
  useFormatSourceOfFunds,
} from "./utils";
import { useValidators } from "../../Common/useValidators";
import { selectedDropdownOption } from "../../Common/selectDropdownOption";
import {
  shouldAskOtherFundsDetails,
  shouldAskPaymentDestinationCountries,
} from "../../KYC/utils";
import { NextButton } from "../../Common/NextButton";
import { foldTenant } from "../../globalDomain";
import { useAppContext } from "../../useAppContext";

type Props = {
  onNext: (transactions: TransactionsInfo2) => unknown;
  initialTransactionsInfo: Option<TransactionsInfo2>;
};

export function Transactions2(props: Props) {
  const {
    apiParameters: { tenant },
  } = useAppContext();
  const formatMessage = useFormatMessage();
  const { portalBlocked } = usePortalStatusContext();

  const countriesToDropdownOptions = (countries: Country[]) =>
    pipe(
      countries,
      array.map(
        c =>
          ({
            value: c,
            label: c.countryName,
          } as DropdownOption<Country>)
      )
    );
  const initialSelectedCountries = pipe(
    props.initialTransactionsInfo,
    option.chain(
      transactions => transactions.paymentDestinationCountriesWithLabels
    ),
    option.map(countriesToDropdownOptions)
  );
  const [selectedCountries, setSelectedCountries] = useState<
    Option<DropdownOption<Country>[]>
  >(initialSelectedCountries);

  const [embargoedCountriesOrOther, setEmbargoedCountriesOrOther] = useState<
    Option<Country[]>
  >(option.none);
  const getEmbargoedCountriesApi = useCommand(kycApi.embargoedCountries);

  const other = "other";

  const isEmbargoedCountrySelected = pipe(
    selectedCountries,
    option.filter(drowpDowns =>
      pipe(
        drowpDowns,
        array.findFirst(country => country.value.countryCode !== other),
        option.isSome
      )
    ),
    option.isSome
  );

  const countryEq = eq.getStructEq<Country>({
    countryCode: eq.eqStrict,
    countryName: eq.fromEquals(constTrue),
    TIN: eq.fromEquals(constTrue),
  });
  useEffect(() => {
    pipe(
      getEmbargoedCountriesApi(),
      taskEither.bimap(constNull, resp => {
        const countries = pipe(
          resp,
          array.sort(
            pipe(
              ord.ordString,
              ord.contramap(({ countryName }: Country) => countryName)
            )
          ),
          array.uniq(countryEq),
          array.insertAt(0, {
            countryName: formatMessage("KYC.Transactions.country.other"),
            countryCode: other,
            TIN: false,
          })
        );
        setEmbargoedCountriesOrOther(countries);
      })
    )();
  }, []);

  const getPaymentDestinationLabel = useFormatPaymentDestination();
  const getIncomingPaymentsLabel = useFormatAverageIncomingPayments();
  const getSourceOfFundsLabel = useFormatSourceOfFunds();

  const { nonBlankString, definedNoExtract, maxLength } = useValidators();
  const workAroundAlwaysRightValidator = <T extends any>() =>
    validators.fromPredicate<T>(constTrue, formatMessage("GenericError"));

  const { values, fieldProps, handleSubmit } = useForm(
    {
      initialValues: pipe(
        props.initialTransactionsInfo,
        option.map(transactionsInfo => ({
          averageMonthlyIncomePayments: pipe(
            transactionsInfo.averageMonthlyIncomePayments,
            option.map(income => income.id)
          ),
          paymentDestination: transactionsInfo.paymentDestination,
          paymentDestinationCountriesWithLabels:
            transactionsInfo.paymentDestinationCountriesWithLabels,
          reasonOfTransactions: pipe(
            transactionsInfo.reasonOfTransactions,
            option.getOrElse(constant(""))
          ),
          sourceOfFunds: pipe(
            transactionsInfo.sourceOfFunds,
            option.map(source => source.id)
          ),
          otherFunds: pipe(
            transactionsInfo.sourceOfFunds,
            option.map(sourceOfFunds => sourceOfFunds.otherSource),
            option.flatten,
            option.getOrElse(constant(""))
          ),
        })),
        option.getOrElse(
          constant({
            averageMonthlyIncomePayments: option.none as Option<AverageIncomingPaymentsId>,
            paymentDestination: option.none as Option<PaymentDestination2>,
            paymentDestinationCountriesWithLabels: option.none as Option<
              Country[]
            >,
            reasonOfTransactions: "",
            sourceOfFunds: option.none as Option<SourceOfFundsId>,
            otherFunds: "",
          })
        )
      ),
      fieldValidators: values => ({
        averageMonthlyIncomePayments: definedNoExtract(),
        paymentDestination: definedNoExtract(),
        paymentDestinationCountriesWithLabels:
          shouldAskPaymentDestinationCountries(values.paymentDestination) &&
          option.isNone(selectedCountries)
            ? definedNoExtract()
            : workAroundAlwaysRightValidator(),
        reasonOfTransactions: isEmbargoedCountrySelected
          ? validators.inSequence(nonBlankString, maxLength(64))
          : undefined,
        sourceOfFunds: definedNoExtract(),
        otherFunds: pipe(
          values.sourceOfFunds,
          option.exists(v => v === "Other")
        )
          ? validators.inSequence(nonBlankString, maxLength(64))
          : undefined,
        employerName: workAroundAlwaysRightValidator(),
        companyICO: workAroundAlwaysRightValidator(),
      }),
    },
    {
      onSubmit: transactionsInfo =>
        taskEither.fromIO(() =>
          props.onNext({
            paymentDestination: transactionsInfo.paymentDestination,
            paymentDestinationLabel: pipe(
              transactionsInfo.paymentDestination,
              option.map(getPaymentDestinationLabel)
            ),
            paymentDestinationCountriesWithLabels: pipe(
              selectedCountries,
              option.map(
                flow(array.map(dropdownCountry => dropdownCountry.value))
              )
            ),
            reasonOfTransactions: isEmbargoedCountrySelected
              ? option.some(transactionsInfo.reasonOfTransactions)
              : option.none,
            averageMonthlyIncomePayments: pipe(
              transactionsInfo.averageMonthlyIncomePayments,
              option.map(incomeId => ({
                id: incomeId,
                value: getIncomingPaymentsLabel(incomeId),
              }))
            ),
            sourceOfFunds: pipe(
              transactionsInfo.sourceOfFunds,
              option.map(fundsId => ({
                id: fundsId,
                value: getSourceOfFundsLabel(fundsId),
                otherSource:
                  fundsId === "Other"
                    ? option.some(transactionsInfo.otherFunds)
                    : option.none,
              }))
            ),
            sourceOfIncome: option.none,
            companyICO: option.none,
            employerName: option.none,
            paymentDestinationCountries: pipe(
              selectedCountries,
              option.map(
                flow(
                  array.map(
                    dropdownCountry => dropdownCountry.value.countryCode
                  ),
                  countries => countries.join(",")
                )
              )
            ),
          })
        ),
    }
  );

  const paymentDestinationOptions = pipe(
    PaymentDestination2.keys,
    record.keys
  ) as NonEmptyArray<PaymentDestination2>;

  const averageIncomeOptions = pipe(
    foldTenant<AverageIncomingPaymentsId[]>(
      tenant,
      () =>
        Object.keys(
          AverageIncomingPaymentsIdSk.keys
        ) as Array<AverageIncomingPaymentsId>,
      () =>
        Object.keys(
          AverageIncomingPaymentsIdCz.keys
        ) as Array<AverageIncomingPaymentsId>
    ),
    array.map(income => ({
      value: income,
      label: getIncomingPaymentsLabel(income),
    }))
  );

  const sourceOfFundsOptions = pipe(
    Object.keys(SourceOfFundsId.keys) as Array<SourceOfFundsId>,
    array.map(source => ({
      value: source,
      label: getSourceOfFundsLabel(source),
    }))
  );

  const handleOnChange = (
    val: Option<OptionsType<DropdownOption<Country>>>
  ) => {
    if (option.isSome(val)) {
      const values: DropdownOption<Country>[] = val.value.map(item => {
        return item;
      });
      fieldProps("paymentDestinationCountriesWithLabels").onChange(
        pipe(
          option.some(values),
          option.map(flow(array.map(dropdownCountry => dropdownCountry.value)))
        )
      );
      setSelectedCountries(option.some(values));
    } else {
      setSelectedCountries(option.none);
      fieldProps("paymentDestinationCountriesWithLabels").onChange(option.none);
    }
  };
  return (
    <>
      <Form>
        <FormSection
          heading={{
            title: formatMessage("KYC.Transactions.description"),
          }}
        >
          <Space units={1} />
          <FormRow type="full">
            <DropdownField
              {...fieldProps("averageMonthlyIncomePayments")}
              label={formatMessage(
                "KYC.Transactions.averageMonthlyIncomePaymentsLabel"
              )}
              placeholder={formatMessage(
                "KYC.Transactions.averageMonthlyIncomePaymentsPlaceholder"
              )}
              options={averageIncomeOptions}
              value={selectedDropdownOption(
                fieldProps("averageMonthlyIncomePayments").value,
                averageIncomeOptions
              )}
              onChange={val =>
                fieldProps("averageMonthlyIncomePayments").onChange(
                  pipe(
                    val,
                    option.map(val => val.value)
                  )
                )
              }
              clearable={false}
              searchable={false}
            />
          </FormRow>
          <FormRow type="full">
            <RadioGroupField
              {...fieldProps("paymentDestination")}
              variant="vertical"
              onChange={newValue => {
                if (
                  option.isNone(newValue) ||
                  newValue.value === "DomesticAndEU"
                ) {
                  setSelectedCountries(option.none);
                }
                fieldProps("paymentDestination").onChange(newValue);
              }}
              options={paymentDestinationOptions}
              label={formatMessage("KYC.Transactions.paymentDestinationLabel")}
              optionKey={identity}
              isOptionDisabled={constFalse}
              renderOption={getPaymentDestinationLabel}
              renderOptionChildren={constant(option.none)}
              disabled={portalBlocked}
            />
          </FormRow>
          {shouldAskPaymentDestinationCountries(values.paymentDestination) && (
            <FormRow type="full">
              <DropdownFieldDs
                {...fieldProps("paymentDestinationCountriesWithLabels")}
                label={formatMessage(
                  "KYC.Transactions.paymentDestinationCountriesEmbargoedLabel"
                )}
                options={pipe(
                  embargoedCountriesOrOther,
                  option.map(countriesToDropdownOptions),
                  option.getOrElse(() => [] as DropdownOption<Country>[])
                )}
                onChange={handleOnChange}
                value={pipe(
                  fieldProps("paymentDestinationCountriesWithLabels").value,
                  option.map(countriesToDropdownOptions)
                )}
                cancelLabel={formatMessage("Cancel")}
                noResultsLabel={formatMessage("NoResultsFound")}
                clearSelectionLabel={formatMessage("Dropdown.clearSelection")}
                filterInputPlaceholder={formatMessage(
                  "Dropdown.filterInputPlaceholder"
                )}
                isMulti
                ctaLabel={formatMessage("Confirm.buttonLabel")}
                clearable
                searchable
                onBlur={
                  fieldProps("paymentDestinationCountriesWithLabels").onBlur
                }
                placeholder={formatMessage(
                  "KYC.Transactions.paymentDestinationCountriesEmbargoedPlaceholder"
                )}
              />
            </FormRow>
          )}
          {isEmbargoedCountrySelected && (
            <FormRow type="full">
              <TextField
                {...fieldProps("reasonOfTransactions")}
                label={formatMessage(
                  "KYC.Transactions.reasonOfTransactionsLabel"
                )}
                placeholder={formatMessage(
                  "KYC.Transactions.reasonOfTransactionsPlaceholder"
                )}
                disabled={portalBlocked}
              />
            </FormRow>
          )}
          <FormRow type="full">
            <DropdownField
              {...fieldProps("sourceOfFunds")}
              label={formatMessage("KYC.Transactions.sourceOfFundsLabel")}
              placeholder={formatMessage(
                "KYC.Transactions.sourceOfFundsPlaceholder"
              )}
              options={sourceOfFundsOptions}
              value={selectedDropdownOption(
                fieldProps("sourceOfFunds").value,
                sourceOfFundsOptions
              )}
              onChange={val =>
                fieldProps("sourceOfFunds").onChange(
                  pipe(
                    val,
                    option.map(val => val.value)
                  )
                )
              }
              clearable={false}
              searchable={false}
            />
          </FormRow>
          {shouldAskOtherFundsDetails(values.sourceOfFunds) && (
            <FormRow type="full">
              <TextField
                {...fieldProps("otherFunds")}
                label={formatMessage(
                  "KYC.Transactions.sourceOfFunds.otherLabel"
                )}
                placeholder={formatMessage(
                  "KYC.Transactions.sourceOfFunds.otherPlaceholder"
                )}
                disabled={portalBlocked}
              />
            </FormRow>
          )}
        </FormSection>
      </Form>
      <Space units={1} />
      <Box hAlignContent="right">
        <NextButton action={handleSubmit} />
      </Box>
    </>
  );
}
