import {
  Box,
  Stack,
  Card,
  Body,
  ReadOnlyField,
  Button,
  Upselling,
  UmbrellaIcon,
  FormSection,
  FormRow,
  FeedbackBlock,
  useIsMobileLayout,
  ContentRow,
} from "design-system";
import {
  useFormatMessage,
  useFormatMoneyAmountValue,
  useFormatPercentageLike,
} from "../../intl";
import * as offerListApi from "./api";
import * as standardLoanApi from "../api";
import { useQuery, useCommand } from "../../useAPI";
import { constNull, pipe } from "fp-ts/function";
import { array, taskEither, option, boolean } from "fp-ts";
import * as remoteData from "../../RemoteData";
import { Reader } from "fp-ts/Reader";
import { IO } from "fp-ts/IO";
import { BackButton } from "../../Common/BackButton/BackButton";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";

type Props = {
  onSelectOffer: Reader<standardLoanApi.GenericLoanResponseOutput, void>;
  onBack: IO<void>;
  offerState: Exclude<offerListApi.CheckOfferStatus, "PENDING">;
};

export function Counteroffer(props: Props) {
  const formatMessage = useFormatMessage();
  const formatMoneyAmount = useFormatMoneyAmountValue();
  const formatPercentage = useFormatPercentageLike();
  const saveSelectedOffer = useCommand(offerListApi.saveSelectedOffer);
  const [counterOffer] = useQuery(offerListApi.getCounterOffers);
  const isMobileLayout = useIsMobileLayout();

  const renderOffers = (
    offers: NonEmptyArray<standardLoanApi.GenericLoanOffer>
  ) =>
    pipe(
      offers,
      array.map(offer => (
        <Card>
          <Stack width="100%" units={8} column>
            <Body size="big" weight="medium">
              {formatMessage("StandardLoan.CounterOffer.counterOfferTitle")}
            </Body>
            {pipe(
              offer.newMoneyAmount,
              option.fold(
                () => (
                  <ReadOnlyField
                    size="big"
                    label={formatMessage(
                      "StandardLoan.CounterOffer.loanAmount"
                    )}
                    value={formatMoneyAmount({
                      amount: offer.borrowedAmount,
                      currency: offer.currencyCode,
                    })}
                  />
                ),
                amount => (
                  <FormSection>
                    <FormRow type="full">
                      <ReadOnlyField
                        size="big"
                        label={formatMessage(
                          "StandardLoan.OfferApproved.ChooseCard.refinancingAmount"
                        )}
                        value={formatMoneyAmount({
                          amount,
                          currency: offer.currencyCode,
                        })}
                      />
                    </FormRow>
                    <FormRow type="full">
                      <ReadOnlyField
                        size="medium"
                        label={formatMessage(
                          "StandardLoan.OfferApproved.ChooseCard.loanAmount"
                        )}
                        value={formatMoneyAmount({
                          amount: offer.borrowedAmount,
                          currency: offer.currencyCode,
                        })}
                      />
                    </FormRow>
                  </FormSection>
                )
              )
            )}

            <FormSection>
              <FormRow type="1-1-1-1">
                <ReadOnlyField
                  size="medium"
                  label={formatMessage(
                    "StandardLoan.OfferApproved.OfferReview.monthlyInstallment"
                  )}
                  value={formatMoneyAmount({
                    amount: offer.installmentAmount,
                    currency: offer.currencyCode,
                  })}
                />
                <ReadOnlyField
                  size="medium"
                  label={formatMessage(
                    "StandardLoan.OfferApproved.OfferReview.durationOfInstallments"
                  )}
                  value={formatMessage(
                    "StandardLoan.OfferApproved.OfferReview.durationOfInstallmentsValue",
                    {
                      duration: offer.tenor,
                    }
                  )}
                />
                <ReadOnlyField
                  size="medium"
                  label={formatMessage(
                    "StandardLoan.OfferApproved.OfferReview.interestRate"
                  )}
                  value={formatMessage(
                    "StandardLoan.OfferApproved.OfferReview.interestRateValue",
                    {
                      rate: formatPercentage(offer.interestRateDecimal),
                    }
                  )}
                />
                {pipe(
                  offer.cpimonthlyAmount,
                  option.fold(constNull, cpimonthlyAmount => (
                    <ReadOnlyField
                      size="medium"
                      label={formatMessage(
                        "StandardLoan.OfferApproved.OfferReview.cpiMonthlyAmount"
                      )}
                      value={formatMoneyAmount({
                        amount: cpimonthlyAmount,
                        currency: offer.currencyCode,
                      })}
                    />
                  ))
                )}
              </FormRow>
            </FormSection>
            {pipe(
              offer.cpiSelectedName,
              option.fold(constNull, cpiName =>
                pipe(
                  offer.cpimonthlyAmount,
                  option.fold(constNull, () => (
                    <Upselling
                      included
                      content={formatMessage(
                        "StandardLoan.OfferApproved.ChooseCard.insurance",
                        {
                          insurance: formatMessage(cpiName),
                        }
                      )}
                      icon={UmbrellaIcon}
                    />
                  ))
                )
              )
            )}
            <Button
              label={formatMessage(
                "StandardLoan.OfferApproved.ChooseCard.chooseOption"
              )}
              variant="secondary"
              size="default"
              action={pipe(
                saveSelectedOffer(offer),
                taskEither.chain(selectedOffer =>
                  taskEither.fromIO(() => props.onSelectOffer(selectedOffer))
                )
              )}
            />
          </Stack>
        </Card>
      ))
    );

  const renderBackButton = (
    <ContentRow type="lateral-margins">
      <Box grow column={isMobileLayout}>
        <BackButton action={props.onBack} />
      </Box>
    </ContentRow>
  );
  return pipe(
    props.offerState === "RECEIVED",
    boolean.fold(
      () => (
        <Stack column units={10}>
          <FeedbackBlock
            size="large"
            type="negative"
            heading={formatMessage("StandardLoan.CounterOffer.title")}
            subheading={option.some(
              formatMessage("StandardLoan.CounterOffer.timeout")
            )}
            actions={[]}
          />
          {renderBackButton}
        </Stack>
      ),
      () =>
        pipe(
          counterOffer,
          remoteData.fold(
            constNull,
            () => (
              <Stack column units={10}>
                <FeedbackBlock
                  size="large"
                  type="negative"
                  heading={formatMessage("StandardLoan.Rejection.Offers.title")}
                  subheading={option.some(
                    formatMessage("StandardLoan.Rejection.Offers.description")
                  )}
                  actions={[]}
                />
                <></>
              </Stack>
            ),
            ({ offers }) => (
              <Stack column units={10}>
                <>
                  <FeedbackBlock
                    size="large"
                    type="info"
                    heading={formatMessage("StandardLoan.CounterOffer.title")}
                    subheading={option.some(
                      formatMessage("StandardLoan.CounterOffer.description")
                    )}
                    actions={[]}
                  />
                  <Box hAlignContent="center">{renderOffers(offers)}</Box>
                </>
                {renderBackButton}
              </Stack>
            )
          )
        )
    )
  );
}
