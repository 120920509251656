import {
  FieldProps,
  FormRow,
  PositiveInteger,
  PinInput,
  Box,
  Space,
  Body,
  useIsMobileLayout,
} from "design-system";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { useMemo } from "react";
import * as option from "fp-ts/Option";
import { constVoid } from "fp-ts/function";
import { readerTaskEither } from "fp-ts";
import { useFormatMessage } from "../../intl";

type Props = FieldProps<string>;

const hiddenNumbersValue = "XXXXXXXX";

export function MaskedCreditCardField(props: Props) {
  const formatMessage = useFormatMessage();

  const splittedMaskedCardNumber = useMemo(() => {
    const [initialNumbers, finalNumbers] = props.value
      .toUpperCase()
      .split(hiddenNumbersValue);

    return {
      initialNumbers,
      finalNumbers: finalNumbers || "",
    };
  }, [props.value]);

  function onChangeInitial(value: string) {
    props.onChange(
      (value +
        hiddenNumbersValue +
        splittedMaskedCardNumber.finalNumbers) as NonEmptyString
    );
  }

  function onChangeFinal(value: string) {
    props.onChange(
      (splittedMaskedCardNumber.initialNumbers +
        hiddenNumbersValue +
        value) as NonEmptyString
    );
  }

  const isMobileLayout = useIsMobileLayout();

  return (
    <FormRow type="full">
      <Box column>
        <Box>
          <Body weight="regular" size="x-small">
            {props.label}
          </Body>
        </Box>
        <Space units={2} />
        <Box grow shrink column={isMobileLayout}>
          <Box column>
            <PinInput
              length={4 as PositiveInteger}
              value={splittedMaskedCardNumber.initialNumbers}
              issues={props.issues}
              onSubmit={readerTaskEither.fromIO(constVoid)}
              onChange={onChangeInitial}
              submit="auto"
            />
          </Box>
          <Space units={5} />
          <Box column>
            <Space units={3} />
            <Body size="big" weight="medium">
              {formatMessage(
                "StandardLoan.AccountsForRefinancing.hiddenCreditCardNumbers"
              )}
            </Body>
          </Box>
          <Space units={5} />
          <Box column>
            <PinInput
              autoFocus={
                splittedMaskedCardNumber.initialNumbers.trim().length === 4
              }
              disabled={
                splittedMaskedCardNumber.initialNumbers.trim().length !== 4
              }
              length={4 as PositiveInteger}
              value={splittedMaskedCardNumber.finalNumbers}
              issues={option.none}
              onSubmit={readerTaskEither.fromIO(constVoid)}
              onChange={onChangeFinal}
              submit="auto"
              focus={
                splittedMaskedCardNumber.initialNumbers.trim().length === 4
                  ? 0
                  : undefined
              }
            />
          </Box>
        </Box>
      </Box>
    </FormRow>
  );
}
