import { SummaryAndDownload } from "../../UKontoSecondPart/SummaryAndDownload/SummaryAndDownload";
import {
  Box,
  Body,
  Stack,
  Space,
  ErrorIcon,
  ContentRow,
  SuccessIcon,
  FeedbackBlock,
  FeedbackDialog,
} from "design-system";
import { FinalFeedback } from "./FinalFeedback";
import { option, boolean } from "fp-ts";
import { useFormatMessage } from "../../intl";
import { usePollingEffect } from "../../useAPI";
import * as api from "../api";
import { useEffect, useState } from "react";
import { constVoid, pipe } from "fp-ts/function";
import cx from "classnames";
import * as classes from "./Summary.treat";
import { IO } from "fp-ts/IO";
import { useAppContext } from "../../useAppContext";
import { StandardLoanFlowType } from "../domain";
import { MainContent } from "../../Common/MainContent";

type Props = {
  clientExists: boolean;
  goToMicrotransaction: IO<unknown>;
  flowType: StandardLoanFlowType;
};

function isSuccess(result: api.SLResultsSuccess): boolean {
  return result.stepsResults.every(r => r.type !== "ERROR");
}

function OnboardingResultSuccessDialog(props: {
  result: api.SLResultsSuccess;
  onDismiss: () => unknown;
}) {
  const formatMessage = useFormatMessage();
  return pipe(
    isSuccess(props.result),
    boolean.fold(
      () => (
        <FeedbackDialog
          type="negative"
          title={formatMessage("StandardLoan.Summary.partialSuccessModalTitle")}
          cta={{
            label: formatMessage("Ok"),
            action: props.onDismiss,
          }}
        >
          <Stack column units={10}>
            <Stack hAlignContent="center" column units={3}>
              {props.result.stepsResults.map(result => (
                <Box vAlignContent="center">
                  {result.type === "SUCCESS" ? (
                    <SuccessIcon
                      size="x-small"
                      className={classes.successColor}
                    />
                  ) : (
                    <ErrorIcon size="x-small" className={classes.errorColor} />
                  )}
                  <Space units={3} />
                  <Body
                    size="medium"
                    weight="regular"
                    className={cx({
                      [classes.errorColor]: result.type === "ERROR",
                    })}
                  >
                    {result.message}
                  </Body>
                </Box>
              ))}
            </Stack>
            <Body size="medium" weight="regular" align="center">
              {formatMessage(
                "StandardLoan.Summary.partialSuccessEmailReminder"
              )}
            </Body>
          </Stack>
        </FeedbackDialog>
      ),
      () => (
        <FeedbackDialog
          type="success"
          title={formatMessage("StandardLoan.Summary.successModalTitle")}
          subtitle={formatMessage("StandardLoan.Summary.successModalBody")}
          cta={{
            label: formatMessage("Ok"),
            action: props.onDismiss,
          }}
        />
      )
    )
  );
}
export const Summary = (props: Props) => {
  const formatMessage = useFormatMessage();

  const [slResult, setSLResult] = useState<api.SLResultsOutput>({
    status: "PENDING",
  });
  const [successDialogOpen, setSuccessDialogOpen] = useState(true);

  usePollingEffect(api.slResults, {
    intervalMS: 3000,
    shouldPollingContinue: result =>
      result.status === "PENDING" || result.status === "CONTINUE",
    onSuccess: setSLResult,
    onError: constVoid,
  });

  useEffect(() => {
    switch (slResult.status) {
      case "MICROPAYMENT":
        props.goToMicrotransaction();
        break;
      case "CONTINUE":
      case "DONE":
      case "ERROR":
      case "NOTIFY_CLIENT_OF_DELAY":
      case "NOTIFY_CLIENT_OF_DELAY_ERRORS":
      case "PENDING":
      case "REDIRECT_TO_THE_BANK":
      case "REJECTED":
        break;
    }
  }, [slResult.status]);

  const decision = slResult.status === "DONE" ? "Approved" : "Pending";

  const feedback = (
    <ContentRow type="lateral-margins">
      <FinalFeedback
        decision={decision}
        onGotoClientsPage={option.none}
        isSummary
        isRefinancing={false}
        isNonWorkingDay={false}
        clientExists={props.clientExists}
        flowType={props.flowType}
      />
    </ContentRow>
  );

  const summaryHeading = {
    title: formatMessage("StandardLoan.Summary.title"),
    description: formatMessage("StandardLoan.Summary.description"),
  };

  const {
    config: { unicreditBranchesMapURL },
  } = useAppContext();

  const navigateToFindBranches = () => {
    window.location.href = unicreditBranchesMapURL;
  };

  const render = () => {
    switch (slResult.status) {
      case "PENDING":
        return feedback;
      case "CONTINUE":
        return (
          <>
            {feedback}
            <SummaryAndDownload
              isCf
              isChild={false}
              finalChecksComplete={false}
              checkPendingActions
              heading={summaryHeading}
            />
          </>
        );
      case "DONE":
        return (
          <>
            {feedback}
            {!props.clientExists && (
              <SummaryAndDownload
                isCf
                isChild={false}
                finalChecksComplete
                checkPendingActions
                heading={summaryHeading}
              />
            )}
            {successDialogOpen && (
              <OnboardingResultSuccessDialog
                result={slResult}
                onDismiss={() => setSuccessDialogOpen(false)}
              />
            )}
          </>
        );
      case "ERROR":
        return (
          <FeedbackBlock
            type="negative"
            size="medium"
            heading={formatMessage("StandardLoan.Summary.failureTitle")}
            subheading={option.some(
              formatMessage("StandardLoan.Summary.failureMessage")
            )}
            actions={[]}
          />
        );
      case "REJECTED":
        return (
          <FeedbackBlock
            type="negative"
            size="medium"
            heading={formatMessage(
              "StandardLoan.Summary.ApplicationRejected.Title"
            )}
            subheading={option.none}
            actions={[]}
          />
        );
      case "MICROPAYMENT":
        return null;
      case "REDIRECT_TO_THE_BANK":
        return (
          <FeedbackBlock
            type="negative"
            size="medium"
            heading={formatMessage(
              "StandardLoan.Summary.RedirectToTheBank.Title"
            )}
            subheading={option.some(
              formatMessage("StandardLoan.Summary.RedirectToTheBank")
            )}
            actions={[
              {
                variant: "primary",
                label: formatMessage(
                  "StandardLoan.Summary.RedirectToTheBank.Button"
                ),
                action: navigateToFindBranches,
              },
            ]}
          />
        );
      case "NOTIFY_CLIENT_OF_DELAY":
        return (
          <FeedbackBlock
            type="negative"
            size="medium"
            heading={formatMessage(
              "StandardLoan.Summary.NotifyClientOfDelay.Title"
            )}
            subheading={option.some(
              formatMessage("StandardLoan.Summary.NotifyClientOfDelay")
            )}
            actions={[]}
          />
        );
      case "NOTIFY_CLIENT_OF_DELAY_ERRORS":
        return (
          <FeedbackBlock
            type="negative"
            size="medium"
            heading={formatMessage(
              "StandardLoan.Summary.NotifyClientOfDelay.Title"
            )}
            subheading={option.some(
              formatMessage("StandardLoan.Summary.NotifyClientOfDelay")
            )}
            banner={{
              type: "warning",
              content: formatMessage(
                "StandardLoan.Summary.NotifyClientOfDelayWithErrors.Banner"
              ),
              actions: option.none,
              onDismiss: option.none,
              title: option.none,
            }}
            actions={[]}
          />
        );
    }
  };

  return <MainContent>{render()}</MainContent>;
};
