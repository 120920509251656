import {
  Body,
  Box,
  LoadingButton,
  LocalizedString,
  Space,
  Stack,
} from "design-system";
import * as classes from "./ChooseBrokerButton.treat";
import { taskEither } from "fp-ts";
import { useFormatMessage } from "../../intl";

type Props = {
  heading: LocalizedString;
  buttonLabel: LocalizedString;
  action: () => unknown;
};

export function ChooseBrokerButton(props: Props) {
  const formatMessage = useFormatMessage();
  return (
    <Stack
      fluid
      grow
      shrink
      units={8}
      vAlignContent="top"
      className={classes.button}
      onClick={props.action}
    >
      <Box column grow shrink>
        <Space units={3.5} />
        <Body size="small" weight="regular">
          {props.heading}
        </Body>
        <Space units={4} />
      </Box>
      <Box>
        <LoadingButton
          variant="text"
          labels={{
            normal: props.buttonLabel,
            loading: formatMessage("Loading"),
            success: props.buttonLabel,
            error: props.buttonLabel,
          }}
          size="default"
          action={taskEither.fromIO(props.action)}
        />
      </Box>
    </Stack>
  );
}
