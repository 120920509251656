import { pipe } from "fp-ts/function";
import { IO } from "fp-ts/IO";
import { useCommand, usePollingEffect } from "../../useAPI";
import * as api from "../api";
import { getBonitaStatus } from "../api";
import { Box, Loader } from "design-system";
import { useEffect, useState } from "react";
import { option, taskEither } from "fp-ts";
import { MainContent } from "../../Common/MainContent";

type Props = {
  onCounterOffer: IO<unknown>;
  onApproved: IO<unknown>;
  onRejected: IO<unknown>;
  onError: IO<unknown>;
};

export function SendAdditionalIncome(props: Props) {
  const sendAdditionalIncome = useCommand(api.sendAdditionalIncome);
  const [errorNo, setErrorNo] = useState(0);
  const [canPoll, setCanPoll] = useState(false);

  useEffect(() => {
    pipe(
      sendAdditionalIncome(),
      taskEither.bimap(props.onError, ({ lfDecision }) => {
        if (lfDecision === "Pending") {
          setCanPoll(true);
        }
      })
    )();
  }, []);

  useEffect(() => {
    if (canPoll) {
      restartPoll();
    }
  }, [canPoll]);

  const restartPoll = usePollingEffect(getBonitaStatus, {
    intervalMS: 5000,
    disabled: !canPoll,
    shouldPollingContinue: val => val.bonitaStatus === "PENDING",
    onError: () => setErrorNo(curr => curr + 1),
    onSuccess: response => {
      setErrorNo(0);

      if (
        response.bonitaStatus === "DONE" &&
        option.isSome(response.proceedWithAdditionalIncomeResponse)
      ) {
        setCanPoll(false);
        switch (response.proceedWithAdditionalIncomeResponse.value.lfDecision) {
          case "Approved":
            props.onApproved();
            break;
          case "Counteroffer":
            props.onCounterOffer();
            break;
          case "Rejected":
            props.onRejected();
            break;
          default:
            props.onError();
        }
      }
    },
  });

  useEffect(() => {
    if (errorNo >= 2) {
      props.onError();
    }
  }, [errorNo]);

  return (
    <MainContent>
      <Box grow hAlignContent="center" vAlignContent="center">
        <Loader />
      </Box>
    </MainContent>
  );
}
