import {
  Banner,
  Body,
  Box,
  ContentRow,
  DoubleCard,
  ErrorBanner,
  FeedbackBlock,
  FileContent,
  FormRow,
  Heading,
  InfoIcon,
  InlineMessage,
  Loader,
  QRCode,
  ReadOnlyField,
  Space,
  Stack,
  Tooltip,
  UnorderedList,
} from "design-system";
import { option } from "fp-ts";
import { sequenceS } from "fp-ts/Apply";
import { flow, pipe } from "fp-ts/function";
import { useState } from "react";
import { useFormatMessage } from "../../intl";
import { useQuery } from "../../useAPI";
import { WithPromotionBenefit } from "../../PhoneAndEmailVerification/WithPromotionBenefit";
import * as api from "./api";
import * as remoteData from "../../RemoteData";
import { palette } from "design-system/lib/styleConstants";
import { ReadOnlyMoneyAmountField } from "../../Common/ReadOnlyMoneyAmountField/ReadOnlyMoneyAmountField";
import { useTenantCurrency } from "../../Common/useTenantCurrency";

export const MicroTransactionOverview = () => {
  const formatMessage = useFormatMessage();
  const [showInfoBanner, setShowInfoBanner] = useState(true);
  const [microTransactionDetails] = useQuery(api.microTransactionDetails);
  const [QRCodeFile] = useQuery(api.getQRCode, {
    size: 150,
  });

  return pipe(
    { microTransactionDetails, QRCodeFile },
    sequenceS(remoteData.remoteData),
    remoteData.fold(
      () => (
        <ContentRow type="full">
          <Loader />
        </ContentRow>
      ),
      () => <ErrorBanner children={formatMessage("GenericError")} />,
      ({ microTransactionDetails, QRCodeFile }) => (
        <Box column grow shrink>
          <FeedbackBlock
            size="medium"
            type="positive"
            heading={formatMessage(
              "StandardLoan.MainContent.microTransactionOverviewTitle"
            )}
            subheading={option.some(
              formatMessage(
                "StandardLoan.MainContent.microTransactionOverviewSubtitle"
              )
            )}
            actions={[]}
          />
          <ContentRow type="lateral-margins">
            <Box column grow shrink>
              <Stack units={3} column>
                <Heading size="small" weight="medium">
                  {formatMessage(
                    "StandardLoan.MainContent.microTransactionOverviewCardTitle"
                  )}
                </Heading>
                <Body size="small" weight="regular">
                  {formatMessage(
                    "StandardLoan.MainContent.microTransactionOverviewCardSubtitle"
                  )}
                </Body>
              </Stack>
              <Space units={10} />
              <MicroPaymentCard
                microTransactionDetails={microTransactionDetails}
                qrCode={QRCodeFile}
              />
              {showInfoBanner && (
                <>
                  <Space units={6} />
                  <Banner
                    type="informative"
                    title={option.none}
                    actions={option.none}
                    onDismiss={option.some(() => setShowInfoBanner(false))}
                    content={formatMessage(
                      "StandardLoan.MainContent.microTransaction.infoBanner"
                    )}
                  />
                </>
              )}
              <WithPromotionBenefit>
                {flow(
                  option.map(promotionBenefit => (
                    <>
                      <Space units={6} />
                      <Banner
                        type="informative"
                        title={option.some(
                          formatMessage(
                            "StandardLoan.MainContent.microTransaction.promotionTitle"
                          )
                        )}
                        content={promotionBenefit}
                        actions={option.none}
                        onDismiss={option.none}
                      />
                    </>
                  )),
                  option.toNullable
                )}
              </WithPromotionBenefit>
              <Space units={10} />
              <Stack units={4} column grow shrink hAlignContent="center">
                <Heading size="medium" weight="medium">
                  {formatMessage(
                    "StandardLoan.MainContent.microTransaction.whatsNext.title"
                  )}
                </Heading>
                <UnorderedList size="large" listStyle="bullet">
                  {[
                    formatMessage(
                      "StandardLoan.MainContent.microTransaction.whatsNext.suggestion1"
                    ),
                    formatMessage(
                      "StandardLoan.MainContent.microTransaction.whatsNext.suggestion2"
                    ),
                    formatMessage(
                      "StandardLoan.MainContent.microTransaction.whatsNext.suggestion3"
                    ),
                    formatMessage(
                      "StandardLoan.MainContent.microTransaction.whatsNext.suggestion4"
                    ),
                  ]}
                </UnorderedList>
              </Stack>
            </Box>
          </ContentRow>
        </Box>
      )
    )
  );
};

function MicroPaymentCard(props: {
  microTransactionDetails: api.MicroTransactionDetailsOutput;
  qrCode: FileContent;
}) {
  const formatMessage = useFormatMessage();
  const currency = useTenantCurrency();
  const formattedAccount = pipe(
    props.microTransactionDetails.prefixCode,
    option.fold(
      () =>
        formatMessage(
          "StandardLoan.MainContent.microTransaction.accountNumber.noPrefix",
          {
            accountNumber: props.microTransactionDetails.accountNumber,
            bankCode: props.microTransactionDetails.bankCode,
          }
        ),
      prefixCode =>
        formatMessage(
          "StandardLoan.MainContent.microTransaction.accountNumber.withPrefix",
          {
            prefixCode,
            accountNumber: props.microTransactionDetails.accountNumber,
            bankCode: props.microTransactionDetails.bankCode,
          }
        )
    )
  );
  const leftSide = (
    <Stack grow shrink column units={11}>
      <Heading size="small" weight="medium">
        {formatMessage(
          "StandardLoan.MainContent.microTransaction.sendmicroTransaction"
        )}
      </Heading>
      <FormRow type="2-1-1">
        <ReadOnlyField
          value={formattedAccount}
          label={formatMessage(
            "StandardLoan.MainContent.microTransaction.accountNumberLabel"
          )}
          size="small"
        />
        <ReadOnlyField
          value={formatMessage(
            "StandardLoan.MainContent.microTransaction.unicredit"
          )}
          label={formatMessage(
            "StandardLoan.MainContent.microTransaction.bank"
          )}
          size="small"
        />
        <ReadOnlyMoneyAmountField
          value={{
            amount: 1,
            currency,
          }}
          label={formatMessage(
            "StandardLoan.MainContent.microTransaction.amount"
          )}
          size="input"
        />
      </FormRow>
      <InlineMessage
        type="informative"
        size="medium"
        message={formatMessage(
          "StandardLoan.MainContent.microTransaction.weWillRefund"
        )}
      />
    </Stack>
  );

  const rightSide = (
    <Stack column units={4} hAlignContent="center">
      <Stack vAlignContent="center" units={2}>
        <Heading size="small" weight="medium" color={palette.white}>
          {formatMessage(
            "StandardLoan.MainContent.microTransaction.scanThisQRCode"
          )}
        </Heading>
        <Tooltip
          content={formatMessage(
            "StandardLoan.MainContent.microTransaction.qrCodeTooltip"
          )}
        >
          <InfoIcon size="default" color={palette.blue600} />
        </Tooltip>
      </Stack>
      <QRCode
        data-test-id="micro_payment_qr_code"
        code={props.qrCode}
        size={108}
      />
    </Stack>
  );

  return (
    <DoubleCard>
      {leftSide}
      {rightSide}
    </DoubleCard>
  );
}
