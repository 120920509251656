import {
  useForm,
  Dialog,
  validators,
  Form,
  FeedbackDialog,
} from "design-system";
import { option, taskEither } from "fp-ts";
import { pipe, constant } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { useFormatMessage } from "../../intl";
import { useValidators } from "../../Common/useValidators";
import { useCommand } from "../../useAPI";
import * as api from "../api";
import { BrokerEmailField } from "../Select3P/BrokerEmailField";
import { useRef, useState } from "react";

type BrokerData = {
  email: string;
};

type Props = {
  onDismiss: () => void;
  onSubmit: () => void;
  initialValues: Option<BrokerData>;
};

export function ApplicationBrokerForBankerDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const { nonEmptyString, validEmail } = useValidators();

  const saveAssignedBrokerForBanker = useCommand(
    api.saveAssignedBrokerForBanker
  );

  const [brokerSelectionError, setBrokerSelectionError] = useState(false);

  const { fieldProps, handleSubmit, setValues } = useForm(
    {
      initialValues: pipe(
        props.initialValues,
        option.fold(
          constant({
            email: "",
          }),
          initialValues => ({
            email: initialValues.email,
          })
        )
      ),
      fieldValidators: _ => ({
        email: validators.inSequence(nonEmptyString, validEmail),
      }),
    },
    {
      onSubmit: ({ email }) =>
        pipe({ email }, values =>
          pipe(
            saveAssignedBrokerForBanker(values),
            taskEither.map(({ status }) => {
              if (status === "VALID") {
                props.onSubmit();
                return taskEither.right(null);
              } else {
                setBrokerSelectionError(true);
                return taskEither.left(null);
              }
            })
          )
        ),
    }
  );

  const activeQueryTerms = useRef({
    email: "",
  });

  const syncFormState = (suggestion: string) =>
    setValues({
      email: suggestion,
    });

  return (
    <>
      <Dialog
        variant="left"
        title={formatMessage(
          option.isSome(props.initialValues)
            ? "StandardLoan.Select3P.edit"
            : "StandardLoan.Select3P.selectBroker"
        )}
        onDismiss={option.some(props.onDismiss)}
        actions={[
          {
            variant: "secondary",
            action: props.onDismiss,
            label: formatMessage("Cancel"),
          },
          {
            variant: "primary",
            action: handleSubmit,
            label: formatMessage("Save"),
          },
        ]}
        size="medium"
      >
        <Form>
          <BrokerEmailField
            showNativeTooltip
            {...fieldProps("email")}
            label={formatMessage("StandardLoan.Select3P.broker")}
            placeholder={formatMessage("StandardLoan.Select3P.broker")}
            onSelectSuggestion={syncFormState}
            queryString={activeQueryTerms.current.email}
            onChange={value => {
              activeQueryTerms.current = {
                email: value,
              };
              fieldProps("email").onChange(value);
            }}
          />
        </Form>
      </Dialog>
      {brokerSelectionError && (
        <FeedbackDialog
          type="negative"
          title={formatMessage("StandardLoan.Select3P.invalidBroker")}
          cta={{
            label: formatMessage("StandardLoan.Select3P.invalidBroker.OK"),
            action: () => setBrokerSelectionError(false),
          }}
        />
      )}
    </>
  );
}
