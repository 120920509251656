import { ComputedFieldProps, fieldIssues, Issues } from "design-system";
import { option } from "fp-ts";
import { constFalse, constTrue, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { useFormatMessage } from "../../intl";
import { PersonalDataFromOutput } from "../Confirmation/domain";

export function useReworkComparator<
  A extends Record<string, Option<B>>,
  B extends Record<string, Option<unknown>>
>(reworkData: Option<A>) {
  const formatMessage = useFormatMessage();

  function extract<T extends Record<string, Option<K>>, K>(
    base: Option<T>,
    fieldName: keyof T
  ): Option<K> {
    return pipe(
      base,
      option.chain(data => data[fieldName])
    );
  }

  function isReworked(
    rework: Option<A>,
    objectName: keyof A,
    fieldName: keyof B
  ) {
    return pipe(
      rework,
      option.chain(rework => extract(rework[objectName], fieldName)),
      option.fold(constFalse, constTrue)
    );
  }

  function formatIssues(
    objectName: keyof A,
    fieldName: keyof B,
    existingIssues: Option<Issues>
  ): Option<Issues> {
    return option.isNone(existingIssues) &&
      isReworked(reworkData, objectName, fieldName)
      ? option.some([
          fieldIssues.warning(
            formatMessage("StandardLoan.Rework.fieldChangedMessage")
          ),
        ])
      : existingIssues;
  }

  function isDisabled(objectName: keyof A, fieldName: keyof B): boolean {
    return (
      option.isSome(reworkData) &&
      !isReworked(reworkData, objectName, fieldName)
    );
  }

  function reworkFieldProps<T>(
    fieldProps: ComputedFieldProps<T>,
    objectName: keyof A,
    disabled: boolean,
    overrideFieldName?: string
  ) {
    return {
      ...fieldProps,
      issues: formatIssues(
        objectName,
        overrideFieldName ? overrideFieldName : fieldProps.name,
        fieldProps.issues
      ),
      disabled:
        disabled ||
        isDisabled(
          objectName,
          overrideFieldName ? overrideFieldName : fieldProps.name
        ),
    };
  }

  function specialFieldsReworkFieldProps<T>(
    fieldProps: ComputedFieldProps<T>,
    reworkDependent: boolean,
    reworked: boolean,
    disabled: boolean,
    reworkAll: boolean
  ): ComputedFieldProps<T> {
    return {
      ...fieldProps,
      issues:
        option.isNone(fieldProps.issues) && reworked
          ? option.some(
              fieldIssues.warnings([
                formatMessage("StandardLoan.Rework.fieldChangedMessage"),
              ])
            )
          : fieldProps.issues,
      disabled:
        !reworkAll &&
        (disabled ||
          (option.isSome(reworkData) && !reworkDependent && !reworked)),
    };
  }

  return {
    formatIssues,
    isDisabled,
    reworkFieldProps,
    specialFieldsReworkFieldProps,
  };
}

export function useReworkPersonalDataComparator(
  reworkPersonalData: Option<PersonalDataFromOutput>
) {
  const formatMessage = useFormatMessage();

  function isReworked(
    rework: Option<PersonalDataFromOutput>,
    fieldName: string
  ) {
    return pipe(
      rework,
      option.map(rework => {
        const k = rework.findIndex(item => item.key === fieldName);
        return k >= 0;
      }),
      option.getOrElse(() => false)
    );
  }

  function formatIssues(
    fieldName: string,
    existingIssues: Option<Issues>
  ): Option<Issues> {
    return option.isNone(existingIssues) &&
      isReworked(reworkPersonalData, fieldName)
      ? option.some(
          fieldIssues.warnings([
            formatMessage("StandardLoan.Rework.fieldChangedMessage"),
          ])
        )
      : existingIssues;
  }

  function isDisabled(fieldName: string): boolean {
    return (
      option.isSome(reworkPersonalData) &&
      !isReworked(reworkPersonalData, fieldName)
    );
  }

  return { formatIssues, isDisabled };
}

export function isReworked<
  A extends Record<string, Option<B>>,
  B extends Record<string, Option<unknown>>
>(rework: Option<A>, objectName: keyof A, fieldName: keyof B) {
  return pipe(
    rework,
    option.chain(rework => extract(rework[objectName], fieldName)),
    option.fold(constFalse, constTrue)
  );

  function extract<T extends Record<string, Option<K>>, K>(
    base: Option<T>,
    fieldName: keyof T
  ): Option<K> {
    return pipe(
      base,
      option.chain(data => data[fieldName])
    );
  }
}
