import {
  Body,
  Box,
  buttonLink,
  CheckIcon,
  ContentRow,
  FeatureList,
  Heading,
  LoadingButton,
  Stack,
  unsafeLocalizedString,
  useIsMobileLayout,
} from "design-system";
import { constVoid, pipe } from "fp-ts/function";
import { useFormatMessage } from "../../intl";
import { useCommand } from "../../useAPI";
import { PageHeading } from "../../Common/PageHeading/PageHeading";
import { MainContent } from "../../Common/MainContent";

import * as classes from "./Landing.treat";
import * as api from "../api";
import { boolean, option, taskEither } from "fp-ts";
import { useState } from "react";
import { Option } from "fp-ts/Option";
import { Configurator } from "./Configurator";
import { UnderAgeError } from "../domain";
import { GenericError } from "../../globalDomain";
import { useAppContext } from "../../useAppContext";
import { useIs3PChannel, useIsInPersonChannel } from "../../useChannel";
import { ChooseBrokerButton } from "./ChooseBrokerButton";

type Props = {
  onContinue: (loanOffer: api.LoanOffer, show3P?: boolean) => unknown;
  onAgeValidationFail: Option<() => unknown>;
};

export function Landing(props: Props) {
  const formatMessage = useFormatMessage();
  const startApplication = useCommand(api.startApplication);
  const initDefaults = useCommand(api.initDefaults);

  const {
    config: { enableCashLoanId3PInBranch },
  } = useAppContext();
  const isInPersonChannel = useIsInPersonChannel();
  const is3PChannel = useIs3PChannel();

  const [initialLoanOffer, setInitialLoanOffer] = useState<
    Option<api.LoanOfferWithLimits>
  >(option.none);
  const isMobileLayout = useIsMobileLayout();
  const stackBasis = pipe(
    isMobileLayout,
    boolean.fold(
      () => "25%",
      () => "auto"
    )
  );

  const handleError = (errors: UnderAgeError | GenericError) => {
    if (errors.id === "UnderAgeError") {
      return pipe(
        props.onAgeValidationFail,
        option.fold(
          () => taskEither.fromIO(constVoid),
          onAgeValidationFail => taskEither.fromIO(onAgeValidationFail)
        )
      );
    }
    return taskEither.left(errors);
  };

  const onClaimLinkClick = pipe(
    startApplication(),
    taskEither.chain(offer =>
      taskEither.fromIO(() => pipe(offer, option.some, setInitialLoanOffer))
    ),
    taskEither.orElse(handleError)
  );

  return (
    <MainContent>
      <PageHeading
        title={formatMessage("StandardLoan.Landing.Heading.Title")}
        description={formatMessage("StandardLoan.Landing.Heading.Description")}
      />
      <ContentRow type="lateral-margins">
        <Box column grow shrink>
          <Stack column units={18}>
            <FeatureList
              orientation={pipe(
                isMobileLayout,
                boolean.fold(
                  () => "horizontal",
                  () => "vertical"
                )
              )}
              size="medium"
              children={[
                {
                  label: formatMessage("StandardLoan.Landing.Heading.Feature1"),
                  icon: CheckIcon,
                },
                {
                  label: formatMessage("StandardLoan.Landing.Heading.Feature2"),
                  icon: CheckIcon,
                },
                {
                  label: formatMessage("StandardLoan.Landing.Heading.Feature3"),
                  icon: CheckIcon,
                },
              ]}
            />
            <Stack column shrink units={12} hAlignContent="center">
              <Heading size="medium" weight="medium">
                {formatMessage("StandardLoan.Landing.HowTo.Title")}
              </Heading>
              <Stack units={6} shrink column={isMobileLayout}>
                <Stack
                  vAlignContent="top"
                  units={2}
                  grow
                  shrink
                  basis={stackBasis}
                >
                  <Box
                    hAlignContent="center"
                    vAlignContent="center"
                    className={classes.stepNumber}
                  >
                    <Body size="medium" weight="medium">
                      {unsafeLocalizedString("1")}
                    </Body>
                  </Box>
                  <Stack column shrink>
                    <Body size="medium" weight="medium">
                      {formatMessage("StandardLoan.Landing.HowTo.Point1.Title")}
                    </Body>
                    <Body size="medium" weight="regular">
                      {formatMessage(
                        "StandardLoan.Landing.HowTo.Point1.Description"
                      )}
                    </Body>
                  </Stack>
                </Stack>

                <Stack
                  vAlignContent="top"
                  units={2}
                  grow
                  shrink
                  basis={stackBasis}
                >
                  <Box
                    hAlignContent="center"
                    vAlignContent="center"
                    className={classes.stepNumber}
                  >
                    <Body size="medium" weight="medium">
                      {unsafeLocalizedString("2")}
                    </Body>
                  </Box>
                  <Stack column shrink>
                    <Body size="medium" weight="medium">
                      {formatMessage("StandardLoan.Landing.HowTo.Point2.Title")}
                    </Body>
                    <Body size="medium" weight="regular">
                      {formatMessage(
                        "StandardLoan.Landing.HowTo.Point2.Description"
                      )}
                    </Body>
                  </Stack>
                </Stack>

                <Stack
                  vAlignContent="top"
                  units={2}
                  grow
                  shrink
                  basis={stackBasis}
                >
                  <Box
                    hAlignContent="center"
                    vAlignContent="center"
                    className={classes.stepNumber}
                  >
                    <Body size="medium" weight="medium">
                      {unsafeLocalizedString("3")}
                    </Body>
                  </Box>
                  <Stack column shrink>
                    <Body size="medium" weight="medium">
                      {formatMessage("StandardLoan.Landing.HowTo.Point3.Title")}
                    </Body>
                    <Body size="medium" weight="regular">
                      {formatMessage(
                        "StandardLoan.Landing.HowTo.Point3.Description"
                      )}
                    </Body>
                  </Stack>
                </Stack>

                <Stack
                  vAlignContent="top"
                  units={2}
                  grow
                  shrink
                  basis={stackBasis}
                >
                  <Box
                    hAlignContent="center"
                    vAlignContent="center"
                    className={classes.stepNumber}
                  >
                    <Body size="medium" weight="medium">
                      {unsafeLocalizedString("4")}
                    </Body>
                  </Box>
                  <Stack column shrink>
                    <Body size="medium" weight="medium">
                      {formatMessage("StandardLoan.Landing.HowTo.Point4.Title")}
                    </Body>
                    <Body size="medium" weight="regular">
                      {formatMessage(
                        "StandardLoan.Landing.HowTo.Point4.Description"
                      )}
                    </Body>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {pipe(
              initialLoanOffer,
              option.fold(
                () => (
                  <Stack units={6} column hAlignContent="center" shrink>
                    {enableCashLoanId3PInBranch &&
                    isInPersonChannel &&
                    !is3PChannel ? (
                      <Stack units={4} column hAlignContent="center" shrink>
                        <LoadingButton
                          size="default"
                          variant="primary"
                          labels={{
                            normal: formatMessage(
                              "StandardLoan.Landing.LoanWithout3P"
                            ),
                            loading: formatMessage(
                              "StandardLoan.Landing.LoanWithout3P"
                            ),
                            success: formatMessage(
                              "StandardLoan.Landing.LoanWithout3P"
                            ),
                            error: formatMessage(
                              "StandardLoan.Landing.LoanWithout3P"
                            ),
                          }}
                          action={pipe(
                            initDefaults(),
                            taskEither.chain(offer =>
                              taskEither.fromIO(() =>
                                props.onContinue(offer, false)
                              )
                            ),
                            taskEither.orElse(handleError)
                          )}
                        />
                        <ChooseBrokerButton
                          heading={formatMessage(
                            "StandardLoan.Landing.LoanWith3P"
                          )}
                          action={pipe(
                            initDefaults(),
                            taskEither.chain(offer =>
                              taskEither.fromIO(() =>
                                props.onContinue(offer, true)
                              )
                            ),
                            taskEither.orElse(handleError)
                          )}
                          buttonLabel={formatMessage(
                            "Mortgage.Dashboard.Overview.brokerForBankerApp.choose"
                          )}
                        />
                      </Stack>
                    ) : (
                      <LoadingButton
                        size="default"
                        variant="primary"
                        labels={{
                          normal: formatMessage(
                            "StandardLoan.Landing.CTALabel"
                          ),
                          loading: formatMessage(
                            "StandardLoan.Landing.CTALabel"
                          ),
                          success: formatMessage(
                            "StandardLoan.Landing.CTALabel"
                          ),
                          error: formatMessage("StandardLoan.Landing.CTALabel"),
                        }}
                        action={pipe(
                          initDefaults(),
                          taskEither.chain(offer =>
                            taskEither.fromIO(() =>
                              props.onContinue(offer, false)
                            )
                          ),
                          taskEither.orElse(handleError)
                        )}
                      />
                    )}
                    <Body size="medium" weight="regular">
                      {formatMessage(
                        "StandardLoan.Landing.RepresentativeOffer.Claim.BeforeLink"
                      )}
                      {buttonLink(
                        onClaimLinkClick,
                        formatMessage(
                          "StandardLoan.Landing.RepresentativeOffer.Claim.LinkText"
                        )
                      )}
                    </Body>
                  </Stack>
                ),
                offer => (
                  <Configurator
                    initialOffer={offer}
                    onContinue={props.onContinue}
                  />
                )
              )
            )}
          </Stack>
        </Box>
      </ContentRow>
    </MainContent>
  );
}
