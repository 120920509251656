import {
  Body,
  Heading,
  Space,
  Stack,
  Card,
  Divider,
  Box,
  ContentRow,
  Loader,
  ErrorBanner,
  useIsMobileLayout,
  LoadingButton,
} from "design-system";
import { useFormatMessage } from "../../intl";
import { PageHeading } from "../../Common/PageHeading/PageHeading";
import { pipe, constNull, constant } from "fp-ts/function";
import { option, taskEither } from "fp-ts";
import { Option } from "fp-ts/Option";
import { InternalLiabilityRow } from "./InternalLiabilityRow";
import { ExternalLiabilitiesEditForm } from "./ExternalLiabilitiesEditForm";
import { BackButton } from "../../Common/BackButton/BackButton";
import { IO } from "fp-ts/IO";
import {
  AccountsForRefinancingOutput,
  ExternalLoanItem,
  getAccountsForRefinancing,
  InternalLoansList,
  submitAccountsForRefinancing,
} from "../api";
import { useCommand, useQuery } from "../../useAPI";
import * as remoteData from "../../RemoteData";
import { TaskEither } from "fp-ts/TaskEither";
import { Rework } from "../StandardLoanState";
import { ReworkBanner } from "../Rework/ReworkBanner";
import { useGetAccountsForRefinancingStepChanges } from "../Rework/utils";
import * as reworkApi from "../Rework/api";
import { StepCommentsOutput } from "../Rework/api";
import { useAppContext } from "../../useAppContext";
import { useRemoteData } from "../../useRemoteData";
import { useMemo } from "react";
import { MainContent } from "../../Common/MainContent";

type Props = {
  onConfirm: TaskEither<unknown, unknown>;
  onBack: IO<unknown>;
  existingClient: boolean;
  reworkData: Rework;
};

export function AccountsForRefinancing(props: Props) {
  const formatMessage = useFormatMessage();
  const [getAccounts] = useQuery(getAccountsForRefinancing);
  const submitAccounts = useCommand(submitAccountsForRefinancing);

  const internalLiabilities = (internalLoans: Option<InternalLoansList>) =>
    pipe(
      internalLoans,
      option.fold(
        () => <></>,
        internalLoans =>
          internalLoans.liabilitiesList.length > 0 && (
            <Stack column units={4}>
              <Body size="small" weight="medium">
                {formatMessage(
                  "StandardLoan.AccountsForRefinancing.InternalLiabilities.title"
                )}
              </Body>
              {internalLoans.liabilitiesList.map((liability, index) => (
                <InternalLiabilityRow
                  key={index}
                  index={index}
                  liability={liability}
                  divider={
                    internalLoans.liabilitiesList.length > 1 &&
                    index < internalLoans.liabilitiesList.length - 1
                  }
                />
              ))}
            </Stack>
          )
      )
    );

  const reworkedAccounts = pipe(
    props.reworkData.oldValues,
    option.chain(oldValues => oldValues.accountsForRefinancingData),
    option.chain(accounts => accounts.externalLoans)
  );

  const externalLiabilities = (accounts: AccountsForRefinancingOutput) =>
    pipe(
      accounts.externalLoans,
      option.fold(constNull, externalLoans =>
        externalLoans.liabilitiesList.length > 0 ? (
          <Stack column units={4}>
            <Space units={10} />
            <Body size="small" weight="medium">
              {formatMessage(
                "StandardLoan.AccountsForRefinancing.ExternalLiabilities.title"
              )}
            </Body>
            <Body size="x-small" weight="regular">
              {formatMessage(
                "StandardLoan.AccountsForRefinancing.ExternalLiabilities.subTitle"
              )}
            </Body>
            <ExternalLiabilitiesEditForm
              existingClient={props.existingClient}
              liabilities={externalLoans.liabilitiesList}
              account={{
                account: accounts.accountNumber,
                currency: accounts.accountCurrency,
              }}
              onSubmit={externalLoansList =>
                pipe(
                  {
                    ...accounts,
                    externalLoans: option.some(externalLoansList),
                  },
                  submitAccounts,
                  taskEither.chain(() => props.onConfirm)
                )
              }
              oldValues={reworkedAccounts}
            />
          </Stack>
        ) : (
          <Box column grow hAlignContent="right">
            <Space units={10} />
            <LoadingButton
              type="submit"
              variant="primary"
              size="default"
              labels={{
                normal: formatMessage(
                  "StandardLoan.AccountsForRefinancing.button"
                ),
                success: formatMessage(
                  "StandardLoan.AccountsForRefinancing.button"
                ),
                loading: formatMessage("Loading"),
                error: formatMessage("Error"),
              }}
              action={pipe(
                {
                  ...accounts,
                },
                submitAccounts,
                taskEither.chain(() => props.onConfirm)
              )}
            />
          </Box>
        )
      )
    );
  const isMobileLayout = useIsMobileLayout();

  const getAccountsForRefinancingStepChanges = useGetAccountsForRefinancingStepChanges(
    pipe(
      getAccounts,
      remoteData.fold(constant([]), constant([]), accounts =>
        pipe(
          accounts.externalLoans,
          option.map(el => el.liabilitiesList),
          option.getOrElse<ExternalLoanItem[]>(constant([]))
        )
      )
    ),
    pipe(
      reworkedAccounts,
      option.map(v => v.liabilitiesList)
    )
  );

  const stepComments = useCommand(reworkApi.stepComments);

  const {
    config: { r6Enabled },
  } = useAppContext();

  const comments = useRemoteData(
    useMemo(() => stepComments({ reworkCategories: ["CONTRACT"] }), [r6Enabled])
  );

  const reworkComments = pipe(
    comments,
    remoteData.fold(
      () => option.none,
      () => option.none,
      comments => option.some(comments)
    )
  );

  const reworkBanner = (comments: Option<StepCommentsOutput>) => {
    return pipe(
      props.reworkData.oldValues,
      option.fold(constNull, () => (
        <ContentRow type="lateral-margins">
          <ReworkBanner
            changes={getAccountsForRefinancingStepChanges}
            stepComments={comments}
          />
        </ContentRow>
      ))
    );
  };

  const areReworkChanges =
    option.isSome(getAccountsForRefinancingStepChanges) &&
    getAccountsForRefinancingStepChanges.value.length > 0;

  const areReworkComments =
    option.isSome(reworkComments) &&
    option.isSome(reworkComments.value.stepComments) &&
    reworkComments.value.stepComments.value.length > 0;

  return (
    <MainContent>
      <Box grow shrink column>
        {isMobileLayout && <Space units={6} />}
        <PageHeading
          title={formatMessage("StandardLoan.AccountsForRefinancing.title")}
          hideOnMobile
        />
        {(areReworkChanges || areReworkComments) &&
          reworkBanner(reworkComments)}

        <ContentRow type="lateral-margins">
          <Card>
            <Stack column units={4} grow shrink>
              <Heading size="small" weight="medium">
                {formatMessage("StandardLoan.AccountsForRefinancing.subTitle")}
              </Heading>
              <Divider />
              {pipe(
                getAccounts,
                remoteData.fold(
                  () => <Loader />,
                  () => (
                    <ErrorBanner>{formatMessage("GenericError")}</ErrorBanner>
                  ),
                  liabilities => (
                    <>
                      {internalLiabilities(liabilities.internalLoans)}
                      {externalLiabilities(liabilities)}
                    </>
                  )
                )
              )}
            </Stack>
          </Card>
        </ContentRow>
        <Space units={10} />
        <ContentRow type="lateral-margins">
          <Box grow column={isMobileLayout}>
            <BackButton action={props.onBack} />
          </Box>
        </ContentRow>
      </Box>
    </MainContent>
  );
}
