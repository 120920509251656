import {
  Box,
  Button,
  FormRow,
  FormSection,
  InputSliderField,
  Loader,
  PositiveInteger,
  ReadOnlyField,
  Space,
  unsafePositiveInteger,
  useIsMobileLayout,
} from "design-system";
import { boolean, either, option } from "fp-ts";
import { constNull, constVoid, flow, pipe } from "fp-ts/function";
import { Reader } from "fp-ts/Reader";
import { useState } from "react";
import { useFormatMessage, useFormatPercentageLike } from "../../intl";
import { ReadOnlyMoneyAmountField } from "../../Common/ReadOnlyMoneyAmountField/ReadOnlyMoneyAmountField";
import * as api from "../api";

type Props = {
  currentLoanOffer: api.LoanOfferWithLimits & { isLoading: boolean };
  onChange: Reader<PositiveInteger, unknown>;
};

export function Tenor(props: Props) {
  const formatMessage = useFormatMessage();
  const formatPercentageLike = useFormatPercentageLike();
  const isMobileLayout = useIsMobileLayout();
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Box>
      <Box column grow>
        {pipe(
          isEditing,
          boolean.fold(constNull, () => (
            <>
              <InputSliderField
                value={props.currentLoanOffer.tenor}
                onChange={flow(
                  PositiveInteger.decode,
                  either.getOrElse(() => unsafePositiveInteger(1)),
                  props.onChange
                )}
                min={props.currentLoanOffer.minTenor}
                max={props.currentLoanOffer.maxTenor}
                step={unsafePositiveInteger(3)}
                minLabelContent={() =>
                  formatMessage(
                    "PreApprovedLoanSaleConfigurator.minTenorLabel",
                    {
                      months: props.currentLoanOffer.minTenor,
                    }
                  )
                }
                maxLabelContent={() =>
                  formatMessage(
                    "PreApprovedLoanSaleConfigurator.maxTenorLabel",
                    {
                      months: props.currentLoanOffer.maxTenor,
                    }
                  )
                }
                name="currentTenor"
                issues={option.none}
                label={formatMessage(
                  "PreApprovedLoanSaleConfigurator.tenorLabelTenor"
                )}
                onBlur={constVoid}
                rightContent={formatMessage(
                  "PreApprovedLoanSaleConfigurator.tenorSliderUnits"
                )}
                disabled={props.currentLoanOffer.isLoading}
              />
              <Space units={isMobileLayout ? 6 : 8} />
            </>
          ))
        )}
        <FormSection>
          {pipe(
            isEditing,
            boolean.fold(
              () => (
                <FormRow type="full">
                  <ReadOnlyField
                    size="small"
                    label={formatMessage(
                      "PreApprovedLoanSaleConfigurator.tenorLabelTenor"
                    )}
                    value={formatMessage(
                      "PreApprovedLoanSaleConfigurator.tenorDescriptionTenor",
                      {
                        months: props.currentLoanOffer.tenor,
                      }
                    )}
                  />
                </FormRow>
              ),
              constNull
            )
          )}
          <FormRow type="full">
            {pipe(
              props.currentLoanOffer.isLoading,
              boolean.fold(
                () => (
                  <ReadOnlyMoneyAmountField
                    size="x-small"
                    label={formatMessage(
                      "PreApprovedLoanSaleConfigurator.tenorLabelInstalment"
                    )}
                    value={{
                      amount: props.currentLoanOffer.installment,
                      currency: props.currentLoanOffer.currency,
                    }}
                  />
                ),
                () => <Loader />
              )
            )}
          </FormRow>
          <FormRow type="full">
            <ReadOnlyField
              size="small"
              label={formatMessage(
                "PreApprovedLoanSaleConfigurator.tenorLabelInterestRate"
              )}
              value={formatMessage(
                "PreApprovedLoanSaleConfigurator.tenorDescriptionInterestRate",
                {
                  interestRate: formatPercentageLike(
                    props.currentLoanOffer.interestRate
                  ),
                }
              )}
            />
          </FormRow>
        </FormSection>
      </Box>
      {pipe(
        isEditing,
        boolean.fold(
          () => (
            <Button
              variant="text"
              size="default"
              label={formatMessage("PreApprovedLoanSaleConfigurator.tenorEdit")}
              action={() => setIsEditing(true)}
            />
          ),
          constNull
        )
      )}
    </Box>
  );
}
