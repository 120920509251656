import { useState } from "react";
import { constFalse, pipe } from "fp-ts/function";
import * as api from "../api";
import { AutocompleteInputField } from "design-system";
import { option } from "fp-ts";
import { useFormatMessage } from "../../intl";
import { Option } from "fp-ts/Option";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";

type Props = Omit<
  React.ComponentProps<typeof AutocompleteInputField>,
  "suggestionsStatus" | "onSelectSuggestion" | "loadingMessage"
> & {
  onSelectSuggestion: (value: api.CreditorData) => unknown;
  loanProviders: NonEmptyArray<api.CreditorData>;
};

const MIN_CREDITOR_SEARCH_TERM_LENGTH = 2;

export function CreditorAutocompleteField(props: Props) {
  const formatMessage = useFormatMessage();

  const [lastSelectedTerm, setLastSelectedTerm] = useState<Option<string>>(
    option.none
  );

  const termEquals = (
    previousTerm: Option<string>,
    currentTerm: string
  ): boolean => {
    return pipe(
      previousTerm,
      option.fold(constFalse, term => term === currentTerm)
    );
  };

  const queryTerms = props.value.toLowerCase();

  return (
    <AutocompleteInputField
      showNativeTooltip
      {...props}
      suggestionsStatus={{
        status: "ready",
        suggestions: props.loanProviders
          .filter(
            item =>
              props.value.length >= MIN_CREDITOR_SEARCH_TERM_LENGTH &&
              !termEquals(lastSelectedTerm, props.value) &&
              item.name.toLowerCase().includes(queryTerms)
          )
          .map(suggestion => ({
            value: suggestion,
            label: formatMessage("Form.companyField.autocompleteLabel", {
              name: suggestion.name,
              ico: suggestion.code,
            }),
          })),
      }}
      onSelectSuggestion={s => {
        props.onSelectSuggestion(s.value);
        props.onChange(s.value.name);
        setLastSelectedTerm(option.some(s.value.name));
      }}
      loadingMessage={formatMessage("LoadingEllipsis")}
    />
  );
}
