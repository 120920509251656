import { boolean, option } from "fp-ts";
import { constFalse, pipe } from "fp-ts/function";
import { ApplicationProductType } from "../../Common/ProcessStoreList/api";
import { Summary } from "./Summary";
import { SummaryExistingClient } from "./SummaryExistingClient";
import { Option } from "fp-ts/Option";
import { LFStatus } from "../api";
import { IO } from "fp-ts/IO";
import { StandardLoanFlowType } from "../domain";

type Props = {
  productType: Option<ApplicationProductType>;
  lfStatus: LFStatus;
  clientExists: boolean;
  hasRefinancing: boolean;
  goToClient: IO<unknown>;
  goToMicroPayments: IO<unknown>;
  flowType: StandardLoanFlowType;
};

export const SLSummary = (props: Props) => {
  return pipe(
    props.clientExists,
    boolean.fold(
      () => (
        <Summary
          clientExists={false}
          goToMicrotransaction={props.goToMicroPayments}
          flowType={props.flowType}
        />
      ),
      () => (
        <SummaryExistingClient
          lfStatus={props.lfStatus}
          isRLProductType={pipe(
            props.productType,
            option.fold(constFalse, productType => productType === "RL")
          )}
          hasRefinancing={props.hasRefinancing}
          onGoToClientPage={props.goToClient}
          flowType={props.flowType}
        />
      )
    )
  );
};
