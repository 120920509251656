import { AlertDialog, InfoIcon } from "design-system";
import { useFormatMessage } from "../intl";

type Props = {
  onSave: () => unknown;
  onCancel: () => unknown;
  onDismiss: () => unknown;
};

export function SaveApplicationDialog(props: Props) {
  const formatMessage = useFormatMessage();

  return (
    <AlertDialog
      type="confirmation"
      title={formatMessage("PreApprovedLoanSale.SaveApplicationModal.title")}
      message={formatMessage(
        "PreApprovedLoanSale.SaveApplicationModal.description"
      )}
      confirmLabel={formatMessage(
        "PreApprovedLoanSale.SaveApplicationModal.save"
      )}
      cancelLabel={formatMessage(
        "PreApprovedLoanSale.SaveApplicationModal.cancel"
      )}
      onConfirm={props.onSave}
      onCancel={props.onCancel}
      onDismiss={props.onDismiss}
      icon={InfoIcon}
    />
  );
}
