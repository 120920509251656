import {
  Body,
  Box,
  Card,
  CheckboxField,
  ContentRow,
  ErrorBanner,
  Form,
  FormRow,
  FormSection,
  Heading,
  Loader,
  Space,
  Stack,
  TextField,
  useIsMobileLayout,
  useForm,
  validators,
} from "design-system";
import { useFormatMessage } from "../../intl";
import { option, taskEither } from "fp-ts";
import { NextButton } from "../../Common/NextButton";
import { useValidators } from "../../Common/useValidators";
import { BankIdentitiesDropdown } from "./BankIdentitiesDropdown";
import * as api from "./api";
import { useCommand, useQuery } from "../../useAPI";
import { pipe } from "fp-ts/function";
import * as remoteData from "../../RemoteData";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { Option } from "fp-ts/Option";
import { BankIdentityFormValue } from "./domain";
import { PageHeading } from "../../Common/PageHeading/PageHeading";
import { IO } from "fp-ts/IO";
import { BackButton } from "../../Common/BackButton/BackButton";

type Props = {
  onNext: () => unknown;
  onBack: IO<unknown>;
};

const initialValues = {
  prefixCode: "",
  accountNumber: "",
  bankIdentity: option.none as Option<BankIdentityFormValue>,
  idConfirmation: false,
};

export function MicroTransaction(props: Props) {
  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();

  const {
    nonBlankString,
    maxLength,
    digitsOnly,
    modulo11Compliant,
    defined,
  } = useValidators();

  const submitBankAccount = useCommand(api.bankAccountSubmit);
  const saveMicroPaymentDataConfirmation = useCommand(
    api.saveMicroPaymentDataConfirmation
  );

  const [getBasicPersonalData] = useQuery(api.basicPersonalData);

  const { fieldProps, handleSubmit } = useForm(
    {
      initialValues,
      fieldValidators: _ => ({
        prefixCode: validators.inSequence(maxLength(6), digitsOnly),
        accountNumber: validators.inSequence(nonBlankString, modulo11Compliant),
        bankIdentity: defined(),
        idConfirmation: validators.checked(
          formatMessage("StandardLoan.microTransaction.missingIdConfirmation")
        ),
      }),
    },
    {
      onSubmit: values =>
        pipe(
          submitBankAccount({
            prefixCode: values.prefixCode,
            accountNumber: values.accountNumber as NonEmptyString,
            bankCode: values.bankIdentity.id,
            bankName: values.bankIdentity.name,
            confirmationLabel: formatMessage(
              "StandardLoan.microTransactionCheckbox",
              {
                fullName: "clientName",
              }
            ),
          }),
          taskEither.chain(() => taskEither.fromIO(props.onNext))
        ),
    }
  );

  return pipe(
    getBasicPersonalData,
    remoteData.fold(
      () => (
        <ContentRow type="full">
          <Loader />
        </ContentRow>
      ),
      () => <ErrorBanner children={formatMessage("GenericError")} />,
      personalData => {
        const form = (
          <Form grow shrink>
            <FormSection>
              <FormRow type="1-1-1">
                <TextField
                  {...fieldProps("prefixCode")}
                  label={formatMessage(
                    "StandardLoan.microTransaction.prefixCode"
                  )}
                  placeholder={formatMessage(
                    "StandardLoan.microTransaction.prefixCodePlaceholder"
                  )}
                />
                <TextField
                  {...fieldProps("accountNumber")}
                  label={formatMessage(
                    "StandardLoan.microTransaction.accountNumberLabel"
                  )}
                  placeholder={formatMessage(
                    "StandardLoan.microTransaction.accountNumberPlaceholder"
                  )}
                />
                <BankIdentitiesDropdown
                  {...fieldProps("bankIdentity")}
                  label={formatMessage(
                    "StandardLoan.microTransaction.bankCode"
                  )}
                  placeholder={formatMessage(
                    "StandardLoan.microTransaction.bankCodePlaceholder"
                  )}
                  clearable
                />
              </FormRow>
              <FormRow type="full">
                <CheckboxField
                  {...fieldProps("idConfirmation")}
                  onChange={checked => {
                    saveMicroPaymentDataConfirmation({ consent: checked })();
                    fieldProps("idConfirmation").onChange(checked);
                  }}
                  label={formatMessage(
                    "StandardLoan.microTransactionCheckbox",
                    {
                      fullName: `${personalData.name} ${personalData.surname}`,
                    }
                  )}
                  multiline={isMobileLayout}
                />
              </FormRow>
            </FormSection>
          </Form>
        );

        return (
          <Box grow shrink column>
            <PageHeading
              title={formatMessage("StandardLoan.microTransactionFormTitle")}
              description={formatMessage(
                "StandardLoan.microTransactionFormSubtitle"
              )}
            />
            <ContentRow type="lateral-margins">
              <Box column grow shrink>
                <Card>
                  <Stack units={2} column shrink grow>
                    <Heading size="small" weight="medium">
                      {formatMessage("StandardLoan.microTransactionCardTitle")}
                    </Heading>
                    <Body size="small" weight="regular">
                      {formatMessage(
                        "StandardLoan.microTransactionCardSubtitle"
                      )}
                    </Body>
                    <Space units={6} />
                    <Stack units={6} column grow shrink>
                      {form}
                      <Box hAlignContent="right">
                        <NextButton action={handleSubmit} />
                      </Box>
                    </Stack>
                  </Stack>
                </Card>
              </Box>
            </ContentRow>
            <Space units={10} />
            <Box>
              <BackButton action={props.onBack} />
            </Box>
          </Box>
        );
      }
    )
  );
}
