import {
  Body,
  Box,
  CheckboxField,
  ComputedFieldProps,
  Divider,
  FieldHeader,
  FormRow,
  Space,
  unsafeLocalizedString,
  useUniqueID,
} from "design-system";
import { option } from "fp-ts";
import { constant, constNull, pipe } from "fp-ts/function";
import { useFormatDate, useFormatMessage } from "../../intl";
import { DisplayMoneyAmount } from "../../Common/Price/DisplayMoneyAmount";
import { getTypeOfProductLabel, LiabilityFormModel } from "../domain";

type Props = {
  namePrefix: string;
  onChangeValues: (value: LiabilityFormModel) => void;
  fieldProps: <K extends keyof LiabilityFormModel>(
    name: K
  ) => ComputedFieldProps<LiabilityFormModel[K]>;
  divider: boolean;
};

export function LiabilityEditableItem(props: Props) {
  const formatMessage = useFormatMessage();
  const formatDate = useFormatDate("dd-mm-yyyy");

  const labelId = useUniqueID();
  const startDateField = pipe(
    props.fieldProps("startDate").value,
    option.fold(constNull, startDate => (
      <Body id={`${labelId}_type`} size="small" weight="regular">
        {pipe(
          option.some(startDate),
          option.fold(constant("-"), formatDate),
          unsafeLocalizedString
        )}
      </Body>
    ))
  );

  return (
    <Box column grow shrink>
      <FormRow shrink type="1-1-1-1">
        <Box column>
          <FieldHeader
            label={formatMessage(
              "StandardLoan.LiabilityItem.typeOfProductLabel"
            )}
            labelId={`${labelId}_type`}
            readonly
          />
          <CheckboxField
            {...props.fieldProps("selected")}
            label={formatMessage(
              getTypeOfProductLabel(props.fieldProps("type").value)
            )}
            description={unsafeLocalizedString("")}
            onChange={selected =>
              props.onChangeValues({
                type: props.fieldProps("type").value,
                originalAmount: props.fieldProps("originalAmount").value,
                remainingAmount: props.fieldProps("remainingAmount").value,
                startDate: props.fieldProps("startDate").value,
                selected,
                recordId: props.fieldProps("recordId").value,
              })
            }
          />
        </Box>
        <Box column>
          <FieldHeader
            label={formatMessage(
              "StandardLoan.LiabilityItem.originalAmountLabel"
            )}
            labelId={`${labelId}_original`}
            readonly
          />
          <Space units={3} />
          <DisplayMoneyAmount
            id={`${labelId}_original`}
            amount={props.fieldProps("originalAmount").value}
            size="x-small"
          />
        </Box>
        <Box column>
          <FieldHeader
            label={formatMessage(
              "StandardLoan.LiabilityItem.remainingAmountLabel"
            )}
            labelId={`${labelId}_remaining`}
            readonly
          />
          <Space units={3} />
          <DisplayMoneyAmount
            id={`${labelId}_remaining`}
            amount={props.fieldProps("remainingAmount").value}
            size="x-small"
          />
        </Box>
        <Box column>
          <FieldHeader
            label={formatMessage("StandardLoan.LiabilityItem.startDateLabel")}
            labelId={`${labelId}_date`}
            readonly
          />
          <Space units={3} />
          {startDateField}
        </Box>
      </FormRow>

      <Space units={4} />
      {props.divider && (
        <FormRow type="full">
          <Divider />
        </FormRow>
      )}
    </Box>
  );
}
