import { apiCall } from "../../APICall";
import * as t from "io-ts";
import { constFalse, constTrue } from "fp-ts/function";
import { eq } from "fp-ts";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { BankIdentity } from "./domain";
import { FileContent, LocalizedString } from "design-system";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export const BankIdentityOutput = t.array(BankIdentity);

export const bankIdentities = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: BankIdentityOutput,
  path: ["packages", "loans", "microTransaction", "bankCodes"],
});

export const MicroTransactionDetailsOutput = t.type({
  accountNumber: NonEmptyString,
  bankCode: NonEmptyString,
  prefixCode: optionFromNullable(NonEmptyString),
});
export type MicroTransactionDetailsOutput = t.TypeOf<
  typeof MicroTransactionDetailsOutput
>;

export const microTransactionDetails = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: MicroTransactionDetailsOutput,
  path: ["packages", "loans", "microTransaction", "getBankAccountData"],
});

export const BasicPersonalDataOutput = t.type({
  name: NonEmptyString,
  surname: NonEmptyString,
});

export const basicPersonalData = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: BasicPersonalDataOutput,
  path: ["clients", "identification", "basicPersonalData"],
});

export const bankAccountSubmitInput = t.type({
  prefixCode: t.string,
  accountNumber: NonEmptyString,
  bankCode: NonEmptyString,
  bankName: LocalizedString,
  confirmationLabel: LocalizedString,
});

export type BankAccountSubmitInput = t.TypeOf<typeof bankAccountSubmitInput>;

const eqBankAccountSubmitInput = eq.getStructEq({
  prefixCode: eq.eqString,
  accountNumber: eq.eqString,
  bankCode: eq.eqString,
  confirmationLabel: eq.eqString,
});

export const bankAccountSubmit = apiCall({
  inputCodec: bankAccountSubmitInput,
  inputEq: eqBankAccountSubmitInput,
  outputCodec: t.unknown,
  path: ["packages", "loans", "microTransaction", "accountData"],
});

const SaveMicroPaymentDataConfirmationInput = t.type({
  consent: t.boolean,
});

const eqSaveMicroPaymentDataConfirmationInput = eq.getStructEq({
  consent: eq.eqBoolean,
});

export const saveMicroPaymentDataConfirmation = apiCall({
  inputCodec: SaveMicroPaymentDataConfirmationInput,
  inputEq: eqSaveMicroPaymentDataConfirmationInput,
  outputCodec: t.unknown,
  path: ["packages", "loans", "microTransaction", "accountDataConfirmation"],
});

const QRCodeInput = t.type({
  size: t.number,
});

const QRCodeOutput = FileContent;

export const getQRCode = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["packages", "loans", "microTransaction", "QRCode"],
  inputCodec: QRCodeInput,
  outputCodec: QRCodeOutput,
});
