import { Box, Loader } from "design-system";
import { useEffect } from "react";

interface Props {
  onReload: () => unknown;
}

export const ReloadSuspense = (props: Props) => {
  useEffect(() => {
    props.onReload();
  }, []);

  return (
    <Box hAlignContent="center">
      <Loader />
    </Box>
  );
};
