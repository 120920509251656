import {
  Body,
  Box,
  ErrorBanner,
  Loader,
  LocalizedString,
  Stack,
  UnorderedList,
  useIsMobileLayout,
} from "design-system";
import { array, taskEither } from "fp-ts";
import { constant, pipe } from "fp-ts/function";
import { Reader } from "fp-ts/Reader";
import { useState } from "react";
import { useFormatMessage } from "../../intl";
import { useCommand, usePollingEffect } from "../../useAPI";
import * as api from "../api";

type DocumentListStatus =
  | {
      status: "Pending";
    }
  | { status: "Received"; list: api.UploadedDocumentData[] }
  | { status: "NotReceived" };

function foldDocumentListStatus<R>(pattern: {
  Pending: () => R;
  Received: (list: api.UploadedDocumentData[]) => R;
  NotReceived: () => R;
}): Reader<DocumentListStatus, R> {
  return (status: DocumentListStatus) => {
    switch (status.status) {
      case "NotReceived":
        return pattern.NotReceived();
      case "Received":
        return pattern.Received(status.list);
      case "Pending":
        return pattern.Pending();
    }
  };
}

export const OfferDocuments = () => {
  const formatMessage = useFormatMessage();
  const [
    requiredDocumentsList,
    setRequiredDocumentsList,
  ] = useState<DocumentListStatus>({ status: "Pending" });
  const requiredDocuments = useCommand(api.previewRequiredDocuments);
  const isMobileLayout = useIsMobileLayout();

  function scrollTop() {
    if (isMobileLayout) {
      window.scrollTo(0, 0);
    }
  }

  usePollingEffect(api.checkRequiredDocuments, {
    intervalMS: 1000,
    shouldPollingContinue: ({ requiredDocumentsReceived }) =>
      requiredDocumentsReceived === false,
    onSuccess: ({ requiredDocumentsReceived }) =>
      requiredDocumentsReceived &&
      pipe(
        requiredDocuments(),
        taskEither.bimap(
          () => {
            setRequiredDocumentsList({ status: "NotReceived" });
            scrollTop();
          },
          list => {
            setRequiredDocumentsList({ status: "Received", list });
            scrollTop();
          }
        )
      )(),
    onError: () => {
      setRequiredDocumentsList({ status: "NotReceived" });
      scrollTop();
    },
  });

  return pipe(
    requiredDocumentsList,
    foldDocumentListStatus({
      NotReceived: constant(
        <ErrorBanner>
          {formatMessage("StandardLoan.UploadDocuments.loadingError")}
        </ErrorBanner>
      ),
      Pending: constant(
        <Stack column units={8}>
          <Box hAlignContent="center">
            <Loader />
          </Box>
          <Box column>
            <Body size="medium" align="center" weight="regular">
              {formatMessage(
                "StandardLoan.OfferApproved.OfferReview.documentationLoading"
              )}
            </Body>
          </Box>
        </Stack>
      ),
      Received: list => {
        const documentList: LocalizedString[] = list.map(
          document => document.documentName
        );

        return array.isNonEmpty(documentList) ? (
          <Stack column units={2}>
            <Body size="medium" weight="medium">
              {formatMessage(
                "StandardLoan.OfferApproved.OfferReview.documentation"
              )}
            </Body>
            <Body size="small" weight="regular" color="#666666">
              {formatMessage(
                "StandardLoan.OfferApproved.OfferReview.documentationDescription"
              )}
            </Body>
            <UnorderedList size="medium" listStyle="bullet">
              {documentList}
            </UnorderedList>
          </Stack>
        ) : null;
      },
    })
  );
};
