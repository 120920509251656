import { DropdownOption } from "design-system";
import { constant, identity, pipe } from "fp-ts/function";
import { array, option } from "fp-ts";
import { useFormatMessage } from "../../intl";
import { useQuery } from "../../useAPI";
import * as api from "./api";
import { Option } from "fp-ts/Option";
import * as remoteData from "../../RemoteData";
import { BankIdentity, BankIdentityFormValue } from "./domain";
import {
  DropdownField,
  DropdownFieldProps,
} from "../../Common/DropdownField/DropdownField";

type Props = Omit<
  DropdownFieldProps<BankIdentityFormValue>,
  "options" | "value" | "onChange" | "searchable"
> & {
  value: Option<BankIdentityFormValue>;
  onChange: (value: Option<BankIdentityFormValue>) => unknown;
};

export function BankIdentitiesDropdown(props: Props) {
  const formatMessage = useFormatMessage();

  const bankIdentities = pipe(
    useQuery(api.bankIdentities),
    ([result]) => result,
    remoteData.fold(constant([]), constant([]), identity)
  );

  const options: DropdownOption<BankIdentityFormValue>[] = bankIdentities.map(
    (bankIdentity: BankIdentity) => ({
      value: {
        id: bankIdentity.bankCode,
        name: bankIdentity.bankName,
      },
      label: formatMessage("StandardLoan.microTransaction.bankDropdownItem", {
        code: bankIdentity.bankCode,
        name: bankIdentity.bankName,
      }),
    })
  );

  const onChange = (value: Option<DropdownOption<BankIdentityFormValue>>) => {
    props.onChange(
      pipe(
        value,
        option.map(v => v.value)
      )
    );
  };

  function selectedDropdownOption(
    initialValue: Option<BankIdentityFormValue>,
    options: Array<DropdownOption<BankIdentityFormValue>>
  ) {
    return pipe(
      initialValue,
      option.chain(initialValue =>
        pipe(
          options,
          array.findFirst(
            bankIdentity => bankIdentity.value.id === initialValue.id
          )
        )
      )
    );
  }

  return (
    <DropdownField
      {...props}
      options={options}
      value={selectedDropdownOption(props.value, options)}
      onChange={onChange}
      searchable
    />
  );
}
