import { eq } from "fp-ts";
import { constFalse } from "fp-ts/function";
import * as t from "io-ts";
import { nonEmptyArray } from "io-ts-types/lib/nonEmptyArray";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { apiCall } from "../../APICall";
import { GenericLoanOffer, GenericLoanResponseOutput } from "../api";

export const UpsellOfferList = t.type(
  {
    applicationId: NonEmptyString,
    clientId: NonEmptyString,
    offers: nonEmptyArray(GenericLoanOffer),
  },
  "StandardLoanOfferList"
);

const CheckOfferStatus = t.keyof(
  {
    PENDING: true,
    RECEIVED: true,
    TIMEOUT: true,
  },
  "CheckOfferStatus"
);
export type CheckOfferStatus = t.TypeOf<typeof CheckOfferStatus>;

const CheckOffersOutput = t.type(
  {
    status: CheckOfferStatus,
  },
  "CheckOffersOutput"
);
export type CheckOffersOutput = t.TypeOf<typeof CheckOffersOutput>;

export const checkOffers = apiCall({
  path: ["packages", "loans", "standard-loan", "offer", "checkOffers"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CheckOffersOutput,
});

export const checkExpensesOffers = apiCall({
  path: ["packages", "loans", "standard-loan", "offer", "checkExpensesOffers"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CheckOffersOutput,
});

export const getCounterOffers = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "offer", "getCounterOffers"],
  inputCodec: t.void,
  outputCodec: UpsellOfferList,
});

export const getSelectedOffer = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "offer", "getSelectedOffer"],
  inputCodec: t.void,
  outputCodec: GenericLoanOffer,
});

export const getUpsellOffers = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "offer", "getUpsellOffers"],
  inputCodec: t.void,
  outputCodec: UpsellOfferList,
});

export const saveSelectedOffer = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "loans", "standard-loan", "offer", "saveSelectedOffer"],
  inputCodec: GenericLoanOffer,
  outputCodec: GenericLoanResponseOutput,
});

export const saveCustomerSelectedOffer = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "offer",
    "saveCustomerSelectedOffer",
  ],
  inputCodec: t.void,
  outputCodec: GenericLoanResponseOutput,
});
