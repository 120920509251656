import { Box, Loader } from "design-system";
import { pipe } from "fp-ts/function";
import { IO } from "fp-ts/IO";
import { Reader } from "fp-ts/Reader";
import * as offerListApi from "./api";
import { usePolling } from "../../useAPI";
import * as remoteData from "../../RemoteData";
import { MainContent } from "../../Common/MainContent";

type Props = {
  onApproved: IO<unknown>;
  onRejected: IO<unknown>;
  onError: IO<unknown>;
};

function foldOfferState<T>(matches: {
  onLoading: IO<T>;
  onReady: IO<T>;
  onTimeout: IO<T>;
}): Reader<offerListApi.CheckOfferStatus, T> {
  return type => {
    switch (type) {
      case "PENDING":
        return matches.onLoading();
      case "RECEIVED":
        return matches.onReady();
      case "TIMEOUT":
        return matches.onTimeout();
    }
  };
}

export function CheckExpensesOffers(props: Props) {
  const [checkOfferStatus] = usePolling(offerListApi.checkExpensesOffers, {
    intervalMS: 2000,
    shouldPollingContinue: ({ status }) => status === "PENDING",
  });

  const renderLoader = (
    <Box grow hAlignContent="center" vAlignContent="center">
      <Loader />
    </Box>
  );

  return (
    <MainContent>
      {pipe(
        checkOfferStatus,
        remoteData.fold(
          () => renderLoader,
          () => <>{props.onError}</>,
          ({ status }) =>
            pipe(
              status,
              foldOfferState({
                onLoading: () => renderLoader,
                onReady: () => <>{props.onApproved()}</>,
                onTimeout: () => <>{props.onApproved()}</>,
              })
            )
        )
      )}
    </MainContent>
  );
}
