import { Box, FeedbackBlock, Loader } from "design-system";
import { IO } from "fp-ts/IO";
import { constVoid, pipe } from "fp-ts/function";
import { Reader } from "fp-ts/Reader";
import { useFormatMessage } from "../../intl";
import { useAppContext } from "../../useAppContext";
import { option } from "fp-ts";
import { foldTenant } from "../../globalDomain";
import { useCheckDay, DayTypeInfo } from "../useCheckDay";
import { LFStatus } from "../api";
import { StandardLoanFlowType } from "../domain";

function foldDecision<T>(matches: {
  whenApproved: IO<T>;
  whenPending: IO<T>;
}): Reader<LFStatus, T> {
  return type => {
    switch (type) {
      case "Approved":
        return matches.whenApproved();
      case "Pending":
      case "Rejected":
        return matches.whenPending();
    }
  };
}

type Props = {
  lfStatus: LFStatus;
  isRLProductType: boolean;
  hasRefinancing: boolean;
  onGoToClientPage: IO<unknown>;
  flowType: StandardLoanFlowType;
};

export const SummaryExistingClient = (props: Props) => {
  const formatMessage = useFormatMessage();
  const { foldStatus } = useCheckDay();
  const {
    apiParameters: { tenant },
  } = useAppContext();

  const getFeedbackText = (dayInfo: DayTypeInfo) => {
    let title = formatMessage(
      "StandardLoan.Summary.ExistingClient.Approved.title"
    );
    let subheading = option.some(
      formatMessage("StandardLoan.Summary.ExistingClient.Approved.message")
    );

    pipe(
      props.lfStatus,
      foldDecision({
        whenApproved: () => {
          if (!dayInfo.isWorkingDay && dayInfo.status === "Ready") {
            title = formatMessage(
              "StandardLoan.Summary.ExistingClient.Approved.NonWorkingDays.title"
            );
            subheading = option.some(
              formatMessage(
                "StandardLoan.Summary.ExistingClient.Approved.NonWorkingDays.message"
              )
            );
          }
        },
        whenPending: () => {
          title = formatMessage(
            "StandardLoan.Summary.ExistingClient.Pending.title"
          );
          subheading = option.some(
            formatMessage("StandardLoan.Summary.ExistingClient.Pending.message")
          );
          foldTenant(
            tenant,
            () => {
              if (props.hasRefinancing && props.isRLProductType) {
                title = formatMessage(
                  "StandardLoan.Summary.ExistingClient.Pending.SK.title"
                );
                subheading = option.some(
                  formatMessage(
                    "StandardLoan.Summary.ExistingClient.Pending.SK.message"
                  )
                );
              }
            },
            constVoid
          );
        },
      })
    );

    return { title, subheading };
  };

  return foldStatus({
    whenLoading: () => (
      <Box hAlignContent="center" vAlignContent="center">
        <Loader />
      </Box>
    ),
    whenReady: info => {
      const { title, subheading } = getFeedbackText(info);
      return (
        <FeedbackBlock
          type="positive"
          size="large"
          heading={title}
          subheading={subheading}
          actions={[
            {
              variant: "primary",
              label:
                props.flowType === "PWSRemote"
                  ? formatMessage("StandardLoan.FinalDecision.gotoHomePage")
                  : formatMessage("StandardLoan.FinalDecision.gotoClientPage"),
              action: props.onGoToClientPage,
            },
          ]}
        />
      );
    },
  });
};
