import {
  Box,
  Form,
  FormRow,
  FormSection,
  RadioGroupField,
  Space,
  TextField,
  useForm,
  validators,
} from "design-system";
import { useFormatMessage } from "../../intl";
import { constant, constFalse, identity, pipe } from "fp-ts/function";
import { option, record, taskEither } from "fp-ts";
import { PaymentDestination, TransactionType } from "./domain";
import { useValidators } from "../../Common/useValidators";
import { Option } from "fp-ts/Option";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { useFormatPaymentDestination, useFormatTransactionType } from "./utils";
import { NextButton } from "../../Common/NextButton";
import { TransactionsInfo } from "../api";
import { useAppContext } from "../../useAppContext";
import { TransactionsInfo1, TransactionsInfo2 } from "../../KYC/domain";
import { Transactions2 } from "./Transactions2";

type PropsOld = {
  onNext: (transactions: TransactionsInfo) => unknown;
  transactionsInfo: Option<TransactionsInfo1>;
};

type PropsNew = {
  onNext: (transactions: TransactionsInfo) => unknown;
  transactionsInfo: Option<TransactionsInfo2>;
};

type Props = { transactionsInfo: Option<TransactionsInfo> } & (
  | Omit<PropsOld, "transactionsInfo">
  | Omit<PropsNew, "transactionsInfo">
);

type InitialValues = {
  paymentDestination: Option<PaymentDestination>;
  transactionType: Option<TransactionType>;
  paymentDestionationCountries: string;
  cashTransactionMotivation: string;
};

export function Transactions(props: Props) {
  const {
    config: { r6NewSironMapping: newSironMapping },
  } = useAppContext();

  return newSironMapping ? (
    <Transactions2
      {...props}
      initialTransactionsInfo={pipe(
        props.transactionsInfo,
        option.map(transactions => transactions as TransactionsInfo2)
      )}
    />
  ) : (
    <Transactions1
      {...props}
      transactionsInfo={pipe(
        props.transactionsInfo,
        option.map(transactions => transactions as TransactionsInfo1)
      )}
    />
  );
}

export function Transactions1(props: PropsOld) {
  const formatMessage = useFormatMessage();
  const getPaymentDestinationLabel = useFormatPaymentDestination();
  const getTransactionTypeLabel = useFormatTransactionType();
  const { nonBlankString, defined, maxLength } = useValidators();

  function shouldAskPaymentDestinationCountries(
    paymentDestination: Option<PaymentDestination>
  ) {
    return pipe(
      paymentDestination,
      option.exists(paymentDestination => paymentDestination === "OutsideEU")
    );
  }

  function shouldAskCashTransactionMotivation(
    transactionType: Option<TransactionType>
  ) {
    return pipe(
      transactionType,
      option.exists(transactionType => transactionType === "Cash")
    );
  }

  const initialValues = pipe(
    props.transactionsInfo,
    option.map(data => ({
      paymentDestination: option.some(data.paymentDestination),
      transactionType: option.some(data.transactionType),
      paymentDestionationCountries: pipe(
        data.paymentDestinationCountries,
        option.getOrElse(() => "")
      ),
      cashTransactionMotivation: pipe(
        data.cashTransactionMotivation,
        option.getOrElse(() => "")
      ),
    })),
    option.getOrElse(
      () =>
        ({
          paymentDestination: option.none,
          transactionType: option.none,
          paymentDestionationCountries: "",
          cashTransactionMotivation: "",
        } as InitialValues)
    )
  );

  const { values, fieldProps, handleSubmit } = useForm(
    {
      initialValues,
      fieldValidators: values => ({
        paymentDestination: defined(),
        transactionType: defined(),
        cashTransactionMotivation: shouldAskCashTransactionMotivation(
          values.transactionType
        )
          ? validators.inSequence(nonBlankString, maxLength(100))
          : undefined,
        paymentDestionationCountries: shouldAskPaymentDestinationCountries(
          values.paymentDestination
        )
          ? validators.inSequence(nonBlankString, maxLength(100))
          : undefined,
      }),
    },
    {
      onSubmit: ({
        paymentDestination,
        transactionType,
        cashTransactionMotivation,
        paymentDestionationCountries,
      }) =>
        taskEither.fromIO(() =>
          props.onNext({
            paymentDestination,
            paymentDestinationLabel: getPaymentDestinationLabel(
              paymentDestination
            ),
            paymentDestinationCountries: shouldAskPaymentDestinationCountries(
              option.some(paymentDestination)
            )
              ? option.some(paymentDestionationCountries)
              : option.none,
            transactionType,
            transactionTypeLabel: getTransactionTypeLabel(transactionType),
            cashTransactionMotivation: shouldAskCashTransactionMotivation(
              option.some(transactionType)
            )
              ? option.some(cashTransactionMotivation)
              : option.none,
          })
        ),
    }
  );

  const paymentDestinationOptions = pipe(
    PaymentDestination.keys,
    record.keys
  ) as NonEmptyArray<PaymentDestination>;

  const transactionTypeOptions = pipe(
    TransactionType.keys,
    record.keys
  ) as NonEmptyArray<TransactionType>;

  return (
    <>
      <Form>
        <FormSection
          heading={{
            title: formatMessage("StandardLoan.KYC.Transactions.description"),
          }}
        >
          <></>
          <FormRow type="full">
            <RadioGroupField
              {...fieldProps("paymentDestination")}
              variant="vertical"
              options={paymentDestinationOptions}
              label={formatMessage(
                "StandardLoan.KYC.Transactions.paymentDestinationLabel"
              )}
              optionKey={identity}
              isOptionDisabled={constFalse}
              renderOption={getPaymentDestinationLabel}
              renderOptionChildren={constant(option.none)}
            />
          </FormRow>
          {shouldAskPaymentDestinationCountries(values.paymentDestination) && (
            <FormRow type="full">
              <TextField
                {...fieldProps("paymentDestionationCountries")}
                label={formatMessage(
                  "KYC.Transactions.paymentDestinationCountriesLabel"
                )}
                placeholder={formatMessage(
                  "KYC.Transactions.paymentDestinationCountriesPlaceholder"
                )}
              />
            </FormRow>
          )}
          <FormRow type="full">
            <RadioGroupField
              {...fieldProps("transactionType")}
              variant="vertical"
              options={transactionTypeOptions}
              label={formatMessage(
                "StandardLoan.KYC.Transactions.transactionTypeLabel"
              )}
              optionKey={identity}
              isOptionDisabled={constFalse}
              renderOption={getTransactionTypeLabel}
              renderOptionChildren={constant(option.none)}
            />
          </FormRow>
          {shouldAskCashTransactionMotivation(values.transactionType) && (
            <FormRow type="full">
              <TextField
                {...fieldProps("cashTransactionMotivation")}
                label={formatMessage(
                  "KYC.Transactions.cashTransactionMotivationLabel"
                )}
                placeholder={formatMessage(
                  "KYC.Transactions.cashTransactionMotivationPlaceholder"
                )}
              />
            </FormRow>
          )}
        </FormSection>
      </Form>
      <Space units={10} />
      <Box hAlignContent="right">
        <NextButton action={handleSubmit} />
      </Box>
    </>
  );
}
