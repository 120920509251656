import { Box, Loader } from "design-system";
import { constNull, pipe } from "fp-ts/function";
import { OfferChoose } from "./OfferChoose";
import { Counteroffer } from "./Counteroffer";
import { IO } from "fp-ts/IO";
import { Reader } from "fp-ts/Reader";
import * as api from "../api";
import * as offerListApi from "./api";
import { usePolling } from "../../useAPI";
import * as remoteData from "../../RemoteData";
import { MainContent } from "../../Common/MainContent";

export type OfferType = "Approved" | "Counteroffer";

type Props = {
  offerType: OfferType;
  onContinue: Reader<api.GenericLoanResponseOutput, unknown>;
  onBack: IO<unknown>;
};

function foldOfferType<T>(matches: {
  whenApproved: IO<T>;
  whenCounteroffer: IO<T>;
}): Reader<OfferType, T> {
  return type => {
    switch (type) {
      case "Approved":
        return matches.whenApproved();
      case "Counteroffer":
        return matches.whenCounteroffer();
    }
  };
}

function foldOfferState<T>(matches: {
  onLoading: IO<T>;
  onReady: IO<T>;
  onTimeout: IO<T>;
}): Reader<offerListApi.CheckOfferStatus, T> {
  return type => {
    switch (type) {
      case "PENDING":
        return matches.onLoading();
      case "RECEIVED":
        return matches.onReady();
      case "TIMEOUT":
        return matches.onTimeout();
    }
  };
}

export function OfferList(props: Props) {
  const [checkOfferStatus] = usePolling(offerListApi.checkOffers, {
    intervalMS: 2000,
    shouldPollingContinue: ({ status }) => status === "PENDING",
  });

  const renderLoader = (
    <Box grow hAlignContent="center" vAlignContent="center">
      <Loader />
    </Box>
  );

  const renderOfferType = (
    offerState: Exclude<offerListApi.CheckOfferStatus, "PENDING">
  ) =>
    pipe(
      props.offerType,
      foldOfferType({
        whenApproved: () => (
          <OfferChoose
            onSelectOffer={props.onContinue}
            onBack={props.onBack}
            offerState={offerState}
          />
        ),
        whenCounteroffer: () => (
          <Counteroffer
            onSelectOffer={props.onContinue}
            onBack={props.onBack}
            offerState={offerState}
          />
        ),
      })
    );

  return (
    <MainContent>
      {pipe(
        checkOfferStatus,
        remoteData.fold(
          () => renderLoader,
          constNull,
          ({ status }) =>
            pipe(
              status,
              foldOfferState({
                onLoading: () => renderLoader,
                onReady: () => renderOfferType("RECEIVED"),
                onTimeout: () => renderOfferType("TIMEOUT"),
              })
            )
        )
      )}
    </MainContent>
  );
}
