import {
  AutocompleteInputField,
  DropdownOption,
  SuggestionsStatus,
} from "design-system";
import { option, taskEither } from "fp-ts";
import { constant, constFalse, pipe } from "fp-ts/function";
import { useMemo, useState } from "react";
import { useDebounceState } from "../../Common/useDebounceState";
import { useFormatMessage } from "../../intl";
import { useCommand } from "../../useAPI";
import { useRemoteData } from "../../useRemoteData";
import { Option } from "fp-ts/Option";
import * as api from "../api";
import * as remoteData from "../../RemoteData";
import * as classes from "./BrokerEmailField.treat";

type Props = Omit<
  React.ComponentProps<typeof AutocompleteInputField>,
  "suggestionsStatus" | "onSelectSuggestion" | "loadingMessage"
> & {
  onSelectSuggestion: (value: string) => unknown;
  queryString?: string;
};

const MIN_BROKER_EMAIL_SEARCH_TERM_LENGTH = 2;

export function BrokerEmailField(props: Props) {
  const formatMessage = useFormatMessage();
  const fetchBrokerSuggestions = useCommand(api.getBrokers, {
    skipTracking: true,
  });

  const termEquals = (
    previousTerm: Option<string>,
    currentTerm: string
  ): boolean => {
    return pipe(
      previousTerm,
      option.fold(constFalse, term => term === currentTerm)
    );
  };

  const [lastSelectedTerm, setLastSelectedTerm] = useState<Option<string>>(
    option.none
  );

  const queryTerms = useDebounceState(
    props.queryString !== undefined ? props.queryString : props.value,
    500
  );

  const brokerEmailSuggestions = useRemoteData(
    useMemo(
      () =>
        queryTerms.length >= MIN_BROKER_EMAIL_SEARCH_TERM_LENGTH &&
        !termEquals(lastSelectedTerm, queryTerms)
          ? pipe(
              fetchBrokerSuggestions({
                partialEmail: queryTerms,
              }),
              taskEither.map(option.some)
            )
          : taskEither.right(option.none),
      [queryTerms]
    )
  );

  return (
    <AutocompleteInputField
      className={classes.emailField}
      showNativeTooltip
      {...props}
      suggestionsStatus={autocompleteInputStatusFromRemoteData(
        pipe(
          brokerEmailSuggestions,
          remoteData.map(option.getOrElse(constant<api.GetBrokersOutput>([])))
        ),
        suggestion => ({
          value: suggestion,
          label: formatMessage("StandardLoan.Select3P.brokerEmailSuggestion", {
            email: suggestion,
          }),
        })
      )}
      onSelectSuggestion={s => {
        const selectedValue = s.value as string;
        props.onChange(selectedValue);
        setLastSelectedTerm(option.some(selectedValue));
        props.onSelectSuggestion(selectedValue);
      }}
      loadingMessage={formatMessage("LoadingEllipsis")}
    />
  );
}

function autocompleteInputStatusFromRemoteData<I, O>(
  query: remoteData.RemoteData<unknown, I[]>,
  suggestionsMap: (suggestion: I) => DropdownOption<O>
): SuggestionsStatus<O> {
  return pipe(
    query,
    remoteData.fold(
      constant({
        status: "loading",
      } as SuggestionsStatus<O>),
      constant({ status: "ready", suggestions: [] }),
      (suggestions, isLoading) =>
        isLoading
          ? {
              status: "loading",
            }
          : {
              status: "ready",
              suggestions: suggestions.map(suggestionsMap),
            }
    )
  );
}
