import { Option } from "fp-ts/Option";
import {
  Box,
  ContentRow,
  FlowAccordion,
  FlowAccordionItem,
  Space,
  useIsMobileLayout,
} from "design-system";
import { useState } from "react";
import { useFormatMessage } from "../../intl";
import { PageHeading } from "../../Common/PageHeading/PageHeading";
import { PersonalAndTaxInformation } from "./PersonalAndTaxInformation";
import { Transactions } from "./Transactions";
import { option, taskEither } from "fp-ts";
import { constVoid, flow, pipe } from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import * as api from "../api";
import { useCommand } from "../../useAPI";
import { IO } from "fp-ts/IO";
import { BackButton } from "../../Common/BackButton/BackButton";
import { StandardLoanFlowType } from "../domain";
import { MainContent } from "../../Common/MainContent";
import { IncomeSourceType } from "../IncomeForm/domain";

type Props = {
  onNext: (needsMicropayment: boolean) => unknown;
  onBack: IO<unknown>;
  mainIncomeSource: IncomeSourceType;
  flowType: StandardLoanFlowType;
  transactionsInfo: Option<api.TransactionsInfo>;
  existingClient: boolean;
};

export function KYC(props: Props) {
  const formatMessage = useFormatMessage();
  const [activeItem, setActiveItem] = useState<Option<number>>(
    option.some(option.isSome(props.transactionsInfo) ? 1 : 0)
  );
  const KYCSubmitCommand = useCommand(api.submitKYC);
  const [transactions, setTransactions] = useState<
    Option<api.TransactionsInfo>
  >(props.transactionsInfo);

  const transactionsStep: FlowAccordionItem = {
    title: formatMessage("StandardLoan.KYC.Transactions.title"),
    content: ({ down }) => (
      <Transactions
        transactionsInfo={props.transactionsInfo}
        onNext={flow(option.some, setTransactions, down)}
      />
    ),
  };

  const personalAndTaxInfoStep: FlowAccordionItem = {
    title: formatMessage("StandardLoan.KYC.PersonalAndTaxInformation.title"),
    content: () => (
      <PersonalAndTaxInformation
        initialPersonalAndTaxInfo={option.none}
        incomeSource={props.mainIncomeSource}
        existingClient={props.existingClient}
        onNext={personalAndTaxInfo =>
          pipe(
            transactions,
            option.fold(
              () => taskEither.fromIO(constVoid),
              transactions =>
                pipe(
                  KYCSubmitCommand({
                    answers: {
                      transactionsInfo: transactions,
                      personalAndTaxInfo: personalAndTaxInfo,
                    },
                  }),
                  taskEither.chain(({ needsMicropayment }) =>
                    taskEither.fromIO(() => props.onNext(needsMicropayment))
                  )
                )
            )
          )
        }
      />
    ),
  };

  const itemsAccordion: NonEmptyArray<FlowAccordionItem> = [
    transactionsStep,
    personalAndTaxInfoStep,
  ];

  const isMobileLayout = useIsMobileLayout();

  return (
    <MainContent>
      <Box grow shrink column>
        <PageHeading
          title={formatMessage("StandardLoan.MainContent.kycTitle")}
          hideOnMobile
        />
        <FlowAccordion
          items={itemsAccordion}
          value={activeItem}
          onChange={setActiveItem}
        />
        <Space units={10} />
        <ContentRow type="lateral-margins">
          <Box grow column={isMobileLayout}>
            <BackButton action={props.onBack} />
          </Box>
        </ContentRow>
      </Box>
    </MainContent>
  );
}
