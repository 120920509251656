import {
  ActionButtonGroup,
  Body,
  Box,
  ContentRow,
  FeedbackBlock,
  Stack,
  useIsMobileLayout,
  Banner,
  Loader,
} from "design-system";
import { option, readerTaskEither, taskEither } from "fp-ts";
import { Option } from "fp-ts/Option";
import { IO } from "fp-ts/IO";
import { constFalse, constVoid, pipe } from "fp-ts/function";
import { useFormatMessage, useFormatPercentageLike } from "../intl";
import { RestoredRecapData } from "./domain";
import { MainContent } from "../Common/MainContent";
import RestrictParallelFlowsWrapper from "./RestrictParallelFlowsWrapper";
import { Container } from "../Common/MainContent/grid/Container";
import { ParallelFlowParameters } from "./api";
import { useEffect } from "react";
import { ApplicationStatus } from "../Common/ProcessStoreList/api";
import { palette } from "design-system/lib/styleConstants";

type Props = {
  onExit: IO<unknown>;
  onBack: () => unknown;
  onNotRestore: taskEither.TaskEither<unknown, unknown>;
  parallelFlowParameters: ParallelFlowParameters;
  isCredentialsAndSigningRemote?: boolean;
} & (
  | {
      isErrorFetchingRestoredData: false;
      restoredData: RestoredRecapData;
      applicationStatus: Option<ApplicationStatus>;
      onRestore: (
        restoreWithDialog: boolean
      ) => taskEither.TaskEither<unknown, unknown>;
      transitionToMasterCard: readerTaskEither.ReaderTaskEither<
        void,
        unknown,
        unknown
      >;
      cardProviderChange: boolean;
    }
  | {
      isErrorFetchingRestoredData: true;
    }
);

export function RestoreDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const formatPercentageLike = useFormatPercentageLike();
  const isMobileLayout = useIsMobileLayout();

  const isApplicationSigned = (applicationStatus: Option<ApplicationStatus>) =>
    pipe(
      applicationStatus,
      option.fold(constFalse, status => "SIGNED" === status)
    );

  useEffect(() => {
    if (
      !props.isErrorFetchingRestoredData &&
      (props.isCredentialsAndSigningRemote ||
        props.restoredData.feStep === "AUTHORIZE_PUSH" ||
        isApplicationSigned(props.applicationStatus))
    ) {
      props.onRestore(false)();
    }
  }, [props.isErrorFetchingRestoredData]);

  const renderLoanDetails = () =>
    props.isErrorFetchingRestoredData ? (
      <Banner
        content={formatMessage("StandardLoan.RestoreModal.errorRestoringData")}
        title={option.none}
        actions={option.none}
        type="error"
      />
    ) : (
      <Stack
        column
        units={8}
        hAlignContent={isMobileLayout ? "left" : "center"}
        shrink
      >
        <Body size="medium" weight="regular">
          {formatMessage("StandardLoan.RestoreModalHeading")}
        </Body>
        <Stack units={isMobileLayout ? 8 : 10} column={isMobileLayout}>
          <Stack column units={8}>
            <Box column>
              <Body size="small" weight="regular">
                {formatMessage("StandardLoan.recapLoanAmountLabel")}
              </Body>
              <Body size="small" weight="regular">
                {formatMessage(
                  "StandardLoan.recapLoanAmountValue",
                  props.restoredData.loanAmount
                )}
              </Body>
            </Box>
            <Box column>
              <Body size="small" weight="regular">
                {formatMessage("StandardLoan.detailsModalInstalmentLabel")}
              </Body>
              <Body size="small" weight="regular">
                {formatMessage(
                  "StandardLoan.recapLoanAmountValue",
                  props.restoredData.instalment
                )}
              </Body>
            </Box>
          </Stack>
          <Stack column units={8}>
            <Box column>
              <Body size="small" weight="regular">
                {formatMessage("StandardLoan.recapTenorLabel")}
              </Body>
              <Body size="small" weight="regular">
                {formatMessage("StandardLoan.recapTenorValue", {
                  tenor: props.restoredData.tenor,
                })}
              </Body>
            </Box>
            <Box column>
              <Body size="small" weight="regular">
                {formatMessage("StandardLoan.recapInterestRateLabel")}
              </Body>
              <Body size="small" weight="regular">
                {formatMessage("StandardLoan.recapInterestRateValue", {
                  interestRate: formatPercentageLike(
                    props.restoredData.interestRate
                  ),
                })}
              </Body>
            </Box>
          </Stack>
        </Stack>
        {props.cardProviderChange && (
          <Body size="small" weight="regular" color={palette.warning500}>
            {formatMessage(
              "StandardLoan.RestoreModal.transitionToMastercardWarning"
            )}
          </Body>
        )}
      </Stack>
    );

  const restrictParallelFlowsWrapper = (
    <RestrictParallelFlowsWrapper
      onBack={props.onBack}
      onExit={props.onExit}
      continueNewApp={
        props.isErrorFetchingRestoredData
          ? props.onNotRestore
          : pipe(
              props.transitionToMasterCard(),
              taskEither.chain(() => props.onRestore(true))
            )
      }
      title={formatMessage("StandardLoan.RestoreModalHeading")}
      hasCAFlowInProgress={props.parallelFlowParameters.hasCAFlowInProgress}
      hasCAFlowSentToBO={props.parallelFlowParameters.hasCAFlowSentToBO}
      hasCFFlowSentToBO={props.parallelFlowParameters.hasCFFlowSentToBO}
      isFromClientProfile={true}
      date={props.parallelFlowParameters.date}
      existingApplicationId={props.parallelFlowParameters.existingApplicationId}
      onReturnToPrev={constVoid}
    />
  );

  const mainContent = (
    <MainContent>
      <ContentRow type="lateral-margins">
        {!props.isErrorFetchingRestoredData &&
        (props.isCredentialsAndSigningRemote ||
          props.restoredData.feStep === "AUTHORIZE_PUSH" ||
          isApplicationSigned(props.applicationStatus)) ? (
          <Box hAlignContent="center">
            <Loader />
          </Box>
        ) : (
          <Box column shrink>
            <FeedbackBlock
              size="medium"
              type="info"
              heading={formatMessage("StandardLoan.RestoreModalTitle")}
              subheading={option.some(
                formatMessage("StandardLoan.RestoreModalFooter")
              )}
              actions={[]}
            />
            <Stack column units={8}>
              {renderLoanDetails()}
              <ActionButtonGroup
                hAlignContent="center"
                actions={[
                  {
                    variant: "loadingSecondary",
                    labels: {
                      normal: formatMessage(
                        "PreApprovedLoanSale.RestoreModalButtonNo"
                      ),
                      success: formatMessage(
                        "PreApprovedLoanSale.RestoreModalButtonNo"
                      ),
                      loading: formatMessage(
                        "PreApprovedLoanSale.RestoreModalButtonNo"
                      ),
                      error: formatMessage(
                        "PreApprovedLoanSale.RestoreModalButtonNo"
                      ),
                    },
                    action: props.onNotRestore,
                  },
                  {
                    variant: "primary",
                    label: formatMessage(
                      "PreApprovedLoanSale.RestoreModalButtonYes"
                    ),
                    disabled: props.isErrorFetchingRestoredData,
                    action: props.isErrorFetchingRestoredData
                      ? constVoid
                      : pipe(
                          props.cardProviderChange
                            ? props.transitionToMasterCard()
                            : taskEither.fromIO(constVoid),
                          taskEither.chain(() => props.onRestore(true))
                        ),
                  },
                ]}
              />
            </Stack>
          </Box>
        )}
      </ContentRow>
    </MainContent>
  );

  const shouldDisplayRestrictParallelFlowsWrapper =
    props.parallelFlowParameters.hasCFFlowSentToBO ||
    props.parallelFlowParameters.hasCAFlowSentToBO ||
    props.parallelFlowParameters.hasCAFlowInProgress;

  return (
    <Container stepper={option.none}>
      {shouldDisplayRestrictParallelFlowsWrapper
        ? restrictParallelFlowsWrapper
        : mainContent}
    </Container>
  );
}
