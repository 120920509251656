import { eq, option } from "fp-ts";
import { constFalse } from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import * as t from "io-ts";
import { nonEmptyArray } from "io-ts-types/lib/nonEmptyArray";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { apiCall } from "../../APICall";
import { Currency, optionFromUndefined } from "../../globalDomain";
import { LocaleKey } from "../../intl";
import { PersonalDataFromOutput } from "../Confirmation/domain";
import {
  ExternalLoansListRework,
  IncomeAndPersonalData,
  InternalLoansListRework,
} from "../api";
import {
  AllowanceInfo,
  CompanyInfo,
  ContractInfo,
  IncomeInfoNoSource,
  IncomeSourceType,
  SpecialIncomeSourceType,
} from "../IncomeForm/domain";
import {
  AddressOCR,
  AddressWrite,
  PersonalDataOCR,
  PersonalDocumentOCR,
  PersonalInfoError,
} from "../../IdUpload/domain";
import { withFallback } from "io-ts-types/lib/withFallback";
import { DocumentType } from "../../UploadDocuments/domain";
import { Option } from "fp-ts/Option";
import { LocalizedString } from "design-system";

const ReworkStep = t.keyof(
  {
    ACCOUNTS_FOR_REFINANCING: true,
    ADDITIONAL_INCOME: true,
    AUTHORIZATION_PAGE: true,
    INCOME_AND_PERSONAL_DATA: true,
    PERSONAL_DATA: true,
    UPLOAD_DOCUMENTS: true,
  },
  "ReworkStep"
);

export type ReworkStep = t.TypeOf<typeof ReworkStep>;

const ReworkStepsOutput = t.type(
  {
    needContractsUpdate: t.boolean,
    steps: nonEmptyArray(ReworkStep),
    reworkAll: t.boolean,
  },
  "ReworkStepsOutput"
);

export type ReworkStepsOutput = t.TypeOf<typeof ReworkStepsOutput>;

export const reworkDetails = apiCall({
  path: ["packages", "loans", "standard-loan", "rework", "reworkDetails"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ReworkStepsOutput,
});

const ReworkActionCategory = t.keyof(
  {
    PERSONAL_ID: true,
    PERSONAL_DATA: true,
    BONITA: true,
    CONTRACT: true,
    DOCUMENT: true,
  },
  "ReworkActionCategory"
);

export type ReworkActionCategory = t.TypeOf<typeof ReworkActionCategory>;

const StepCommentsInput = t.type(
  {
    reworkCategories: t.array(ReworkActionCategory),
  },
  "StepCommentsInput"
);

export type StepCommentsInput = t.TypeOf<typeof StepCommentsInput>;

const StepCommentsOutput = t.type(
  {
    stepComments: optionFromUndefined(t.array(t.string)),
  },
  "StepCommentsOutput"
);

export type StepCommentsOutput = t.TypeOf<typeof StepCommentsOutput>;

export const stepComments = apiCall({
  path: ["packages", "loans", "standard-loan", "rework", "stepComments"],
  inputCodec: StepCommentsInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: StepCommentsOutput,
});

const ReworkIncomeOutputBase = t.type(
  {
    allowanceInfo: optionFromUndefined(AllowanceInfo),
    companyInfo: optionFromUndefined(CompanyInfo),
    contractInfo: optionFromUndefined(ContractInfo),
  },
  "ReworkIncomeOutputBase"
);

const ReworkIncomeSourceInfo = t.type(
  {
    incomeSource: optionFromUndefined(IncomeSourceType),
    specialTypeOfIncome: optionFromUndefined(SpecialIncomeSourceType),
  },
  "ReworkIncomeSourceInfo"
);

const ReworkIncomeInfo = t.intersection(
  [IncomeInfoNoSource, ReworkIncomeSourceInfo],
  "ReworkIncomeInfo"
);

export const ReworkIncomeOutput = t.intersection(
  [
    ReworkIncomeOutputBase,
    t.type({ incomeInfo: optionFromUndefined(ReworkIncomeInfo) }),
  ],
  "ReworkIncomeOutput"
);

export type ReworkIncomeOutput = t.TypeOf<typeof ReworkIncomeOutput>;

export const ReworkIncomeNoSourceOutput = t.intersection(
  [
    ReworkIncomeOutputBase,
    t.type({ incomeInfo: optionFromUndefined(IncomeInfoNoSource) }),
  ],
  "ReworkIncomeNoSourceOutput"
);

export type ReworkIncomeNoSourceOutput = t.TypeOf<
  typeof ReworkIncomeNoSourceOutput
>;

const ReworkIncomeAndPersonalData = t.type({
  income: optionFromUndefined(ReworkIncomeOutput),
  personalData: optionFromUndefined(
    t.type({
      personalData: PersonalDataFromOutput,
    })
  ),
});

export type ReworkIncomeAndPersonalData = t.TypeOf<
  typeof ReworkIncomeAndPersonalData
>;

const ReworkAdditionalIncomeMap = t.record(
  t.string,
  ReworkIncomeOutput,
  "ReworkAdditionalIncomeMap"
);

export type ReworkAdditionalIncomeMap = t.TypeOf<
  typeof ReworkAdditionalIncomeMap
>;

const ReworkAccountsForRefinancingOutput = t.type(
  {
    accountNumber: optionFromUndefined(NonEmptyString),
    accountCurrency: optionFromUndefined(Currency),
    internalLoans: optionFromUndefined(InternalLoansListRework),
    externalLoans: optionFromUndefined(ExternalLoansListRework),
  },
  "ReworkAccountsForRefinancingOutput"
);

export type ReworkAccountsForRefinancingOutput = t.TypeOf<
  typeof ReworkAccountsForRefinancingOutput
>;

export const ClientDataReworkOCR = t.type({
  documentType: optionFromUndefined(DocumentType),
  personalData: optionFromUndefined(PersonalDataOCR),
  documentDetails: optionFromUndefined(PersonalDocumentOCR),
  permanentAddress: optionFromUndefined(AddressOCR),
  addressSuggestions: optionFromUndefined(nonEmptyArray(AddressWrite)),
  validationErrors: withFallback(
    optionFromUndefined(PersonalInfoError),
    option.none
  ),
});
export type ClientDataReworkOCR = t.TypeOf<typeof ClientDataReworkOCR>;

export type ExtractedDataReworkResult = {
  documentType: Option<DocumentType>;
  additionalDocumentType: Option<DocumentType>;
  personalData: Option<PersonalDataOCR>;
  documentDetails: Option<PersonalDocumentOCR>;
  additionalDocumentDetails: Option<PersonalDocumentOCR>;
  permanentAddress: Option<AddressOCR>;
  validationErrors: Option<PersonalInfoError>;
  addressSuggestions: Option<NonEmptyArray<AddressWrite>>;
};

const ReworkOldValuesOutput = t.type(
  {
    accountsForRefinancingData: optionFromUndefined(
      ReworkAccountsForRefinancingOutput
    ),
    additionalIncomeMap: optionFromUndefined(ReworkAdditionalIncomeMap),
    // allPreApprovedLimitsResponse: optionFromNullable(TLAndCLLimits),
    // genericLoanResponse: GenericLoanResponseOutput,
    // personalDataResponse: PersonalDataOutput,
    incomeAndPersonalData: optionFromUndefined(ReworkIncomeAndPersonalData),
    //microTransactionData: optionFromUndefined(MicroTransactionDataOutput),
    //standardLoanKycRequest: optionFromUndefined(StandardLoanKycRequest),
    //startStandardLoanApplicationRequest: StartLoanApplicationInput,
    //creditBureauStatusResponse: CreditBureauStatus,
    //proceedNextResponse: optionFromUndefined(OfferTypeOutput),
    personalDataConfirmation: optionFromUndefined(
      t.type({
        primary: optionFromUndefined(ClientDataReworkOCR),
        secondary: optionFromUndefined(ClientDataReworkOCR),
      })
    ),
  },
  "ReworkOldValuesOutput"
);

export type ReworkOldValuesOutput = t.TypeOf<typeof ReworkOldValuesOutput>;

export const getReworkOldValues = apiCall({
  path: ["packages", "loans", "standard-loan", "rework", "oldValues"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ReworkOldValuesOutput,
});

export type ReworkChangesItemData = {
  variant: "item";
  label: LocaleKey;
} & (
  | {
      type: "string";
      oldValue: Option<string | LocalizedString>;
      newValue: Option<string | LocalizedString>;
    }
  | {
      type: "money";
      oldValue: Option<number>;
      newValue: Option<number>;
    }
  | {
      type: "date";
      oldValue: Option<Date>;
      newValue: Option<Date>;
    }
  | {
      type: "boolean";
      oldValue: Option<boolean>;
      newValue: Option<boolean>;
    }
);

export type ReworkChangesItem = { variant: "divider" } | ReworkChangesItemData;

export type ReworkChanges = NonEmptyArray<ReworkChangesItem>;

export type IncomeAndPersonalDataRework = {
  oldValues: ReworkOldValuesOutput;
  newValues: IncomeAndPersonalData;
};
