import {
  Stack,
  Loader,
  Box,
  FeedbackBlock,
  useIsMobileLayout,
} from "design-system";
import { useFormatMessage } from "../../intl";
import { OfferChooseCard } from "./OfferChooseCard";
import * as offerListApi from "./api";
import * as standardLoanApi from "../api";
import { useQuery } from "../../useAPI";
import { constNull, pipe } from "fp-ts/function";
import { array, boolean, option } from "fp-ts";
import * as remoteData from "../../RemoteData";
import { BackButton } from "../../Common/BackButton/BackButton";
import { IO } from "fp-ts/IO";
import { Reader } from "fp-ts/Reader";
import * as classes from "./OfferChoose.treat";
import cx from "classnames";

type Props = {
  onSelectOffer: Reader<standardLoanApi.GenericLoanResponseOutput, unknown>;
  onBack: IO<unknown>;
  offerState: Exclude<offerListApi.CheckOfferStatus, "PENDING">;
};

type ChildProps = {
  onSelectOffer: Reader<standardLoanApi.GenericLoanResponseOutput, unknown>;
  isMobileLayout: boolean;
};

export function SelectedOffer(props: ChildProps) {
  const [selectedOffer] = useQuery(offerListApi.getSelectedOffer);

  return pipe(
    selectedOffer,
    remoteData.fold(
      () => (
        <Box grow hAlignContent="center">
          <Loader />
        </Box>
      ),
      constNull,
      offer => (
        <Box hAlignContent="center" column={props.isMobileLayout}>
          <OfferChooseCard
            onComplete={props.onSelectOffer}
            offer={offer}
            selected
            expanded={true}
          />
        </Box>
      )
    )
  );
}

export function UpsellOffers(props: ChildProps) {
  const [upsellOffers] = useQuery(offerListApi.getUpsellOffers);

  return pipe(
    upsellOffers,
    remoteData.fold(constNull, constNull, ({ offers }) => (
      <Stack units={8} hAlignContent="center" column={props.isMobileLayout}>
        {pipe(
          offers,
          array.map(offer => (
            <OfferChooseCard
              onComplete={props.onSelectOffer}
              offer={offer}
              expanded={true}
            />
          ))
        )}
      </Stack>
    ))
  );
}

export function OfferChoose(props: Props) {
  const formatMessage = useFormatMessage();

  const isMobileLayout = useIsMobileLayout();

  return (
    <Stack
      column
      units={10}
      className={cx(isMobileLayout && classes.containerMobile)}
    >
      <FeedbackBlock
        size="large"
        type="positive"
        heading={formatMessage("StandardLoan.OfferApproved.ChooseOffer.title")}
        subheading={option.some(
          formatMessage("StandardLoan.OfferApproved.ChooseOffer.subtitle")
        )}
        actions={[]}
      />
      {pipe(
        props.offerState === "RECEIVED",
        boolean.fold(
          () => (
            <SelectedOffer
              isMobileLayout={isMobileLayout}
              onSelectOffer={props.onSelectOffer}
            />
          ),
          () => (
            <UpsellOffers
              isMobileLayout={isMobileLayout}
              onSelectOffer={props.onSelectOffer}
            />
          )
        )
      )}
      <Box grow column={isMobileLayout}>
        <BackButton action={props.onBack} />
      </Box>
    </Stack>
  );
}
