import { option, taskEither } from "fp-ts";
import { constVoid, pipe } from "fp-ts/function";
import {
  ErrorBanner,
  FeedbackBlock,
  LocalizedString,
  unsafeLocalizedString,
} from "design-system";
import { MainContent } from "../Common/MainContent";
import { useFormatMessage } from "../intl";
import { Container } from "../Common/MainContent/grid/Container";
import { useIsInPersonChannel } from "../useChannel";
import { useAppContext } from "../useAppContext";
import moment from "moment";
import { Option } from "fp-ts/Option";
import { useState } from "react";
import { useCommand } from "../useAPI";
import { IO } from "fp-ts/IO";
import * as api from "./api";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

type Props = {
  onBack: () => unknown;
  onExit: IO<unknown>;
  continueNewApp: () => unknown;
  title: LocalizedString;
  hasCAFlowInProgress: boolean;
  hasCAFlowSentToBO: boolean;
  hasCFFlowSentToBO: boolean;
  isFromClientProfile: boolean;
  date: Option<number>;
  existingApplicationId: Option<NonEmptyString>;
  onReturnToPrev: () => unknown;
};

export default function RestrictParallelFlowsWrapper(props: Props) {
  return (
    <MainContent>
      <RestrictParallelFlowsWrapperInner {...props} />
    </MainContent>
  );
}

function RestrictParallelFlowsWrapperInner(props: Props) {
  const formatMessage = useFormatMessage();
  const isInPerson = useIsInPersonChannel();
  const { config } = useAppContext();
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(
    false
  );

  const applicationType = props.hasCFFlowSentToBO
    ? formatMessage("Applications.ProductType.CF.title")
    : props.hasCAFlowSentToBO || props.hasCAFlowInProgress
    ? formatMessage("Applications.ProductType.Ukonto.title")
    : "";
  const existingApplicationDate = pipe(
    props.date,
    option.fold(
      () => "",
      value => unsafeLocalizedString("" + moment(value).format("DD/MM/YYYY"))
    )
  );

  const inPersonFlow = (
    <FeedbackBlock
      type="negative"
      size="large"
      heading={formatMessage(
        "BankerLanding.anotherApplicationAlreadyInProgress.title"
      )}
      subheading={option.some(
        formatMessage(
          "BankerLanding.anotherApplicationAlreadyInProgress.subtitle",
          { product: applicationType, date: existingApplicationDate }
        )
      )}
      actions={[
        {
          variant: "primary",
          label: formatMessage(
            "BankerLanding.anotherApplicationAlreadyInProgress.confirmButtonLabel"
          ),
          action: props.onExit,
        },
      ]}
    />
  );

  const remoteFlow = (
    <FeedbackBlock
      type="negative"
      size="large"
      heading={formatMessage(
        "BankerLanding.anotherApplicationAlreadyInProgress.title"
      )}
      subheading={option.some(
        formatMessage("BankerLanding.remoteAppAlreadyInProgress.subtitle", {
          product: applicationType,
          date: existingApplicationDate,
        })
      )}
      actions={[
        {
          variant: "primary",
          label: formatMessage(
            "BankerLanding.anotherApplicationAlreadyInProgress.confirmButtonLabel"
          ),
          action: props.isFromClientProfile
            ? props.onExit
            : () => (window.location.href = config.PWSURL),
        },
      ]}
    />
  );

  const handleCancelExistingApplication = () => {
    setShowDeletionConfirmation(true);
  };

  const remoteFlowCAInProgress = (
    <FeedbackBlock
      type="negative"
      size="large"
      heading={formatMessage(
        "BankerLanding.anotherApplicationAlreadyInProgress.title"
      )}
      subheading={option.some(
        formatMessage("BankerLanding.remoteFlowAnotherAppInProgress.subtitle", {
          product: applicationType,
          date: existingApplicationDate,
        })
      )}
      actions={[
        {
          variant: "secondary",
          label: formatMessage("BankerLanding.cancelMyExistingApplication"),
          action: () => handleCancelExistingApplication(),
        },
        {
          variant: "primary",
          label: formatMessage(
            "BankerLanding.anotherApplicationAlreadyInProgress.confirmButtonLabel"
          ),
          action: props.isFromClientProfile
            ? props.onExit
            : () => (window.location.href = config.PWSURL),
        },
      ]}
    />
  );

  return (
    <Container stepper={option.none}>
      {!showDeletionConfirmation ? (
        props.hasCAFlowSentToBO || props.hasCFFlowSentToBO ? (
          isInPerson ? (
            inPersonFlow
          ) : (
            remoteFlow
          )
        ) : props.hasCAFlowInProgress ? (
          isInPerson ? (
            inPersonFlow
          ) : (
            remoteFlowCAInProgress
          )
        ) : (
          <></>
        )
      ) : (
        <OldApplicationDeletionConfirmation
          {...props}
          onReturnToPrev={() => setShowDeletionConfirmation(false)}
        />
      )}
    </Container>
  );
}

function OldApplicationDeletionConfirmation(props: Props) {
  const formatMessage = useFormatMessage();
  const deleteApplication = useCommand(api.deleteExistingApplication);
  const [
    showAppSuccessfullyCanceled,
    setShowAppSuccessfullyCanceled,
  ] = useState(false);
  const [showErrorBanner, setShowErrorBanner] = useState(false);

  const handleDeleteApplication = (props: Props) => {
    pipe(
      props.existingApplicationId,
      option.fold(constVoid, appId =>
        pipe(
          deleteApplication({ processId: appId }),
          taskEither.mapLeft(
            taskEither.fromIO(() => {
              setShowErrorBanner(true);
            })
          ),
          taskEither.chain(() =>
            taskEither.fromIO(() => setShowAppSuccessfullyCanceled(true))
          )
        )()
      )
    );
  };

  const applicationType = props.hasCFFlowSentToBO
    ? formatMessage("Applications.ProductType.CF.title")
    : props.hasCAFlowSentToBO || props.hasCAFlowInProgress
    ? formatMessage("Applications.ProductType.Ukonto.title")
    : "";
  const existingApplicationDate = pipe(
    props.date,
    option.fold(
      () => "",
      value => unsafeLocalizedString("" + moment(value).format("DD/MM/YYYY"))
    )
  );

  const inPersonFlow = (
    <FeedbackBlock
      type="negative"
      size="large"
      heading={formatMessage(
        "BankerLanding.anotherApplicationAlreadyInProgress.confirmAction"
      )}
      subheading={option.some(
        formatMessage(
          "BankerLanding.anotherApplicationAlreadyInProgress.confirmActionQuestion",
          { product: applicationType, date: existingApplicationDate }
        )
      )}
      actions={[
        {
          variant: "secondary",
          label: formatMessage(
            "BankerLanding.anotherApplicationAlreadyInProgress.noDontCancelIt"
          ),
          action: props.onReturnToPrev,
        },
        {
          variant: "primary",
          label: formatMessage(
            "BankerLanding.anotherApplicationAlreadyInProgress.yesCancelIt"
          ),
          action: () => handleDeleteApplication(props),
        },
      ]}
    />
  );

  const appSuccessfullyCanceled = (
    <FeedbackBlock
      type="positive"
      size="large"
      heading={formatMessage(
        "BankerLanding.anotherApplicationAlreadyInProgress.appCanceled"
      )}
      subheading={option.none}
      actions={[
        {
          variant: "primary",
          label: formatMessage(
            "BankerLanding.anotherApplicationAlreadyInProgress.continueApp"
          ),
          action: props.continueNewApp,
        },
      ]}
    />
  );

  const errorContent = <ErrorBanner children={formatMessage("GenericError")} />;

  return (
    <Container stepper={option.none}>
      {showErrorBanner
        ? errorContent
        : showAppSuccessfullyCanceled
        ? appSuccessfullyCanceled
        : inPersonFlow}
    </Container>
  );
}
