import {
  Box,
  Card,
  ComputedFieldProps,
  Form,
  FormRow,
  FormSection,
  ReadOnlyField,
  TextField,
  useLoadingStatusRead,
} from "design-system";
import { CreditorData, ExternalLoanItemRework } from "../api";
import { ExternalLiabilityFormModel, getTypeOfProductLabel } from "../domain";
import { CreditorAutocompleteField } from "./CreditorAutocompleteField";
import { ReadOnlyMoneyAmountField } from "../../Common/ReadOnlyMoneyAmountField/ReadOnlyMoneyAmountField";
import { LocaleKey, useFormatMessage } from "../../intl";
import { pipe } from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { Option } from "fp-ts/Option";
import { boolean, option } from "fp-ts";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { CreditorConfig } from "./ExternalLiabilitiesEditForm";
import { MaskedCreditCardField } from "./MaskedCreditCardField";
import { Reader } from "fp-ts/Reader";
import { useReworkComparator } from "../Rework/useReworkComparator";
import { useEffect } from "react";

type Props = {
  namePrefix: string;
  fieldProps: <K extends keyof ExternalLiabilityFormModel>(
    name: K
  ) => ComputedFieldProps<ExternalLiabilityFormModel[K]>;
  loanProviders: NonEmptyArray<CreditorData>;
  onCreditorNameChanged: Reader<NonEmptyString, unknown>;
  creditorConfig: CreditorConfig;
  oldValues: Option<ExternalLoanItemRework>;
};

export function ExternalLiabilitiesEdititableItem(props: Props) {
  const formatMessage = useFormatMessage();
  const { isSubmitting } = useLoadingStatusRead();

  const wrapper = pipe(
    props.oldValues,
    option.map(oldValues => ({ item: option.some(oldValues) }))
  );

  const { reworkFieldProps } = useReworkComparator(wrapper);

  useEffect(() => {
    if (
      props.fieldProps("accountForDisbursment").value.length === 0 &&
      option.isSome(props.creditorConfig.accountForDisbursment) &&
      option.isNone(props.oldValues)
    ) {
      props
        .fieldProps("accountForDisbursment")
        .onChange(props.creditorConfig.accountForDisbursment.value as string);
    }
  }, [props.creditorConfig]);

  const typeOfProductField = (
    <ReadOnlyField
      label={formatMessage("StandardLoan.LiabilityItem.typeOfProductLabel")}
      value={formatMessage(
        getTypeOfProductLabel(props.fieldProps("type").value)
      )}
      size="small"
    />
  );

  const originalAmountField = (
    <ReadOnlyMoneyAmountField
      label={formatMessage("StandardLoan.LiabilityItem.originalAmountLabel")}
      value={props.fieldProps("originalAmount").value}
      size="x-small"
    />
  );

  const remainingAmountField = (
    <ReadOnlyMoneyAmountField
      label={formatMessage("StandardLoan.LiabilityItem.remainingAmountLabel")}
      value={props.fieldProps("remainingAmount").value}
      size="x-small"
    />
  );

  const variableSymbolField = (placeholder: Option<LocaleKey>) =>
    pipe(
      placeholder,
      option.fold(
        () => (
          <ReadOnlyField
            label={formatMessage(
              "StandardLoan.AccountsForRefinancing.variableSymbolLabel"
            )}
            value={formatMessage(
              "StandardLoan.AccountsForRefinancing.variableSymbolNotNeeded"
            )}
            size="small"
          />
        ),
        placeholder => (
          <TextField
            {...reworkFieldProps(
              props.fieldProps("variableSymbol"),
              "item",
              isSubmitting,
              "variableSymbol"
            )}
            placeholder={formatMessage(placeholder)}
            label={formatMessage(
              "StandardLoan.AccountsForRefinancing.variableSymbolLabel"
            )}
            inputMode={"numeric"}
          />
        )
      )
    );
  const renderAccountForDisbursement = pipe(
    props.creditorConfig.accountForDisbursment,
    option.fold(
      () => (
        <TextField
          {...reworkFieldProps(
            props.fieldProps("accountForDisbursment"),
            "item",
            isSubmitting,
            "accountForDisbursment"
          )}
          label={formatMessage(
            "StandardLoan.AccountsForRefinancing.accountForDisbursmentLabel"
          )}
          placeholder={formatMessage(props.creditorConfig.accountPlaceholder)}
        />
      ),
      disbursementAccount => {
        return props.creditorConfig.accountReadonly ? (
          <ReadOnlyField
            {...props.fieldProps("accountForDisbursment")}
            value={disbursementAccount}
            label={formatMessage(
              "StandardLoan.AccountsForRefinancing.accountForDisbursmentLabel"
            )}
            size="small"
          />
        ) : (
          <TextField
            {...props.fieldProps("accountForDisbursment")}
            label={formatMessage(
              "StandardLoan.AccountsForRefinancing.accountForDisbursmentLabel"
            )}
            placeholder={formatMessage(props.creditorConfig.accountPlaceholder)}
          />
        );
      }
    )
  );

  return (
    <Card>
      <Box column grow shrink>
        <Form>
          <FormSection>
            <FormRow type="1-1">
              {typeOfProductField}
              <CreditorAutocompleteField
                {...reworkFieldProps(
                  props.fieldProps("creditorName"),
                  "item",
                  isSubmitting,
                  "creditor"
                )}
                label={formatMessage(
                  "StandardLoan.AccountsForRefinancing.creditorLabel"
                )}
                placeholder={formatMessage(
                  "StandardLoan.AccountsForRefinancing.creditorPlaceholder"
                )}
                onSelectSuggestion={suggestion => {
                  props.fieldProps("accountForDisbursment").onChange("");
                  props.onCreditorNameChanged(suggestion.code);
                }}
                loanProviders={props.loanProviders}
              />
            </FormRow>
            <FormRow type="1-1">
              {originalAmountField}
              {remainingAmountField}
            </FormRow>
            <FormRow type="1-1">
              {renderAccountForDisbursement}
              {variableSymbolField(
                props.creditorConfig.variableSymbolPlaceholder
              )}
            </FormRow>
            {pipe(
              props.fieldProps("type").value === "CREDIT_CARD" &&
                option.isNone(props.oldValues),
              boolean.fold(
                () => <></>,
                () => (
                  <FormRow type="full">
                    <MaskedCreditCardField
                      {...reworkFieldProps(
                        props.fieldProps("creditCardNumber"),
                        "item",
                        isSubmitting,
                        "creditCardNumber"
                      )}
                      label={formatMessage(
                        "StandardLoan.AccountsForRefinancing.creditCard"
                      )}
                    />
                  </FormRow>
                )
              )
            )}
          </FormSection>
        </Form>
      </Box>
    </Card>
  );
}
