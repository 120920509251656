import {
  Body,
  Box,
  Button,
  Card,
  Loader,
  Stack,
  unsafeLocalizedString,
} from "design-system";
import { pipe, constant, constNull } from "fp-ts/function";
import { useState } from "react";
import { useFormatMessage } from "../../intl";
import { useQuery } from "../../useAPI";
import * as api from "../api";
import { AssignedBrokerForBankerInfo } from "../domain";
import { Option } from "fp-ts/Option";
import { option } from "fp-ts";
import * as remoteData from "../../RemoteData";
import { ApplicationBrokerForBankerDialog } from "./ApplicationBrokerForBankerDialog";

export default function OfferReviewSelect3P() {
  const formatMessage = useFormatMessage();

  const [assignedBrokerData, refreshAssignedData] = useQuery(
    api.getAssignedBrokerForBanker
  );
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const unwrap = (value: Option<string>) =>
    pipe(value, option.getOrElse(constant("")));

  const isAssigned = (assignedBroker: AssignedBrokerForBankerInfo) =>
    option.isSome(assignedBroker.firstName) ||
    option.isSome(assignedBroker.lastName) ||
    option.isSome(assignedBroker.phoneNumber) ||
    option.isSome(assignedBroker.email);

  const renderBrokerData = (assignedBroker: AssignedBrokerForBankerInfo) => (
    <Box column shrink hAlignContent="left">
      <Stack units={4} vAlignContent="center">
        <Body size="small" weight="regular">
          {formatMessage("StandardLoan.Select3P.assignedBroker")}
        </Body>
        <Button
          size="small"
          variant="text"
          label={formatMessage("StandardLoan.Select3P.edit")}
          action={() => setDialogOpen(true)}
        />
      </Stack>
      <Body size="small" weight="regular">
        {unsafeLocalizedString(
          unwrap(assignedBroker.firstName) +
            " " +
            unwrap(assignedBroker.lastName)
        )}
      </Body>
      <Body size="small" weight="regular">
        {unsafeLocalizedString(unwrap(assignedBroker.brokerageCompany))}
      </Body>
      <Body size="small" weight="regular">
        {unsafeLocalizedString(unwrap(assignedBroker.phoneNumber))}
      </Body>
      <Body size="small" weight="regular">
        {unsafeLocalizedString(unwrap(assignedBroker.email))}
      </Body>
    </Box>
  );

  const renderCard = (assignedBroker: AssignedBrokerForBankerInfo) => (
    <Box grow shrink hAlignContent="center">
      <Card column>{renderBrokerData(assignedBroker)}</Card>
    </Box>
  );

  return (
    <>
      {dialogOpen && (
        <ApplicationBrokerForBankerDialog
          onDismiss={() => setDialogOpen(false)}
          onSubmit={() => {
            refreshAssignedData();
            setDialogOpen(false);
          }}
          initialValues={pipe(
            assignedBrokerData,
            remoteData.fold(
              () => option.none,
              () => option.none,
              assignedBroker =>
                option.isSome(assignedBroker.brokerInfo) &&
                option.isSome(assignedBroker.brokerInfo.value.email)
                  ? option.some({
                      email: assignedBroker.brokerInfo.value.email.value,
                    })
                  : option.none
            )
          )}
        />
      )}
      {pipe(
        assignedBrokerData,
        remoteData.fold(constant(<Loader />), constNull, assignedBroker =>
          pipe(
            assignedBroker.brokerInfo,
            option.filter(isAssigned),
            option.map(renderCard),
            option.getOrElse(() => <></>)
          )
        )
      )}
    </>
  );
}
