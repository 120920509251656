import { option, array, record } from "fp-ts";
import { constant, constFalse, identity, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { TaskEither } from "fp-ts/TaskEither";
import { useFormatMessage } from "../../intl";
import { useQuery } from "../../useAPI";
import { useAppContext } from "../../useAppContext";
import { useValidators } from "../../Common/useValidators";
import { Industry, ReasonNote } from "../../KYC/domain";
import {
  TaxPayerCountryForm,
  TaxPayerCountryFormModel,
} from "../../KYC/TaxPayerCountryForm";
import { PersonalAndTaxInfo } from "../api";
import * as kycApi from "../../KYC/api";
import { useLayoutEffect, useMemo, useState } from "react";
import * as remoteData from "../../RemoteData";
import {
  dropdownOptionToValue,
  selectedDropdownOption,
} from "../../Common/selectDropdownOption";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import {
  Banner,
  Body,
  Box,
  Button,
  Divider,
  DropdownOption,
  Form,
  FormRow,
  FormSection,
  link,
  LoadingButton,
  PlusIcon,
  Space,
  Stack,
  TextField,
  LocalizedString,
  useForm,
  validators,
  Field,
} from "design-system";
import { shouldAskCompany } from "../domain";
import { useFormatIndustry } from "../../KYC/utils";
import { YesNoRadioGroupField } from "../../Common/YesNoRadioGroup/YesNoRadioGroupField";
import { PersonalDataProcessingDisclaimer } from "../../Common/PersonalDataProcessingDisclaimer/PersonalDataProcessingDisclaimer";
import { DropdownField } from "../../Common/DropdownField/DropdownField";
import { RiskyActivitiesDialog } from "../../KYC/RiskyActivitiesDialog";
import { IncomeSourceType } from "../IncomeForm/domain";
import { incomeSourcesWithIndustry } from "./domain";
import { SecondCitizenshipField } from "../../IdUpload/ClientData/PersonalDataForm/SecondCitizenshipField";
import { AllCitizenships } from "../../IdUpload/domain";

type Props = {
  onNext: (
    personalAndTaxInfo: PersonalAndTaxInfo
  ) => TaskEither<unknown, unknown>;
  initialPersonalAndTaxInfo: Option<PersonalAndTaxInfo>;
  incomeSource: IncomeSourceType;
  existingClient: boolean;
};

const emptyOtherTaxPayerCountry: TaxPayerCountryFormModel = {
  country: option.none,
  countryRequiresTIN: false,
  TIN: "",
  note: option.none,
  noTIN: false,
};

function useFormatReasonNote(): (note: ReasonNote) => LocalizedString {
  const formatMessage = useFormatMessage();

  return note => {
    switch (note) {
      case "NotIssuedToMe":
        return formatMessage("KYC.TaxPayerCountryForm.noteNotIssuedToMe");
      case "DoNotKnow":
        return formatMessage("KYC.TaxPayerCountryForm.noteDoNotKnow");
      case "RefuseToProvide":
        return formatMessage("KYC.TaxPayerCountryForm.noteRefuseToProvide");
    }
  };
}

function getOtherTaxPayerCountries({
  taxPayerOutsideCzechRepublic,
  countries,
}: {
  taxPayerOutsideCzechRepublic: boolean;
  countries: TaxPayerCountryFormModel[];
}) {
  if (taxPayerOutsideCzechRepublic) {
    let otherTaxPayingCountries = [];
    for (let i = 0; i < countries.length; i++) {
      otherTaxPayingCountries[i] = {
        TIN: pipe(countries[i].TIN, NonEmptyString.decode, option.fromEither),
        note: dropdownOptionToValue(countries[i].note),
        country: getCountryCode(countries[i]),
      };
    }
    return otherTaxPayingCountries;
  }
  return [];
}

function getCountryCode(taxCountry: TaxPayerCountryFormModel) {
  let country = option.toNullable(dropdownOptionToValue(taxCountry.country));
  return country ? country.countryCode : "";
}

export function PersonalAndTaxInformation(props: Props) {
  const formatMessage = useFormatMessage();
  const {
    defined,
    definedNoExtract,
    nonBlankString,
    maxLength,
  } = useValidators();

  const formatReasonNote = useFormatReasonNote();

  const {
    config: { CRSCountryURL, FATCAURL, r6NewSironMapping: newSironMapping },
  } = useAppContext();

  const [remoteCountries] = useQuery(kycApi.countries);

  const [showRiskyActivitiesModal, setShowRiskyActivitiesModal] = useState(
    false
  );

  const shouldAskSecondCitizenship = newSironMapping && props.existingClient;

  function shouldAskSsn(usCitizenOrTaxPayer: Option<boolean>): boolean {
    return pipe(usCitizenOrTaxPayer, option.exists(identity));
  }

  function shouldAskOtherTaxPayerCountries(
    taxPayerOutsideCzechRepublic: Option<boolean>
  ): boolean {
    return pipe(taxPayerOutsideCzechRepublic, option.exists(identity));
  }

  const shouldAskIndustry =
    !newSironMapping && shouldAskCompany(option.some(props.incomeSource));

  function shouldAskIndustryquestion(incomeSource: IncomeSourceType): boolean {
    return !newSironMapping && incomeSourcesWithIndustry.includes(incomeSource);
  }

  const shouldAskOtherIndustry = (
    industry: Option<DropdownOption<Industry>>
  ): boolean =>
    !newSironMapping &&
    pipe(
      industry,
      option.exists(industry => industry.value === "Other")
    );

  const ssnFormatValidator = validators.regex(
    /^(\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/,
    formatMessage("KYC.PersonalAndTaxInformation.ssnField.warning.format")
  );

  const ssnValidator = validators.inSequence(
    nonBlankString,
    ssnFormatValidator
  );

  const tinValidator = validators.inSequence(nonBlankString, maxLength(20));

  const countryOptions = useMemo(
    () =>
      pipe(
        remoteCountries,
        remoteData.fold(constant([]), constant([]), countries =>
          pipe(
            countries,
            array.map(country => ({
              label: country.countryName,
              value: country,
            }))
          )
        )
      ),
    [remoteCountries]
  );

  const noteOptions = pipe(
    ReasonNote.keys,
    record.keys,
    array.map(note => ({
      label: formatReasonNote(note),
      value: note,
    }))
  );

  const formatIndustry = useFormatIndustry();

  const industryOptions: Array<DropdownOption<Industry>> = pipe(
    Industry.keys,
    record.keys,
    array.map(industry => ({
      label: formatIndustry(industry),
      value: industry,
    }))
  );

  const { values, fieldProps, fieldArray, handleSubmit } = useForm(
    {
      initialValues: pipe(
        props.initialPersonalAndTaxInfo,
        option.map(personalAndTaxInfo => ({
          politicallyExposedPerson: option.some(
            personalAndTaxInfo.politicallyExposedPerson
          ),
          taxPayerOutsideCzechRepublic: option.some(
            personalAndTaxInfo.taxPayerOutsideCzechRepublic
          ),
          otherTaxPayerCountries: personalAndTaxInfo.otherTaxPayerCountries.map(
            taxCountry =>
              ({
                country: pipe(
                  countryOptions,
                  array.findFirst(
                    option => option.value.countryCode === taxCountry.country
                  )
                ),
                TIN: pipe(taxCountry.TIN, option.getOrElse(constant(""))),
                note: selectedDropdownOption(taxCountry.note, noteOptions),
                countryRequiresTIN:
                  option.isSome(taxCountry.TIN) ||
                  option.isSome(taxCountry.note),
                noTIN: option.isSome(taxCountry.note),
              } as TaxPayerCountryFormModel)
          ),
          usCitizenOrTaxPayer: option.some(
            personalAndTaxInfo.usCitizenOrTaxPayer
          ),
          ssn: pipe(personalAndTaxInfo.ssn, option.getOrElse(constant(""))),
          specialRelationships: option.some(
            personalAndTaxInfo.specialRelationships
          ),
          riskyActivities: personalAndTaxInfo.riskyActivities,
          industry: selectedDropdownOption(
            personalAndTaxInfo.industry,
            industryOptions
          ),
          secondCitizenshipField: personalAndTaxInfo.secondCitizenship,
          secondCitizenshipRadio: option.some(
            option.isSome(personalAndTaxInfo.secondCitizenship)
          ),
        })),
        option.getOrElse(
          constant({
            politicallyExposedPerson: option.none as Option<boolean>,
            taxPayerOutsideCzechRepublic: option.none as Option<boolean>,
            otherTaxPayerCountries: [] as TaxPayerCountryFormModel[],
            usCitizenOrTaxPayer: option.none as Option<boolean>,
            ssn: "",
            riskyActivities: option.none as Option<boolean>,
            specialRelationships: option.none as Option<boolean>,
            industry: option.none as Option<DropdownOption<Industry>>,
            secondCitizenshipField: option.none as Option<AllCitizenships>,
            secondCitizenshipRadio: option.some(false),
          })
        )
      ),
      fieldValidators: values => ({
        politicallyExposedPerson: defined(),
        taxPayerOutsideCzechRepublic: defined(),
        usCitizenOrTaxPayer: defined(),
        ssn: shouldAskSsn(values.usCitizenOrTaxPayer)
          ? ssnValidator
          : undefined,
        riskyActivities: newSironMapping ? undefined : definedNoExtract(),
        specialRelationships: defined(),
        industry: shouldAskIndustry
          ? validators.definedNoExtract(
              formatMessage("Form.fieldError.required")
            )
          : undefined,
        otherIndustry:
          shouldAskIndustry && shouldAskOtherIndustry(values.industry),
        secondCitizenshipField:
          shouldAskSecondCitizenship &&
          pipe(values.secondCitizenshipRadio, option.getOrElse(constFalse))
            ? definedNoExtract<AllCitizenships>()
            : undefined,
      }),
      fieldArrayValidators: (values, index) =>
        shouldAskOtherTaxPayerCountries(values.taxPayerOutsideCzechRepublic)
          ? {
              otherTaxPayerCountries: {
                country: defined(),
                TIN:
                  values.otherTaxPayerCountries[index].countryRequiresTIN &&
                  option.isNone(values.otherTaxPayerCountries[index].note) &&
                  !values.otherTaxPayerCountries[index].noTIN
                    ? tinValidator
                    : undefined,
                note: validators.foldPredicate(
                  () =>
                    values.otherTaxPayerCountries[index].countryRequiresTIN &&
                    values.otherTaxPayerCountries[index].TIN.trim().length ===
                      0,
                  () => option.none,
                  definedNoExtract()
                ),
              },
            }
          : {},
    },
    {
      onSubmit: ({
        politicallyExposedPerson,
        taxPayerOutsideCzechRepublic,
        usCitizenOrTaxPayer,
        otherTaxPayerCountries,
        ssn,
        specialRelationships,
        riskyActivities,
        industry,
        secondCitizenshipField,
        secondCitizenshipRadio,
      }) =>
        props.onNext({
          politicallyExposedPerson,
          taxPayerOutsideCzechRepublic,
          otherTaxPayerCountries: getOtherTaxPayerCountries({
            taxPayerOutsideCzechRepublic: taxPayerOutsideCzechRepublic,
            countries: otherTaxPayerCountries,
          }),
          usCitizenOrTaxPayer,
          ssn: usCitizenOrTaxPayer ? option.some(ssn) : option.none,
          specialRelationships,
          riskyActivities: newSironMapping ? option.none : riskyActivities,
          industry: dropdownOptionToValue(industry),
          secondCitizenship:
            shouldAskSecondCitizenship &&
            pipe(secondCitizenshipRadio, option.getOrElse(constFalse))
              ? secondCitizenshipField
              : option.none,
        }),
    }
  );

  const otherTaxPayerCountriesFieldArray = fieldArray("otherTaxPayerCountries");

  useLayoutEffect(() => {
    if (
      shouldAskOtherTaxPayerCountries(values.taxPayerOutsideCzechRepublic) &&
      otherTaxPayerCountriesFieldArray.items.length === 0
    ) {
      otherTaxPayerCountriesFieldArray.push(emptyOtherTaxPayerCountry);
    }
  }, [otherTaxPayerCountriesFieldArray, values.taxPayerOutsideCzechRepublic]);

  return (
    <>
      <Form>
        <FormSection
          heading={{
            title: formatMessage(
              "StandardLoan.KYC.PersonalAndTaxInformation.description"
            ),
          }}
        >
          <></>
          {shouldAskSecondCitizenship && (
            <FormRow type="full">
              <SecondCitizenshipField
                {...fieldProps("politicallyExposedPerson")}
                secondCitizenshipRadio={fieldProps("secondCitizenshipRadio")}
                secondCitizenshipField={fieldProps("secondCitizenshipField")}
                readonly={false}
              />
            </FormRow>
          )}
          <FormRow type="full">
            <YesNoRadioGroupField
              {...fieldProps("politicallyExposedPerson")}
              label={formatMessage(
                "StandardLoan.KYC.PersonalAndTaxInformation.politicallyExposedPersonLabel"
              )}
              description={formatMessage(
                "StandardLoan.KYC.PersonalAndTaxInformation.politicallyExposedPersonHint"
              )}
            />
          </FormRow>
          <FormRow type="full">
            <YesNoRadioGroupField
              {...fieldProps("taxPayerOutsideCzechRepublic")}
              label={formatMessage(
                "StandardLoan.KYC.PersonalAndTaxInformation.taxPayerOutsideCZLabel"
              )}
            />
          </FormRow>
          {shouldAskOtherTaxPayerCountries(
            values.taxPayerOutsideCzechRepublic
          ) && (
            <FormRow type="full">
              <Banner
                type="informative"
                content={[
                  formatMessage(
                    "StandardLoan.KYC.PersonalAndTaxInformation.taxPayerOutsideCZInfoBox"
                  ),
                  link(
                    CRSCountryURL,
                    formatMessage(
                      "StandardLoan.KYC.PersonalAndTaxInformation.taxPayerOutsideCZInfoBoxDownloadLink"
                    )
                  ),
                ]}
                title={option.none}
                actions={option.none}
                onDismiss={option.none}
              />
            </FormRow>
          )}
          {shouldAskOtherTaxPayerCountries(
            values.taxPayerOutsideCzechRepublic
          ) && (
            <FormRow type="3-1">
              <Box column>
                <Field
                  issues={fieldProps("otherTaxPayerCountries").issues}
                  label={formatMessage(
                    "StandardLoan.KYC.PersonalAndTaxInformation.otherTaxPayerCountries"
                  )}
                >
                  {labelId => (
                    <Stack id={labelId} column units={6}>
                      {otherTaxPayerCountriesFieldArray.items.map(
                        ({
                          fieldProps,
                          remove,
                          onChangeValues,
                          namePrefix,
                        }) => (
                          <TaxPayerCountryForm
                            fieldProps={fieldProps}
                            namePrefix={namePrefix}
                            onRemove={remove}
                            onChangeValues={onChangeValues}
                            showRemoveButton={
                              values.otherTaxPayerCountries.length > 1
                            }
                            noteOptions={noteOptions}
                            countryOptions={pipe(
                              countryOptions,
                              array.filter(country =>
                                pipe(
                                  values.otherTaxPayerCountries,
                                  array.findFirst(c =>
                                    pipe(
                                      c.country,
                                      dropdownOptionToValue,
                                      option.exists(c => c === country.value)
                                    )
                                  ),
                                  option.isNone
                                )
                              )
                            )}
                          />
                        )
                      )}
                    </Stack>
                  )}
                </Field>
                <Space units={6} />
                {values.otherTaxPayerCountries.length < 3 && (
                  <Box grow vAlignContent="center">
                    <Button
                      variant="text"
                      size="default"
                      icon={PlusIcon}
                      action={() =>
                        otherTaxPayerCountriesFieldArray.push(
                          emptyOtherTaxPayerCountry
                        )
                      }
                      label={formatMessage(
                        "StandardLoan.KYC.TaxPayerCountryForm.addCountry"
                      )}
                    />
                    <Space units={3} />
                    <Body size="small" weight="regular">
                      {formatMessage(
                        "StandardLoan.KYC.TaxPayerCountryForm.addCountryHint"
                      )}
                    </Body>
                  </Box>
                )}
              </Box>
              <Space fluid />
            </FormRow>
          )}
          <FormRow type="full">
            <YesNoRadioGroupField
              {...fieldProps("usCitizenOrTaxPayer")}
              label={formatMessage(
                "StandardLoan.KYC.PersonalAndTaxInformation.usCitizedOrTaxPayerLabel"
              )}
            />
          </FormRow>
          {shouldAskSsn(values.usCitizenOrTaxPayer) && (
            <FormRow type="full">
              <Banner
                type="informative"
                content={[
                  formatMessage(
                    "StandardLoan.KYC.PersonalAndTaxInformation.usCitizedOrTaxPayerInfoBox"
                  ),
                  link(
                    FATCAURL,
                    formatMessage(
                      "StandardLoan.KYC.PersonalAndTaxInformation.usCitizedOrTaxPayerInfoBoxDownloadLink"
                    )
                  ),
                ]}
                title={option.none}
                actions={option.none}
                onDismiss={option.none}
              />
            </FormRow>
          )}
          {pipe(values.usCitizenOrTaxPayer, option.exists(identity)) ? (
            <FormRow type="1-1">
              <TextField
                {...fieldProps("ssn")}
                label={formatMessage(
                  "StandardLoan.KYC.PersonalAndTaxInformation.ssnLabel"
                )}
                placeholder={formatMessage(
                  "StandardLoan.KYC.PersonalAndTaxInformation.ssnPlaceholder"
                )}
              />
              <Space fluid />
            </FormRow>
          ) : null}
          <FormRow type="full">
            <YesNoRadioGroupField
              {...fieldProps("specialRelationships")}
              label={formatMessage(
                "StandardLoan.KYC.PersonalAndTaxInformation.specialRelationshipsLabel"
              )}
              description={formatMessage(
                "StandardLoan.KYC.PersonalAndTaxInformation.specialRelationshipsInfo"
              )}
            />
          </FormRow>
          {shouldAskIndustryquestion(props.incomeSource) && (
            <FormRow type="full">
              <DropdownField
                {...fieldProps("industry")}
                label={formatMessage(
                  "StandardLoan.KYC.PersonalAndTaxInformation.industryLabel"
                )}
                placeholder={formatMessage(
                  "StandardLoan.KYC.PersonalAndTaxInformation.industryPlaceholder"
                )}
                options={industryOptions}
                clearable
                searchable
              />
            </FormRow>
          )}
          {!newSironMapping && (
            <FormRow type="full">
              <YesNoRadioGroupField
                {...fieldProps("riskyActivities")}
                label={formatMessage(
                  "StandardLoan.KYC.PersonalAndTaxInformation.riskyBusinessLabel"
                )}
                cta={{
                  action: () => setShowRiskyActivitiesModal(true),
                  label: formatMessage("MoreInfo"),
                }}
              />
            </FormRow>
          )}
          <Divider />
          <PersonalDataProcessingDisclaimer />
          <Box hAlignContent="right">
            <LoadingButton
              type="submit"
              variant="primary"
              size="default"
              labels={{
                normal: formatMessage("Continue"),
                success: formatMessage("Save"),
                loading: formatMessage("Loading"),
                error: formatMessage("Error"),
              }}
              action={handleSubmit}
            />
          </Box>
        </FormSection>
      </Form>
      {showRiskyActivitiesModal && (
        <RiskyActivitiesDialog
          onDismiss={() => setShowRiskyActivitiesModal(false)}
        />
      )}
    </>
  );
}
