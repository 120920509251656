import { Divider, FormRow, FormSection, ReadOnlyField } from "design-system";
import { getTypeOfProductLabel } from "../domain";
import { useFormatMessage } from "../../intl";
import { ReadOnlyMoneyAmountField } from "../../Common/ReadOnlyMoneyAmountField/ReadOnlyMoneyAmountField";
import { InternalLoanItem } from "../api";
import { ReadOnlyDateField } from "../../Common/ReadOnlyDateField/ReadOnlyDateField";
import { option } from "fp-ts";
import { constNull, pipe } from "fp-ts/function";

type Props = {
  index: number;
  liability: InternalLoanItem;
  divider: boolean;
};

export function InternalLiabilityRow(props: Props) {
  const formatMessage = useFormatMessage();

  const typeOfProductField = (
    <ReadOnlyField
      label={formatMessage("StandardLoan.LiabilityItem.typeOfProductLabel")}
      value={formatMessage(getTypeOfProductLabel(props.liability.type))}
      size="small"
    />
  );

  const originalAmountField = (
    <ReadOnlyMoneyAmountField
      label={formatMessage("StandardLoan.LiabilityItem.originalAmountLabel")}
      value={props.liability.originalAmount}
      size="x-small"
    />
  );

  const remainingAmountField = (
    <ReadOnlyMoneyAmountField
      label={formatMessage("StandardLoan.LiabilityItem.remainingAmountLabel")}
      value={props.liability.remainingAmount}
      size="x-small"
    />
  );

  const startDateField = pipe(
    props.liability.startDate,
    option.fold(constNull, () => (
      <ReadOnlyDateField
        label={formatMessage("StandardLoan.LiabilityItem.startDateLabel")}
        value={props.liability.startDate}
        format="dd-mm-yyyy"
      />
    ))
  );

  return (
    <FormSection>
      <FormRow type="1-1-1-1">
        {typeOfProductField}
        {originalAmountField}
        {remainingAmountField}
        {startDateField}
      </FormRow>
      {props.divider && (
        <FormRow type="full">
          <Divider />
        </FormRow>
      )}
    </FormSection>
  );
}
